<app-moment icon="assignment" title="Note" colour="#9ee7cc" [momentData]="momentData" [expandOption]="true" (expandFunc)="momentService.expandButtonClicked(momentData)">
  <div *ngIf="momentData.deleted" class="deleted">
    <div fxLayout="column" fxLayouGap="6px">
      <h4>Deleted</h4>
      <p>{{ momentData.deleted.ts | date: 'fullDate' }}, {{ momentData.deleted.ts | date: 'shortTime' }} by {{ momentData.deleted.author }}</p>
      <p>Reason: {{ momentData.deleted.reason }}</p>
    </div>
  </div>

  <div *ngIf="momentData.edited" class="edited-info">
    <h4>Edited</h4>
    <p>{{ momentData.edited.ts | date: 'fullDate' }}, {{ momentData.edited.ts | date: 'shortTime' }} by {{ momentData.edited.author }}</p>
    <p>Reason: {{ momentData.edited.reason }}</p>
  </div>

  <div class="note" fxLayoutAlign="left" [innerText]="momentData.note"></div>

  <div *ngIf="!(featureFlags$ | async).isAppointmentMomentsEnabled; else default" fxLayout="row" fxLayoutAlign="center">
    <button mat-flat-button class="warn" (click)="momentService.hardDeleteNoteButtonClicked(momentData, momentService)">
      Delete from timeline
    </button>
  </div>
  <ng-template #default>
    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="6px">
      <button *ngIf="momentData.appointmentRef && !momentData.deleted" mat-button (click)="momentService.editAppointmentMoment(momentData)">
        Edit
      </button>
      <button *ngIf="!momentData.deleted" mat-button class="warn" (click)="momentService.softDeleteButtonClicked(momentData)">Delete</button>
    </div>
  </ng-template>
</app-moment>
