import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lfsFilePath',
    standalone: false
})
export class LfsFilePathPipe implements PipeTransform {

  transform(url: string): string {
    if (url == null || url === '' || url.startsWith('https://') || url.startsWith('http://')) {
      return url;
    }
    return 'https://www.feelstressfree.com/LFS/' + url;
  }

}
