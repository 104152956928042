<app-dialog
  #dialog
  (cancel)="cancelButtonClicked()"
  (confirm)="deleteButtonClicked()"
  cancelButtonText="Cancel"
  confirmButtonText="Soft delete"
  confirmButtonColor="warn"
  [isConfirmButtonEnabled]="deletionFormGroup.valid"
  [title]="title">
  <div><mat-error>Warning! This action cannot be undone</mat-error></div>
  <p>{{ infoText }}</p>

  <mat-accordion>
      <ng-container *ngIf="data.moment.typeName === momentType.Appointment" [ngTemplateOutlet]="appointmentMoment" />
      <ng-container *ngIf="data.moment.typeName === momentType.Note" [ngTemplateOutlet]="noteMoment" />
      <ng-container *ngIf="data.moment.typeName === momentType.CgiScore" [ngTemplateOutlet]="cgiMoment" />
      <ng-container *ngIf="data.moment.typeName === momentType.Event" [ngTemplateOutlet]="riskMoment" />
      <ng-container *ngIf="data.moment.typeName === momentType.Call || data.moment.typeName === momentType.File || data.moment.typeName === momentType.GoogleFile"
                    [ngTemplateOutlet]="attachmentMoment" />
  </mat-accordion>

  <div fxFlex fxLayout="column" [formGroup]="deletionFormGroup" class="form" fxLayoutGap="6px">
    <div fxFlex>
      <mat-error>
        Please do not submit any patient identifiable information in the reason box, this includes names, emails and patient IDs.
      </mat-error>
    </div>
    <mat-form-field fxFlex>
      <mat-label>Reason for soft deleting moment</mat-label>
      <textarea matInput formControlName="softDeleteReason" placeholder="Please explain why you want to delete this moment"></textarea>
      <mat-error *ngIf="deletionFormGroup.controls['softDeleteReason'].hasError('required')">
        Reason for soft deleting moment is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="deletionFormGroup.controls['softDeleteReason'].hasError('trimErrorStart')">
        Reason for soft deleting moment cannot contain leading whitespaces
      </mat-error>
      <mat-error *ngIf="deletionFormGroup.controls['softDeleteReason'].hasError('trimErrorEnd')">
        Reason for soft deleting moment cannot contain trailing whitespaces
      </mat-error>
      <mat-error *ngIf="deletionFormGroup.controls['softDeleteReason'].hasError('multipleWhitespacesError')">
        Reason for soft deleting moment cannot contain multiple consecutive whitespaces
      </mat-error>
      <mat-error *ngIf="!deletionFormGroup.controls['softDeleteReason'].hasError('required') && deletionFormGroup.controls['softDeleteReason'].hasError('lengthError')">
        Reason for soft deleting moment must contain more than 3 characters
      </mat-error>
    </mat-form-field>

    <div fxFlex>
      <p>Do any of the moments contain data for another patient?</p>
      <mat-radio-group formControlName="dataContainsAnotherUsersInfo" aria-label="Select an option">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="deletionFormGroup.get('dataContainsAnotherUsersInfo').value === true" fxFlex fxLayout="column">
      <div fxFlex>
        <mat-error>
          If a moment contains data for another patient this requires a hard delete. Please contact Andres to request
          this data be deleted.
        </mat-error>
      </div>

      <div fxFlex>
        <p>Would you like to request a hard delete?</p>
        <mat-radio-group formControlName="requestHardDelete" aria-label="Select an option">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxFlex>
        Please remember to chase this request if you have heard nothing within 4 days time.
      </div>
    </div>
  </div>
</app-dialog>

<ng-template #noteMoment>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon [ngStyle]="{ color: '#9ee7cc' }" class="moment-icon preview">assignment</mat-icon>
        <span>Note</span>
      </mat-panel-title>
      <mat-panel-description> {{ (data.moment || linkedMoment).author }} | {{ (data.moment || linkedMoment).timestamp | convertDateToTime }} </mat-panel-description>
    </mat-expansion-panel-header>
    <p>{{ (data.moment || linkedMoment).note }}</p>
  </mat-expansion-panel>
</ng-template>

<ng-template #cgiMoment>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon [ngStyle]="{ color: '#e988ec' }" class="moment-icon preview">assessment</mat-icon>
        <span>CGI Score</span>
      </mat-panel-title>
      <mat-panel-description> {{ (data.moment || linkedMoment).author }} | {{ (data.moment || linkedMoment).timestamp | convertDateToTime }} </mat-panel-description>
    </mat-expansion-panel-header>
    <p>CGI-SEVERITY: {{ (data.moment || linkedMoment).cgiSeverity }}</p>
    <p>CGI-IMPROVEMENT: {{ (data.moment || linkedMoment).cgiImprovement }}</p>
  </mat-expansion-panel>
</ng-template>

<ng-template #riskMoment>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon [ngStyle]="{ color: '#E3B23C' }" class="moment-icon preview">playlist_add_check</mat-icon>
        <span>{{ (data.moment || linkedMoment).titleText }}</span>
      </mat-panel-title>
      <mat-panel-description> {{ (data.moment || linkedMoment).author }} | {{ (data.moment || linkedMoment).timestamp | convertDateToTime }} </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container *ngIf="(data.moment || linkedMoment).GADQuestions.length > 0">
      <p>GAD - {{ (data.moment || linkedMoment).GADSeverity }} ({{ (data.moment || linkedMoment).GADScore }})</p>
      <ul>
        <li *ngFor="let question of (data.moment || linkedMoment).GADQuestions; trackByProperty: 'momentDataGADQuestions'">
          {{ question.question }} - <i>{{ question.answer }}</i>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="(data.moment || linkedMoment).PHQQuestions.length > 0">
      <p>PHQ - {{ (data.moment || linkedMoment).PHQSeverity }} ({{ (data.moment || linkedMoment).PHQScore }})</p>
      <ul>
        <li *ngFor="let question of (data.moment || linkedMoment).PHQQuestions; trackByProperty: 'momentDataPHQQuestions'">
          {{ question.question }} - <i>{{ question.answer }}</i>
        </li>
      </ul>
    </ng-container>
  </mat-expansion-panel>
</ng-template>

<ng-template #attachmentMoment>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon *ngIf="(data.moment || linkedMoment).typeName === momentType.Call" [ngStyle]="{ color: '#46cbf3' }" class="moment-icon preview">call</mat-icon>
        <mat-icon *ngIf="(data.moment || linkedMoment).typeName === momentType.File" [ngStyle]="{ color: '#fdb47f' }" class="moment-icon preview">save</mat-icon>
        <mat-icon *ngIf="(data.moment || linkedMoment).typeName === momentType.GoogleFile" svgIcon="google-drive" class="moment-icon preview svg"></mat-icon>
        <span>{{ (data.moment || linkedMoment).fileName }}</span>
      </mat-panel-title>
      <mat-panel-description> {{ (data.moment || linkedMoment).author }} | {{ (data.moment || linkedMoment).timestamp | convertDateToTime }} </mat-panel-description>
    </mat-expansion-panel-header>
    <p>{{ (data.moment || linkedMoment)?.description }}</p>
  </mat-expansion-panel>
</ng-template>

<ng-template #appointmentMoment>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon [ngStyle]="{ color: '#3cc4e3' }" class="moment-icon preview">event_available</mat-icon>
        <span>{{ (data.moment || linkedMoment).typeName | titlecase }}</span>
      </mat-panel-title>
      <mat-panel-description> {{ (data.moment || linkedMoment).author }} | {{ (data.moment || linkedMoment).timestamp | convertDateToTime }} </mat-panel-description>
    </mat-expansion-panel-header>
    <div>Date: {{ data.moment.appointmentDate | date: 'fullDate' }}</div>
    <div>Time: {{ data.moment.appointmentTime | date: 'shortTime' }}</div>
    <p>Number of Sessions: {{ data.moment.therapySessionNumber }}</p>
    <p>
      Outcome: {{ data.moment.appointmentOutcome }}
      <span *ngIf="data.moment.appointmentOutcomeOther"> - {{ data.moment.appointmentOutcomeOther }} </span>
    </p>
    <p>
      How difficult patient is finding daily activites:
      {{ data.moment.howPatientIsFunctioning || 'Not answered' }}
    </p>
  </mat-expansion-panel>
</ng-template>
