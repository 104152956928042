import { Component } from '@angular/core';

@Component({
    selector: 'app-firebase-verify-email',
    templateUrl: './firebase-verify-email.component.html',
    styleUrls: ['./firebase-verify-email.component.scss'],
    standalone: false
})
export class FirebaseVerifyEmailComponent {

  constructor() { }

}
