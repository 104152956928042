import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayUserName',
    standalone: false
})

export class DisplayUserNamePipe implements PipeTransform {
  /**
   * Returns a user name based on the parameters provided.
   * @param email email address of patient
   * @param firstName User first name
   * @param lastName User last name
   */
  transform(email: string, firstName?: string, lastName?: string): string {

    let fullName = '';

    // If name is not undefined...
    if (typeof firstName === 'string' && firstName.trim() !== '') {
      // ...set fullName to concatenation of name and lastName if lastName is present, otherwise just set name
      fullName = lastName ? `${firstName} ${lastName}`.trim() : firstName;
    } else if (email != null) {
      fullName = email;
    }

    return fullName;
  }
}
