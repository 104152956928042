import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { DefaultTemplateComponent } from './default-template.component';
import { MainSideNavComponent } from '../components/main-sidenav/main-sidenav.component';
import { MainTabsComponent } from '../components/main-tabs/main-tabs.component';
import { MainToolbarComponent } from '../components/main-toolbar/main-toolbar.component';
import { NavLinkComponent } from '../components/main-nav/nav-link/nav-link.component';
import { LogoutConfirmationDialogComponent } from '../components/logout-confirmation-dialog/logout-confirmation-dialog.component';

import { MainNavComponent } from '../components/main-nav/main-nav.component';
import { MessageNotificationButtonComponent } from '../components/notifications/v1/message-notification-button/message-notification-button.component';
import { PrimaryNotificationButtonComponent } from '../components/notifications/v1/primary-notification-button/primary-notification-button.component';
import { PrimaryNotificationComponent } from '../components/notifications/v1/primary-notification-button/primary-notification/primary-notification.component';
import { GoMessageNotificationButtonComponent } from '../components/notifications/v2/go-message-notification-button/go-message-notification-button.component';
import { GoPrimaryNotificationButtonComponent } from '../components/notifications/v2/go-primary-notification-button/go-primary-notification-button.component';
import { GoPrimaryNotificationComponent } from '../components/notifications/v2/go-primary-notification-button/go-primary-notification/go-primary-notification.component';
import { FeedbackFormComponent } from '../components/feedback-form/feedback-form.component';

// Standalone components
import { OfficeHoursComponent } from '../components/office-hours/office-hours.component';
import { TherapistStatusComponent } from '../components/therapist-status/therapist-status.component';
import { ContainsActiveLinkPipe } from '../components/main-nav/nav-link/contains-active-link.pipe';
import { AssignedPatientNotificationDirective } from '../components/notifications/v1/primary-notification-button/assigned-patient-notification.directive';
import { ChatRequestNotificationDirective } from '../components/notifications/v1/primary-notification-button/chat-request-notification.directive';
import { LeadStatusChangeNotificationDirective } from '../components/notifications/v1/primary-notification-button/lead-status-change-notification.directive';
import { GoChatRequestNotificationDirective } from '../components/notifications/v2/go-primary-notification-button/go-chat-request-notification.directive';
import { GoChatUpgradeNotificationDirective } from '../components/notifications/v2/go-primary-notification-button/go-chat-upgrade-notification.directive';
import { GoAssignedPatientNotificationDirective } from '../components/notifications/v2/go-primary-notification-button/go-assigned-patient-notification.directive';

@NgModule({
  declarations: [
    DefaultTemplateComponent,
    MainSideNavComponent,
    MainTabsComponent,
    MainToolbarComponent,
    MainNavComponent,
    NavLinkComponent,
    LogoutConfirmationDialogComponent,
    MessageNotificationButtonComponent,
    PrimaryNotificationButtonComponent,
    PrimaryNotificationComponent,
    // go-chat-duplication start
    GoMessageNotificationButtonComponent,
    GoPrimaryNotificationButtonComponent,
    GoPrimaryNotificationComponent,
    // go-chat-duplication end
    FeedbackFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    // Standalone components
    OfficeHoursComponent,
    TherapistStatusComponent,
    ContainsActiveLinkPipe,
    AssignedPatientNotificationDirective,
    ChatRequestNotificationDirective,
    LeadStatusChangeNotificationDirective,
    // go-chat-duplication start
    GoChatRequestNotificationDirective,
    GoChatUpgradeNotificationDirective,
    GoAssignedPatientNotificationDirective,
    // go-chat-duplication end
  ]
})
export class DefaultTemplateModule { }
