import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { takeUntil } from 'rxjs';

import { OnDestroyObservable } from '@app/classes/on-destroy-observable';
import { TimelineFilterOptions } from './timeline-filter-options';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { TimelineService } from '../timeline.service';

@Component({
    selector: 'app-timeline-filters',
    templateUrl: './timeline-filters.component.html',
    styleUrls: ['./timeline-filters.component.scss'],
    standalone: false
})
export class TimelineFiltersComponent extends OnDestroyObservable implements OnInit {

  public maxFilterDate: Date;
  public areFiltersValid = true;

  @Input() displayInOverlay = false;
  @Output() currentFilterOptions = new EventEmitter<TimelineFilterOptions>();

  public filterOptions: TimelineFilterOptions = TimelineService.TIMELINE_FILTER_DEFAULTS;
  public filterOptionsForm = this.formBuilder.group({
    date: [ this.filterOptions.date ],
    types: this.formBuilder.group({
      call: [this.filterOptions.types.call],
      note: [this.filterOptions.types.note],
      file: [this.filterOptions.types.file],
      googleFile: [this.filterOptions.types.googleFile],
      chat: [this.filterOptions.types.chat],
      event: [this.filterOptions.types.event],
      discharge: [this.filterOptions.types.discharge],
      cgiScore: [this.filterOptions.types.cgiScore],
      appointment: [this.filterOptions.types.appointment]
    })
  });

  public get displayListOfSelected(): string[] {
    if (!this.filterOptions.types) return undefined;

    const selectedTypes = [];
    Object.values(this.filterOptions.types).forEach((value, index) => value ? selectedTypes.push(Object.keys(this.filterOptions.types)[index]) : undefined);
    return selectedTypes;
  }

  public get numberOfActiveFilters(): number {
    if (!this.filterOptions.types) return 0;

    let counter = 0;
    Object.values(this.filterOptions.types).forEach(value => value ? counter++ : null);
    // Returns count for how many are true
    return counter;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private timelineService: TimelineService,
    public featureFlagService: FeatureFlagService,
  ) {
    super();
   }

  public ngOnInit(): void {
    this.maxFilterDate = this.setDateToEndOfDay(new Date());
    this.filterOptions.date = this.setDateToEndOfDay(this.filterOptions.date);
    this.currentFilterOptions.emit(this.filterOptions);

    this.filterOptionsForm.controls.date.valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(date => {
      this.filterOptions.date = date;
      this.dateChange(date);
    });

    this.patchFilterFormValues();

    this.filterOptionsForm.controls.types.valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(filters => {
      this.filterOptions.types = {
        call: filters.call,
        note: filters.note,
        file: filters.file,
        googleFile: filters.googleFile,
        chat: filters.chat,
        event: filters.event,
        discharge: filters.discharge,
        cgiScore: filters.cgiScore,
        appointment: filters.appointment
      };
      this.checkboxChange();
    });
  }

  public filterButtonClicked(): void {
    this.currentFilterOptions.emit(this.filterOptions);
  }

  private checkboxChange(): void {
    // Sets areFiltersValid to true if there is at least one filter option selected, otherwise sets it to false
    this.areFiltersValid = Object.values(this.filterOptions.types).some(value => value);
  }

  private setDateToEndOfDay(date: Date): Date {
    return new Date(date.setHours(23, 59, 59, 999));
  }

  private dateChange(date: Date): void {
    this.setDateToEndOfDay(date);
  }

  private patchFilterFormValues(): void {
    // If filter types are null, sub ready to update
      this.timelineService.typeFilterReset$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
        const filterTypeDefaults = this.timelineService.filterOptions$.getValue().types;

        this.filterOptionsForm.get('types').patchValue({
          call: filterTypeDefaults.call,
          note: filterTypeDefaults.note,
          file: filterTypeDefaults.file,
          googleFile: filterTypeDefaults.googleFile,
          chat: filterTypeDefaults.chat,
          event: filterTypeDefaults.event,
          discharge: filterTypeDefaults.discharge,
          cgiScore: filterTypeDefaults.cgiScore,
          appointment: filterTypeDefaults.appointment
        });
      });
  }
}
