import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { ChatMoment } from '../moment/chat-moment/chat-moment';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { PatientTimelineService } from '@backend-client/services';

@Component({
    selector: 'app-chat-log-dialog',
    templateUrl: './chat-log-dialog.component.html',
    styleUrls: ['./chat-log-dialog.component.scss'],
    standalone: false
})
export class ChatLogDialogComponent implements OnInit {

  @ViewChild('dialog', { static: true }) dialog: DialogComponent;
  public chatMessages: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { chatMoment: ChatMoment, patientId: string },
              private dialogRef: MatDialogRef<ChatLogDialogComponent>,
              private patientTimelineService: PatientTimelineService,
              private httpClient: HttpClient) { }

  public async ngOnInit() {
    await this.dialog.showAsyncProgressForFunction(async () => {
      // get the signed url to download the archived chat log json file
      const downloadUrlResponse = await firstValueFrom(this.patientTimelineService.PatientsMomentsGetPatientFileContentDownloadUrl({
        patientId: this.data.patientId,
        momentId: this.data.chatMoment.id
      }));

      // use httpClient to download the file from the signedUrl
      const chatLogData = await firstValueFrom(this.httpClient.get(downloadUrlResponse.signedUrl));

      // assign the messages into a local variable which is bound in the template file
      this.chatMessages = (chatLogData as any).messages;
    });
  }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }
}
