import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { MediaObserver } from '@ngbracket/ngx-layout';

import { Moment } from './moment';

@Component({
    selector: 'app-moment',
    templateUrl: './moment.component.html',
    styleUrls: ['./moment.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MomentComponent {

  // TODO: Can these various inputs be condensed into a single object?
  @Input() icon;
  @Input() svgIcon;
  @Input() title;
  @Input() colour;
  @Input() expandOption = false;
  @Input() momentData: Moment;

  @Output() expandFunc = new EventEmitter();

  constructor(public mediaObserver: MediaObserver) { }

}
