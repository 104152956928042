import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Overlay } from '@angular/cdk/overlay';

import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { map, share, switchMap, take, tap } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import auth = firebase.auth;
import firestore = firebase.firestore;

import { SwaggerSecurityScopes } from '@backend-client/swagger-security-scopes';
import { resettableCache } from '@operators/resettable-cache';
import { IAMSAuthenticationService } from '@backend-client/services/iamsauthentication.service';
import { IAMSScopesInfoService } from '@backend-client/services/iamsscopes-info.service';
import { AuthenticationCookieService } from '@backend-client/authentication-cookie.service';
import { NotificationsService } from '@shared/services/notifications';
import { ServerStatusService } from '@shared/services/server-status.service';
import { environment } from '@environments/environment';
import { CustomTokenResponse } from '@backend-client/models/custom-token-response';
import { SessionCookieResponse } from '@backend-client/models/session-cookie-response';
import { LoginStepperModalComponent } from './login-stepper/login-stepper-modal.component';
import { TimeBasedOneTimePasswordsMultiFactorAuthenticationService } from '@backend-client/services/time-based-one-time-passwords-multi-factor-authentication.service';
import { TotpService } from './totp.service';
import { MultiFactorAuthenticationService } from '@backend-client/services/multi-factor-authentication.service';
import { EmailCodesMultiFactorAuthenticationService } from '@backend-client/services/email-codes-multi-factor-authentication.service';
import { EmailSendStatus } from './authentication-container/steps/enter-authenticator/email-send-status.enum';
import { MfaMethod } from './mfa-method.enum';
import { TubMfaChallengeResponseTokenResponse } from '@backend-client/models/tub-mfa-challenge-response-token-response';
import { MfaOption } from '@backend-client/models/mfa-option';
import { DialogService } from '@shared/components/dialog/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly DESIRED_SCOPES_ARRAY = [ 'all' ];

  private activeAuthenticationDialog$: Observable<void> = null;
  private userScopesSyncCache: SwaggerSecurityScopes[] = [];
  private resetScopesCache$ = new Subject<void>();
  private userScopes$: BehaviorSubject<SwaggerSecurityScopes[]> = new BehaviorSubject<SwaggerSecurityScopes[]>([null]);

  constructor(private angularFireAuth: AngularFireAuth,
              private iamsAuthenticationService: IAMSAuthenticationService,
              private iamsScopesInfoService: IAMSScopesInfoService,
              private authenticationCookieService: AuthenticationCookieService,
              public overlay: Overlay,
              private dialogService: DialogService,
              private notificationsService: NotificationsService,
              private serverStatusService: ServerStatusService,
              private timeBasedOneTimePasswordsMultiFactorAuthenticationService: TimeBasedOneTimePasswordsMultiFactorAuthenticationService,
              private totpService: TotpService,
              private multiFactorAuthenticationService: MultiFactorAuthenticationService,
              private emailCodesMultiFactorAuthenticationService: EmailCodesMultiFactorAuthenticationService) {
    this.angularFireAuth.user.subscribe((user) => {
      this.loggedInEmail.next(user?.email);
      this.loggedInUserId$.next(user?.uid);
    });
  }

  public loggedInEmail: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public loggedInUserId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public hasLoggedOut$: Subject<boolean> = new Subject<boolean>();

  public async attemptLoginWithEmailAndPassword(email: string, password: string): Promise<string> {
    // TODO: this does not belong here, it should be done during some kind of initialisation step
    await this.angularFireAuth.setPersistence(
      environment.production ? auth.Auth.Persistence.NONE : auth.Auth.Persistence.LOCAL
    );

    // Log in to Iams using the firebase AuthenticatesUserCredential
    const authenticatedUserCredential = await this.angularFireAuth.signInWithEmailAndPassword(email, password);
    return await authenticatedUserCredential.user.getIdToken();
  }
  public finishLoginSSO(): void {
       // trigger a cache reset for the scopes
      this.resetScopesCache$.next();

      this.subscribeToServerStatus();

      firebase.database().goOnline();

  }
  public async attemptLogin(authenticatedIdToken: string, mfaChallengeResponseToken?: string): Promise<void> {

    await this.loginWithAuthenticatedIdToken(authenticatedIdToken, mfaChallengeResponseToken);

    // trigger a cache reset for the scopes
    this.resetScopesCache$.next();

    this.subscribeToServerStatus();

    firebase.database().goOnline();

    await this.setUserScopes();

    // Don't await permission, as this can be accepted/denied without having an impact on the execution of the app
    this.notificationsService.getPermissionToReceivePushNotifications();
  }

  public async getMfaOptions(authenticatedIdToken: string): Promise<MfaOption[]> {

    const mfaOptionParams: MultiFactorAuthenticationService.MfaGetMfaOptionsParams = {
      scopes: this.DESIRED_SCOPES_ARRAY,
      idToken: authenticatedIdToken
    };

    return (await firstValueFrom(this.multiFactorAuthenticationService.MfaGetMfaOptions(mfaOptionParams))).options;
  }

  /**
   * Listens to the server status and logs out the user if the server becomes offline
   */
  private subscribeToServerStatus(): void {
    this.serverStatusService.isServerOnline.subscribe(async () => {
      if (!this.serverStatusService.isOnline) {
        await this.logOut();
      }
    });
  }

  public async isScopePresent(scope: SwaggerSecurityScopes): Promise<boolean> {
    return firstValueFrom(this.isScopePresent$(scope).pipe(take(1)));
  }

  public isScopePresent$(scope: SwaggerSecurityScopes): Observable<boolean> {
    return this.userScopes$.pipe(
      map(userScopes => {
        return userScopes.includes(scope);
      })
    );
  }

  public async resetPassword(email: string, continueUrl: string): Promise<void> {
    await this.angularFireAuth.sendPasswordResetEmail(email, { url: continueUrl });
  }

  public async createUser(email: string, password: string): Promise<void> {
    const authenticatedUserCredential = await this.angularFireAuth.createUserWithEmailAndPassword(email, password);
    const authenticatedIdToken = await authenticatedUserCredential.user.getIdToken();
    await this.loginWithAuthenticatedIdToken(authenticatedIdToken);
  }

  /**
   * Adds MFA to an account via security token
   * @param data Security token and secret
   */
  public async addMfa(data: { token: string, secret: string }): Promise<void> {
    const numberOfCurrentMfaMethods = (await this.totpService.getTotpMethodsForAccount()).length;

    await firstValueFrom(this.timeBasedOneTimePasswordsMultiFactorAuthenticationService.TotpMfaRegisterNewSecret({
      code: data.token,
      secret: data.secret,
      label: `Authenticator ${numberOfCurrentMfaMethods + 1}`
    }));
  }

  /**
   * Authenticates security token
   * @param idToken as returned during the actual sign in attempt
   * @param authCode Security token from Authenticator App
   * @param mfaMethod The MFA Method to validate against
   */
  public async authenticateMfa(idToken: string, authCode: string, mfaMethod: MfaMethod): Promise<string> {
    let response: TubMfaChallengeResponseTokenResponse;
    const params = {
      idToken,
      body: { code: authCode }
    };

    if (mfaMethod === MfaMethod.totp) {
      response = await firstValueFrom(this.timeBasedOneTimePasswordsMultiFactorAuthenticationService.TotpMfaPostChallengeResponse(params));
    } else if (mfaMethod === MfaMethod.email) {
      response = await firstValueFrom(this.emailCodesMultiFactorAuthenticationService.EmailMfaPostChallengeResponse(params));
    }

    return response.token;
  }

  private async loginWithAuthenticatedIdToken(idToken: string, mfaChallengeResponseToken?: string): Promise<void> {

    const customTokenResponse: CustomTokenResponse =
      await firstValueFrom(this.iamsAuthenticationService.IamsAuthenticationGetCustomToken({
        idToken,
        scope: this.DESIRED_SCOPES_ARRAY,
        mfaChallengeResponse: mfaChallengeResponseToken
      }));
    await this.loginWithCustomToken(customTokenResponse);
  }

  public async loginWithCustomToken(customTokenResponse: CustomTokenResponse) {
    await this.angularFireAuth.setPersistence(
      environment.production ? auth.Auth.Persistence.NONE : auth.Auth.Persistence.LOCAL
    );
    const customToken = customTokenResponse.customToken;
    const authorisedUserCredential = await this.angularFireAuth.signInWithCustomToken(customToken);
    const authorisedIdToken = await authorisedUserCredential.user.getIdToken();
    const sessionCookieResponse: SessionCookieResponse = await firstValueFrom(this.iamsAuthenticationService.IamsAuthenticationGetSessionCookie({
      idToken: authorisedIdToken,
      setCookie: false
    }));
    if (sessionCookieResponse.sessionCookie) {
      this.authenticationCookieService.updateSessionCookie(sessionCookieResponse.sessionCookie);
    }
  }

  public async emailTotp(idToken: string, configurationId: string): Promise<EmailSendStatus> {
    try {
      const emailMfaGetChallengeParams: EmailCodesMultiFactorAuthenticationService.EmailMfaGetChallengeParams = {
        idToken,
        configurationId
      };
      await firstValueFrom(this.emailCodesMultiFactorAuthenticationService.EmailMfaGetChallenge(emailMfaGetChallengeParams));
      return EmailSendStatus.Successful;
    } catch (e) {
      console.error(e);
      return EmailSendStatus.Failed;
    }
  }

  public openAuthenticationDialog(): Observable<void> {
    // if there is no active authentication dialog observable, that means there is no current dialog present
    if (this.activeAuthenticationDialog$ === null) {
      // create the dialog observable
      this.activeAuthenticationDialog$ = this.angularFireAuth.user.pipe(
        take(1),
        switchMap((currentUser) => {
          const dialogRef = this.dialogService.openDialog(LoginStepperModalComponent,
            { user: currentUser },
            { panelClass: 'app-no-padding-dialog' });
          return dialogRef.afterClosed();
        }),
        // using tap, ensure that when afterClosed has fired, that the activeAuthenticationDialog$ field is cleared
        tap(() => this.activeAuthenticationDialog$ = null),
        // ensure that there is only one dialog which shares the output with all observers
        share());
    }

    // whether we just created a new dialog or not, return the active dialog
    return this.activeAuthenticationDialog$;
  }

  public async logOut(autoRedirect: boolean = true): Promise<void> {
    try {
      await this.notificationsService.unsubscribeFromNotifications();
    } catch (err) {
      console.error('failed to unsubscribe from push notifications');
    }

    try {
      await firstValueFrom(this.iamsAuthenticationService.IamsAuthenticationLogout());
    } catch (err) {
      // Allow the TUB call to fail
      console.error('failed to log out of TUB securely');
    }

    this.loggedInEmail.next(null);
    this.userScopes$.next([null]);
    this.hasLoggedOut$.next(true);

    firebase.database().goOffline();
    await firestore().terminate();
    // This causes the stored logged-in email address to be reset
    await this.angularFireAuth.signOut();
    this.authenticationCookieService.updateSessionCookie(null);

    if (autoRedirect) {
      // Used instead of router to nuke all local variables / subscriptions  when logged out
      location.href = '/home';
    }
  }

  public async setUserScopes(ignoreLoginCheck: boolean = false): Promise<void> {
    if (!this.loggedInEmail && !ignoreLoginCheck) {
      return;
    }
    await firstValueFrom(this.iamsScopesInfoService.IamsScopesInfoGetCurrentlyActiveScopes().pipe(
     map(scopesResponse => scopesResponse.scopes as SwaggerSecurityScopes[]),
     resettableCache(this.resetScopesCache$),
     tap(scopes => {
       this.userScopes$.next(scopes);
       this.userScopesSyncCache = scopes;
     })
   ));
 }

  public getUserScopes$(): Observable<SwaggerSecurityScopes[]> {
    return this.userScopes$;
  }
}
