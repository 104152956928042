import { DashboardSoftDeletedTubMoment } from '@backend-client/models/dashboard-soft-deleted-tub-moment';

import { AppointmentMomentLinkConnectionType, AppointmentMomentPosition, EditedMomentData, Moment } from '../moment';
import { MomentType } from '../moment-type';

export class AppointmentMoment extends Moment {
  readonly typeName = MomentType.Appointment;

  public appointmentDate: string;
  public appointmentTime: string;
  public therapySessionNumber: number;
  public appointmentOutcome: string;
  public appointmentOutcomeOther: string;
  public howPatientIsFunctioning: string;

  public noteRef: string;
  public riskAssessmentRef: string;
  public cgiRef: string;
  public attachmentsRef: string[];

  public edited: EditedMomentData;
  public appointmentRef: string = undefined;
  public appointmentMomentPosition: AppointmentMomentPosition = null;

  public get displayAppointmentConnectors(): AppointmentMomentLinkConnectionType {
    if (!this.noteRef && !this.cgiRef && !this.attachmentsRef && !this.riskAssessmentRef && !this.edited && !this.deleted?.newMomentRef ||
      !this.noteRef && !this.cgiRef && this.attachmentsRef?.length === 0 && !this.riskAssessmentRef && !this.edited && !this.deleted?.newMomentRef) {
      return null;
    }

    if (this.appointmentMomentPosition === AppointmentMomentPosition.start) {
      return AppointmentMomentLinkConnectionType.StartLink;
    }

    if (this.appointmentMomentPosition === AppointmentMomentPosition.last) {
      return AppointmentMomentLinkConnectionType.EndLink;
    }

    return AppointmentMomentLinkConnectionType.MiddleLink;
  }

  constructor(options: {
    id: string,
    timestamp: Date,
    createdByUserId: number,
    author: string,
    appointmentOutcome: string,
    appointmentOutcomeOther: string,
    appointmentDate: string,
    appointmentTime: string,
    therapySessionNumber: number,
    howPatientIsFunctioning: string,
    noteRef: string,
    riskAssessmentRef: string,
    cgiRef: string,
    attachmentsRef: string[],
    deleted?: DashboardSoftDeletedTubMoment,
    edited?: EditedMomentData,
    appointmentRef?: string,
    appointmentMomentPosition?: AppointmentMomentPosition,
  }) {
    super(options.id, options.timestamp, options.author, options.deleted);
    this.appointmentDate = options.appointmentDate;
    this.appointmentTime = options.appointmentTime;
    this.therapySessionNumber = options.therapySessionNumber;
    this.appointmentOutcome = options.appointmentOutcome;
    this.appointmentOutcomeOther = options.appointmentOutcomeOther;
    this.howPatientIsFunctioning = options.howPatientIsFunctioning;

    this.noteRef = options.noteRef;
    this.riskAssessmentRef = options.riskAssessmentRef;
    this.cgiRef = options.cgiRef;
    this.attachmentsRef = options.attachmentsRef;

    this.edited = options.edited;
    this.appointmentRef = options.appointmentRef;
    this.appointmentMomentPosition = options.appointmentMomentPosition;
  }
}
