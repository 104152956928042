<div *ngIf="loading" class="loading-app-container">
  <div class="loading-app">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<app-main-nav *ngIf="!loading && navigationVisible; else default">
  <router-outlet></router-outlet>
</app-main-nav>

<ng-template #default>
  <router-outlet></router-outlet>
</ng-template>
