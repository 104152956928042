import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'invokeFunctionWithContext',
    standalone: false
})
export class InvokeFunctionWithContextPipe implements PipeTransform {

  /**
   * Allows a function to be executed within a pipe from the HTML template, without it constantly being triggered by the Angular change
   * detection.
   * @param func The function to be executed
   * @param context The context to be bound to
   * @param args The arguments of the function
   */
  transform<T extends (...args) => unknown>(func: T, context: any, ...args: Parameters<T>): ReturnType<T> {
    const boundFunction = func.bind(context);
    return boundFunction(...args);
  }

}
