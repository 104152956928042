import { Pipe, PipeTransform } from '@angular/core';
import { Moment, AppointmentMomentLinkConnectionType } from './moment/moment';

@Pipe({
    name: 'isLinkedAppointmentMoment',
    standalone: false
})
export class IsLinkedAppointmentMomentPipe implements PipeTransform {

  transform(index: number, moments: Moment[]): boolean {
    let nextLinkConnectionType;
    let prevLinkConnectionType;

    // Assign nextLinkConnectionType
    for (let i = (index - 1); i >= 0; i--) {
      if (moments[i].displayAppointmentConnectors !== AppointmentMomentLinkConnectionType.None) {
        nextLinkConnectionType = moments[i].displayAppointmentConnectors;
        break;
      }
    }

    // Assign prevLinkConnectionType
    for (let i = (index + 1); i < moments.length; i++) {
      if (moments[i].displayAppointmentConnectors !== AppointmentMomentLinkConnectionType.None) {
        prevLinkConnectionType = moments[i].displayAppointmentConnectors;
        break;
      }
    }

    // Return true if moment at index is a connection between a start and an end link
    return prevLinkConnectionType === AppointmentMomentLinkConnectionType.StartLink
    || nextLinkConnectionType === AppointmentMomentLinkConnectionType.EndLink
    || prevLinkConnectionType === AppointmentMomentLinkConnectionType.MiddleLink
    || nextLinkConnectionType === AppointmentMomentLinkConnectionType.MiddleLink;
  }

}
