import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hashString',
    standalone: false
})
export class HashStringPipe implements PipeTransform {

  async transform(stringToHash: string): Promise<string> {
    const textEncoder = new TextEncoder();
    const returnedArrayBuffer: ArrayBuffer = await crypto.subtle.digest('SHA-256', textEncoder.encode(stringToHash));

    // Create a character array
    const characterArray = Array.from(new Uint8Array(returnedArrayBuffer));
    // Create text as a string
    const textString = characterArray.map(char => String.fromCharCode(char)).join('');
    // Convert to base64
    const hashedString = btoa(textString);

    return hashedString;
  }
}
