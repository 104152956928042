import { Pipe, PipeTransform } from '@angular/core';
import { Moment, MomentLinkConnectionType } from './moment/moment';

@Pipe({
    name: 'isLinkedMoment',
    standalone: false
})
export class IsLinkedMomentPipe implements PipeTransform {

  transform(index: number, moments: Moment[]): boolean {

    let nextLinkConnectionType;
    let prevLinkConnectionType;

    // Assign nextLinkConnectionType
    for (let i = (index - 1); i >= 0; i--) {
      if (moments[i].displayLinkConnection !== MomentLinkConnectionType.None) {
        nextLinkConnectionType = moments[i].displayLinkConnection;
        break;
      }
    }

    // Assign prevLinkConnectionType
    for (let i = (index + 1); i < moments.length; i++) {
      if (moments[i].displayLinkConnection !== MomentLinkConnectionType.None) {
        prevLinkConnectionType = moments[i].displayLinkConnection;
        break;
      }
    }

    // Return true if moment at index is a connection between a start and an end link
    return prevLinkConnectionType === MomentLinkConnectionType.StartLink || nextLinkConnectionType === MomentLinkConnectionType.EndLink;
  }

}
