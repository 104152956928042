import { Component } from '@angular/core';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { CallMoment } from './call-moment';
import { MomentService } from '../moment.service';

@Component({
    selector: 'app-call-moment',
    templateUrl: './call-moment.component.html',
    styleUrls: ['./call-moment.component.scss'],
    standalone: false
})

export class CallMomentComponent {
  public momentData: CallMoment;
  public featureFlags$ = this.featureFlagService.featureFlags$;

  constructor(
    public momentService: MomentService,
    private featureFlagService: FeatureFlagService,
  ) { }
}
