<div fxLayout="column" class="container-padding">
  <div fxFlex fxLayout="row">
    <button mat-button (click)="cancel()"><mat-icon>chevron_left</mat-icon> Return</button>
  </div>
  <mat-stepper #stepper>
    <mat-step [stepControl]="appointmentFormGroup" [editable]="!savingCompleted">
      <form [formGroup]="appointmentFormGroup">
        <div fxLayout="column" fxLayoutGap="12px" class="stepper-content">
          <div *ngIf="editMode" [formGroup]="editReasonFormGroup">
            <mat-form-field>
              <mat-label>Reason for editing moment</mat-label>
              <textarea cdkTextareaAutosize matInput formControlName="reason" cdkAutosizeMinRows="2" placeholder="Enter your reason here..."></textarea>
              <mat-error *ngIf="editReasonFormGroup.controls['reason'].hasError('required')">
                Reason for editing moment is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="editReasonFormGroup.controls['reason'].hasError('trimErrorStart')">
                Reason for editing moment cannot contain leading whitespaces
              </mat-error>
              <mat-error *ngIf="editReasonFormGroup.controls['reason'].hasError('trimErrorEnd')">
                Reason for editing moment cannot contain trailing whitespaces
              </mat-error>
              <mat-error *ngIf="editReasonFormGroup.controls['reason'].hasError('multipleWhitespacesError')">
                Reason for editing moment cannot contain multiple consecutive whitespaces
              </mat-error>
              <mat-error *ngIf="!editReasonFormGroup.controls['reason'].hasError('required') && editReasonFormGroup.controls['reason'].hasError('lengthError')">
                Reason for editing moment must contain more than 3 characters
              </mat-error>
            </mat-form-field>
          </div>

          <ng-template matStepLabel>Appointment Information</ng-template>
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field fxFlex fxFlex.gt-sm="80">
              <mat-label>Date of appointment</mat-label>
              <input matInput [matDatepicker]="picker" [max]="today" formControlName="appointmentDate" />
              <mat-hint>DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Select time</mat-label>
              <input matInput [matTimepicker]="timePicker" formControlName="appointmentTime">
              <mat-timepicker-toggle matIconSuffix [for]="timePicker" />
              <mat-timepicker #timePicker />
              <mat-hint>Enter time in 24 hour format</mat-hint>
              <mat-error *ngIf="appointmentFormGroup.controls['appointmentTime'].hasError('invalidTimeFuture')">
                Time cannot be in the future
              </mat-error>
            </mat-form-field>
          </div>

          <mat-form-field>
            <mat-label>Outcome of appointment</mat-label>
            <mat-select formControlName="appointmentAttendanceStatus">
              <mat-option *ngFor="let status of attendanceStates; trackByProperty: 'attendanceStates'" [value]="status">
                {{ status }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="appointmentFormGroup.controls['appointmentAttendanceStatus'].hasError('required')">
              Outcome of appointment is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="appointmentFormGroup.get('appointmentAttendanceStatus').value === 'Other'">
            <mat-label>Reason for other</mat-label>
            <input matInput formControlName="appointmentAttendanceStatusOther">
            <mat-error *ngIf="appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('required')">
              Reason for selecting "Other" is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('trimErrorStart')">
              Reason for selecting "Other" cannot contain leading whitespaces
            </mat-error>
            <mat-error *ngIf="appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('trimErrorEnd')">
              Reason for selecting "Other" cannot contain trailing whitespaces
            </mat-error>
            <mat-error *ngIf="appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('multipleWhitespacesError')">
              Reason for selecting "Other" cannot contain multiple consecutive whitespaces
            </mat-error>
            <mat-error *ngIf="!appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('required') && appointmentFormGroup.controls['appointmentAttendanceStatusOther'].hasError('lengthError')">
              Reason for selecting "Other" must contain more than 3 characters
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Session number</mat-label>
            <input matInput placeholder="Number of therapy sessions so far" formControlName="appointmentSessionNumber" type="number">
            <mat-error *ngIf="appointmentFormGroup.controls['appointmentSessionNumber'].hasError('required')">
              Number of Therapy sessions user has recieved is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <ng-container *ngIf="!editMode">
            <mat-accordion *ngIf="previousNote else noPreviousNote">
              <mat-expansion-panel class="previous">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Previous note
                  </mat-panel-title>
                  <mat-panel-description class="previous-description">
                    {{ previousNote?.author }} &nbsp; | &nbsp; {{ previousNote?.ts | date: 'EEEE, MMMM d, y, h:mm:ss a' }}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="previous-content"><p [innerText]="previousNote?.data?.note"></p></div>
              </mat-expansion-panel>
            </mat-accordion>
            <ng-template #noPreviousNote><div>There are no previous notes for {{ patient?.email | displayUserName: patient?.firstName : patient?.lastName }}.</div></ng-template>
          </ng-container>

          <div [formGroup]="noteFormGroup">
            <mat-form-field>
              <mat-label>Notes</mat-label>
              <textarea cdkTextareaAutosize #text matInput formControlName="note" cdkAutosizeMinRows="20" placeholder="Enter your notes here..."></textarea>
              <mat-error *ngIf="noteFormGroup.controls['note'].hasError('required')">
                Notes are <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="noteFormGroup.controls['note'].hasError('trimErrorStart')">
                Notes cannot contain leading whitespaces
              </mat-error>
              <mat-error *ngIf="noteFormGroup.controls['note'].hasError('trimErrorEnd')">
                Notes cannot contain trailing whitespaces
              </mat-error>
              <mat-error *ngIf="noteFormGroup.controls['note'].hasError('multipleWhitespacesError')">
                Notes cannot contain multiple consecutive whitespaces
              </mat-error>
              <mat-error *ngIf="!noteFormGroup.controls['note'].hasError('required') && noteFormGroup.controls['note'].hasError('lengthError')">
                Notes must contain more than 3 characters
              </mat-error>
            </mat-form-field>
          </div>

          <div class="function-question">
            <p><label>{{ functionQuestion }}</label></p>
            <mat-radio-group formControlName="functionResult">
              <mat-radio-button *ngFor="let answer of functionAnswers; trackByProperty: 'functionQuestionAnswers'" [value]="answer">{{ answer }}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="phqFormGroup" [editable]="!savingCompleted">
      <form [formGroup]="phqFormGroup">
        <div fxLayout="column" fxLayoutGap="32px" class="stepper-content questions">
          <ng-template matStepLabel>PHQ 9</ng-template>
          <p>{{ phqGadInitialQuestion }}</p>
          <div *ngFor="let phqQuestion of phqQuestions; let i = index; trackByProperty: 'phqQuestionsAnswers'" class="phq-question">
            <label>{{i + 1}}. {{ phqQuestion }}</label>
            <mat-radio-group formControlName="{{ phqQuestionsKeys[i] }}">
              <mat-radio-button *ngFor="let answer of phqGadAnswers; let answerIndex = index; trackByProperty: 'phqGadQuestionAnswers'" [value]="answerIndex">{{ answer }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            Total Score: {{ phqScore }}
          </div>
        </div>
        <p class="small-print"><small>UHS Rev 4/2020 - Developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke and colleagues, with an educational grant from Pfizer Inc.</small></p>

        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="gadFormGroup" [editable]="!savingCompleted">
      <form [formGroup]="gadFormGroup">
        <div fxLayout="column" fxLayoutGap="32px" class="stepper-content questions">
          <ng-template matStepLabel>GAD 7</ng-template>
          <p>{{ phqGadInitialQuestion }}</p>
          <div *ngFor="let gadQuestion of gadQuestions; let i = index; trackByProperty: 'gadQuestionsAnswers'" class="gad-question">
            <label>{{i + 1}}. {{ gadQuestion }}</label>
            <mat-radio-group formControlName="{{ gadQuestionsKeys[i] }}">
              <mat-radio-button *ngFor="let answer of phqGadAnswers; let answerIndex = index; trackByProperty: 'phqGadQuestionsAnswers'" [value]="answerIndex">{{ answer }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            Total Score: {{ gadScore }}
          </div>
        </div>
        <p class="small-print"><small>UHS Rev 4/2020 - Developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke and colleagues, with an educational grant from Pfizer Inc.</small></p>


        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="cgiFormGroup" [editable]="!savingCompleted">
      <form [formGroup]="cgiFormGroup">
        <ng-template matStepLabel>CGI-S/ CGI-I</ng-template>

        <div fxLayout="column" fxLayoutGap="24px" class="stepper-content">
          <ng-container *ngIf="!editMode">
            <mat-accordion *ngIf="previousCGI; else noPreviousCGI">
              <mat-expansion-panel class="previous">
                <mat-expansion-panel-header>
                  <mat-panel-title> Previous CGI Severity / CGI Improvement </mat-panel-title>
                  <mat-panel-description class="previous-description">
                    {{ previousCGI?.author }} &nbsp; | &nbsp; {{ previousCGI?.ts | date: 'EEEE, MMMM d, y, h:mm:ss a' }}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                  <p class="highlight">
                    <span>CGI Severity:</span> {{ previousCGI?.data?.cgiSeverity }} -
                    {{ cgiSeverityAnswers[previousCGI?.data?.cgiSeverity - 1] }}
                  </p>
                  <p class="highlight">
                    <span>CGI Improvement:</span> {{ previousCGI?.data?.cgiImprovement }} -
                    {{ cgiImprovementAnswers[previousCGI?.data?.cgiImprovement - 1] }}
                  </p>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <ng-template #noPreviousCGI>
              <div>
                There are no previous CGI scores for {{ patient?.email | displayUserName: patient?.firstName : patient?.lastName }}.
              </div>
            </ng-template>
          </ng-container>

          <div>
            <p>{{ cgiSeverityExplanation }}</p>
            <p class="highlight-question">{{ cgiSeverityQuestion }}</p>
            <p>{{ cgiSeverityQuestionHint }}</p>

            <mat-form-field class="form-group">
              <mat-label for="cgiSeverity">CGI Severity</mat-label>
              <mat-select id="cgiSeverity" #cgiSeverity formControlName="cgiSeverityResult">
                <mat-option *ngFor="let option of cgiSeverityAnswers; let i = index; trackByProperty: 'cgiSeverityQuestionAnswers'" [value]="cgiSeverityKeys[i]">{{ option }}</mat-option>
              </mat-select>
              <mat-error *ngIf="cgiFormGroup.controls['cgiSeverityResult'].hasError('required')">
                CGI Severity score is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <p>{{ cgiImprovementExplanation }}</p>
            <p class="highlight-question">{{ cgiImprovementQuestion }}</p>

            <mat-form-field class="form-group">
              <mat-label for="cgiImprovement">CGI Improvement</mat-label>
              <mat-select id="cgiImprovement" #cgiImprovement formControlName="cgiImprovementResult">
                <mat-option *ngFor="let option of cgiImprovementAnswers; let i = index; trackByProperty: 'cgiImprovementQuestionAnswers'" [value]="cgiImprovementKeys[i]">{{ option }}</mat-option>
              </mat-select>
              <mat-error *ngIf="cgiFormGroup.controls['cgiImprovementResult'].hasError('required')">
                CGI Improvement score is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="attachmentsFormGroup" [editable]="!savingCompleted">
      <form [formGroup]="attachmentsFormGroup">
        <ng-template matStepLabel>Attach Therapy Session Files</ng-template>

        <div fxLayout="column" fxLayoutGap="24px">
          <div fxFlex fxLayout="column" class="new-moment">
            <p class="header">Upload Therapy Session Files</p>
            <div fxFlex fxLayout="row" fxLayoutGap="12px">
              <button mat-flat-button (click)="appointmentMomentService.uploadAttachment()">Upload File</button>
              <button *ngIf="!(featureFlags$ | async)?.isGoogleDriveEnabled" mat-flat-button (click)="appointmentMomentService.uploadCallAttachment()">
                Upload Call Transcript
              </button>
            </div>
          </div>

          <p class="information-text">Therapy session files can only be attached to one appointment moment.</p>

          <div fxLayout="row" fxLayoutAlign="end" class="reload-container">
            <button mat-flat-button [disabled]="appointmentMomentService.fetchingAttachments$ | async" (click)="appointmentMomentService.reloadAttachments()"><mat-icon>refresh</mat-icon> reload</button>
            <button mat-flat-button [disabled]="appointmentMomentService.fetchingAttachments$ | async" (click)="appointmentMomentService.loadMoreAttachments()"><mat-icon>cached</mat-icon> Load More</button>
          </div>

          <div *ngIf="appointmentMomentService.fetchingAttachments$ | async; else attachment" fxLayout="row" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
          </div>

          <ng-template #attachment>
            <div fxLayout="column" fxLayoutGap="24px" class="attachment-container">
              <div *ngIf="appointmentMomentService.attachments.length === 0">
                <p>There are no file moments for {{ patient?.email | displayUserName: patient?.firstName : patient?.lastName }}.</p>
              </div>

              <mat-card *ngFor="let attachment of appointmentMomentService.attachments; trackByProperty: 'appointmentAttachments'" class="attachment-moment">
                <mat-card-header>
                  <div fxFlex fxLayout="row">
                    <mat-icon *ngIf="attachment.typeName === 'google-file'; else defaultIcon" svgIcon="google-drive" class="file-icon" [ngStyle]="{color: undefined}"></mat-icon>
                    <ng-template #defaultIcon>
                      <mat-icon class="file-icon" [ngStyle]="{color: attachment.typeName === 'file' ? 'rgb(253, 180, 127)' : 'rgb(70, 203, 243)'}">
                        {{ attachment.typeName === 'file' ? 'save' : 'call' }}
                      </mat-icon>
                    </ng-template>

                    <div fxFlex fxLayout="column" fxLayout.gt-md="row">
                      <p class="heading" fxFlex>{{ attachment.fileName }}</p>
                      <span>{{ attachment.author }} &nbsp; | &nbsp; {{ attachment.timestamp | date: 'EEEE, MMMM d, y, h:mm:ss a' }}</span>
                    </div>
                    <mat-checkbox (change)="checkedAttachment(attachment)" (click)="$event.stopPropagation()" [checked]="attachment.checked"></mat-checkbox>
                  </div>
                </mat-card-header>
                <mat-card-content *ngIf="attachment?.description">
                  {{ attachment?.description }}
                </mat-card-content>
                <mat-card-footer>
                  <button *ngIf="attachment.typeName === 'google-file'" mat-button (click)="momentService.addEditDescriptionForGoogleFileMoment(attachment, attachment?.description)">{{ !attachment?.description ? 'Add' : 'Edit' }} Description</button>
                  <button *ngIf="attachment.typeName === 'google-file'" mat-button (click)="momentService.previewGoogleDriveMoment(attachment)">Preview</button>
                  <button *ngIf="attachment.typeName === 'file' || attachment.typeName === 'call'" mat-button (click)="momentService.downloadGoogleDriveMoment(attachment)">Download</button>
                </mat-card-footer>
              </mat-card>
            </div>
          </ng-template>
        </div>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Save</ng-template>

      <div class="stepper-content questions">
        <div *ngIf="savingData" class="form-status saving" fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner></mat-spinner>
        </div>

        <div *ngIf="errorSaving || (appointmentMomentService.errorFetchingAttachments$ | async)" class="form-status error" fxLayout="column" fxLayoutAlign="center center">
          <div fxFlex fxLayout="row" fxLayoutGap="8px">
            <mat-icon class="error">error</mat-icon>
            <p class="header">
              <span *ngIf="errorSaving">Encountered error whilst trying to save data!</span>
              <span *ngIf="appointmentMomentService.errorFetchingAttachments$ | async">Unable to save encountered error whilst trying to fetch attachments</span>
            </p>
          </div>
          <p *ngIf="appointmentMomentService.errorInformation$ | async">
            {{ appointmentMomentService.errorInformation$ | async }}
          </p>
        </div>

        <div *ngIf="!savingData && !savingCompleted" class="form-status validation" fxLayout="column" fxLayoutGap="12px">
          <p>Please recheck all data being submitted is for {{ patient?.email | displayUserName: patient?.firstName : patient?.lastName }} before saving.</p>
          <div *ngIf="editMode">
            <p class="header">Edit Reason</p>
            <p>
              <mat-icon [ngClass]="{error: editReasonFormGroup.get('reason').hasError('required')}">
                {{ editReasonFormGroup.get('reason').hasError('required') ? 'error' : 'check_circle' }}
              </mat-icon> {{ editReasonFormGroup.get('reason').hasError('required') ? 'Reason for editing is required' : 'Ready to save' }}
          </div>

          <div>
            <p class="header">Appointment Information</p>
            <p>
              <mat-icon [ngClass]="{error: appointmentFormGroup.invalid}">
                {{ appointmentFormGroup.invalid ? 'error' : 'check_circle' }}
              </mat-icon> {{ appointmentFormGroup.invalid ? 'Form contains errors:' : 'Ready to save' }}
            </p>
            <div>
              <p>
                <mat-icon [ngClass]="{error: (appointmentFormGroup.get('appointmentDate').value > today)}">
                  {{ (appointmentFormGroup.get('appointmentDate').value > today) ? 'error' : 'check_circle' }}
                </mat-icon>
                Date <ng-container *ngIf="appointmentFormGroup.get('appointmentDate').value > today">cannot be in the future</ng-container>
              </p>
              <p>
                <mat-icon [ngClass]="{error: appointmentFormGroup.get('appointmentTime').hasError('required') || appointmentFormGroup.get('appointmentTime').hasError('invalidTimeFuture')}">
                  {{ appointmentFormGroup.get('appointmentTime').hasError('required') || appointmentFormGroup.get('appointmentTime').hasError('invalidTimeFuture') ? 'error' : 'check_circle' }}
                </mat-icon>
                Time <ng-container *ngIf="appointmentFormGroup.get('appointmentTime').hasError('required')">is required</ng-container>
                <ng-container *ngIf="appointmentFormGroup.get('appointmentTime').hasError('invalidTimeFuture')">cannot be in the future</ng-container>
              </p>
              <p>
                <mat-icon [ngClass]="{error: appointmentFormGroup.get('appointmentAttendanceStatus').hasError('required')}">
                  {{ appointmentFormGroup.get('appointmentAttendanceStatus').hasError('required') ? 'error' : 'check_circle' }}
                </mat-icon>
                Outcome of appointment <ng-container *ngIf="appointmentFormGroup.get('appointmentAttendanceStatus').hasError('required')">is required</ng-container>
              </p>
              <p *ngIf="appointmentFormGroup.get('appointmentAttendanceStatusOther').hasError('required')">
                <mat-icon [ngClass]="{error: appointmentFormGroup.get('appointmentAttendanceStatusOther').hasError('required')}">
                  {{ appointmentFormGroup.get('appointmentAttendanceStatusOther').hasError('required') ? 'error' : 'check_circle' }}
                </mat-icon>
                Reason for Other <ng-container *ngIf="appointmentFormGroup.get('appointmentAttendanceStatusOther').hasError('required')">is required</ng-container>
              </p>
              <p>
                <mat-icon [ngClass]="{error: appointmentFormGroup.get('appointmentSessionNumber').hasError('required')}">
                  {{ appointmentFormGroup.get('appointmentSessionNumber').hasError('required') ? 'error' : 'check_circle' }}
                </mat-icon>
                Session number <ng-container *ngIf="appointmentFormGroup.get('appointmentSessionNumber').hasError('required')">is required</ng-container>
              </p>
              <p>
                <mat-icon [ngClass]="{error: noteFormGroup.get('note').hasError('required')}">
                  {{ noteFormGroup.get('note').hasError('required') ? 'error' : 'check_circle' }}
                </mat-icon>
                Notes <ng-container *ngIf="noteFormGroup.get('note').hasError('required')">are required</ng-container>
              </p>
              <p *ngIf="!sessionCancelled">
                <mat-icon [ngClass]="{error: appointmentFormGroup.get('functionResult').hasError('required')}">
                  {{ appointmentFormGroup.get('functionResult').hasError('required') ? 'error' : 'check_circle' }}
                </mat-icon>
                How is the patient functioning question <ng-container *ngIf="appointmentFormGroup.get('functionResult').hasError('required')">is required</ng-container>
              </p>
            </div>
          </div>

          <div>
            <p class="header">PHQ9</p>
            <button *ngIf="!sessionCancelled && phqFormGroup.errors !== null && !phqGadRequired || !sessionCancelled && phqFormGroup.dirty && !phqGadRequired" (click)="markGadPhqAsNotRequired()" mat-flat-button class="warn">
              Mark PHQ9 / GAD7 as not required
            </button>
            <p *ngIf="!sessionCancelled && phqFormGroup.invalid && phqFormGroup.errors !== null || !sessionCancelled && phqFormGroup.invalid && phqFormGroup.dirty">
              <mat-icon class="error">error</mat-icon> Form contains errors:
            </p>
            <p *ngIf="phqFormGroup.valid && phqFormGroup.dirty"><mat-icon>check_circle</mat-icon> Ready to save</p>
            <p *ngIf="!sessionCancelled && phqFormGroup.errors === null && phqFormGroup.pristine">
              <mat-icon class="warn">warning</mat-icon> Not required. <small>If this is wrong, please go answer PHQ questions.</small>
            </p>

            <ng-container *ngIf="!sessionCancelled; else notRequired">
              <div *ngIf="phqFormGroup.errors !== null || phqFormGroup.dirty">
                <p *ngFor="let phqQuestion of phqQuestionsKeys; let i = index; trackByProperty: 'phqQuestionsKey'">
                  <mat-icon [ngClass]="{error: phqFormGroup.get(phqQuestion).hasError('required')}">
                    {{ phqFormGroup.get(phqQuestion).hasError('required') ? 'error' : 'check_circle' }}
                  </mat-icon>
                  PHQ9 Question {{i + 1}} <ng-container *ngIf="phqFormGroup.get(phqQuestion).hasError('required')">answer is required</ng-container>
                </p>
              </div>
            </ng-container>
          </div>

          <div>
            <p class="header">GAD7</p>
            <button *ngIf="!sessionCancelled && gadFormGroup.errors !== null && !phqGadRequired || !sessionCancelled && gadFormGroup.dirty && !phqGadRequired" (click)="markGadPhqAsNotRequired()" mat-flat-button class="warn">
              Mark PHQ9 / GAD7 as not required
            </button>
            <p *ngIf="!sessionCancelled && gadFormGroup.invalid && gadFormGroup.errors !== null || !sessionCancelled && gadFormGroup.invalid && gadFormGroup.dirty">
              <mat-icon class="error">error</mat-icon> Form contains errors:
            </p>
            <p *ngIf="gadFormGroup.valid && gadFormGroup.dirty"><mat-icon>check_circle</mat-icon> Ready to save</p>
            <p *ngIf="!sessionCancelled && gadFormGroup.errors === null && gadFormGroup.pristine">
              <mat-icon class="warn">warning</mat-icon> Not required. <small>If this is wrong, please go answer GAD questions.</small>
            </p>

            <ng-container *ngIf="!sessionCancelled; else notRequired">
              <div *ngIf="gadFormGroup.errors !== null || gadFormGroup.dirty">
                <p *ngFor="let gadQuestion of gadQuestionsKeys; let i = index; trackByProperty: 'gadQuestionsKey'">
                  <mat-icon [ngClass]="{error: gadFormGroup.get(gadQuestion).hasError('required')}">
                    {{ gadFormGroup.get(gadQuestion).hasError('required') ? 'error' : 'check_circle' }}
                  </mat-icon>
                  GAD7 Question {{i + 1}} <ng-container *ngIf="gadFormGroup.get(gadQuestion).hasError('required')">answer is required</ng-container>
                </p>
              </div>
            </ng-container>
          </div>

          <div>
            <p class="header">CGI-S/ CGI-I</p>
            <p *ngIf="!sessionCancelled">
              <mat-icon [ngClass]="{error: cgiFormGroup.invalid}">{{ cgiFormGroup.invalid ? 'error' : 'check_circle' }}</mat-icon> {{ cgiFormGroup.invalid ? 'Form contains errors:' : 'Ready to save' }}
            </p>
            <div *ngIf="!sessionCancelled; else notRequired">
              <p>
                <mat-icon [ngClass]="{error: cgiFormGroup.get('cgiSeverityResult').hasError('required')}">
                  {{ cgiFormGroup.get('cgiSeverityResult').hasError('required') ? 'error' : 'check_circle' }}
                </mat-icon>
                CGI Severity <ng-container *ngIf="cgiFormGroup.get('cgiSeverityResult').hasError('required')"> is required</ng-container>
              </p>
              <p>
                <mat-icon [ngClass]="{error: cgiFormGroup.get('cgiImprovementResult').hasError('required')}">
                  {{ cgiFormGroup.get('cgiImprovementResult').hasError('required') ? 'error' : 'check_circle' }}
                </mat-icon>
                CGI Improvement <ng-container *ngIf="cgiFormGroup.get('cgiImprovementResult').hasError('required')"> is required</ng-container>
              </p>
            </div>
          </div>

          <div>
            <p class="header">Attachments</p>
            <p *ngIf="!sessionCancelled; else notRequired">
              <mat-icon [ngClass]="{warn: attachmentsFormGroup.get('moments').value.length === 0}">
                {{ attachmentsFormGroup.get('moments').value.length === 0 ? 'warning' : 'check_circle' }}
              </mat-icon>
              {{ attachmentsFormGroup.get('moments').value.length === 0 ? 'There are no attachments selected' : attachmentsFormGroup.get('moments').value.length + ' attachments are selected' }}
            </p>
          </div>

          <div>
            <button mat-flat-button [disabled]="saveDisabled()" (click)="save()">Save</button>
          </div>

          <ng-template #notRequired>
            <p><mat-icon class="warn">warning</mat-icon> Not required due to session not taking place.</p>
          </ng-template>
        </div>

        <div *ngIf="savingCompleted" class="form-status saved" fxLayout="column" fxLayoutAlign="center center">
          <p class="header">Appointment moment has been created</p>
          <div fxFlex fxLayout="row" fxLayoutGap="6px">
            <button mat-flat-button (click)="returnToTimeline()">Return to patient timeline</button>
            <button *ngIf="patientChat.hasActiveChat" mat-flat-button (click)="returnToChatSession()">Return to chat session</button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
