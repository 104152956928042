import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthenticationService } from '@authentication/authentication.service';
import { MessageService } from '@shared/services/message.service';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { GoogleDriveService } from '@app/modules/shared/services/google-drive/google-drive.service';
import { GoChatCacheService } from '@app/modules/therapist/go-chat/go-chat-cache.service';
import { GoCoreChatService } from '@shared/services/gocore/chat/gocore-chat.service';

@Component({
    selector: 'app-logout-confirmation-dialog',
    templateUrl: './logout-confirmation-dialog.component.html',
    styleUrls: ['./logout-confirmation-dialog.component.scss'],
    standalone: false
})
export class LogoutConfirmationDialogComponent {

  @ViewChild('dialog') dialog: DialogComponent;
  public title = 'Are you sure that you want to log out?';

  constructor(private dialogRef: MatDialogRef<LogoutConfirmationDialogComponent>,
              private authenticationService: AuthenticationService,
              private googleDriveService: GoogleDriveService,
              private messageService: MessageService,
              private goCoreChatService: GoCoreChatService,
              private goChatCacheService: GoChatCacheService) { }

  public async logOutButtonClicked(): Promise<void> {
    try {
      await this.dialog.showAsyncProgressForFunction(async () => {
        this.title = 'Revoking Access Token...';
        this.googleDriveService.revokeAccessTokenConsent();
        this.title = 'Sending Pending Messages...';
        await this.goCoreChatService.waitForPendingMessagesToSend();
        this.title = 'Deleting Chat Cache...';
        await this.goChatCacheService.deleteChatCache();
        this.title = 'Logging Out...';
        await this.authenticationService.logOut();
        this.title = 'Successfully Logged Out!';
      });
      this.dialogRef.close();
    } catch (err) {
      console.error(err);
      this.dialogRef.close();
      this.messageService.showMessage('Error: Could not log out. Please try again.');
    }
  }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

}
