import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { SwaggerSecurityScopes } from '@backend-client/swagger-security-scopes';
import { OnDestroyObservable } from '@classes/on-destroy-observable';
import { AuthenticationService } from './authentication.service';

@Directive({
    selector: '[appUserHasScope]',
    standalone: false
})
export class UserHasScopeDirective extends OnDestroyObservable {

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authenticationService: AuthenticationService
  ) { super(); }

  @Input() set appUserHasScope(scope: SwaggerSecurityScopes) {
    this.authenticationService.isScopePresent$(scope).pipe(
      distinctUntilChanged(),
      takeUntil(this.ngOnDestroy$)
    ).subscribe(scopeIsPresent => {
      // This subscription logic isn't idempotent, so distinctUntilChanged prevents multiple executions for the same value
      if (scopeIsPresent) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    });
  }
}
