<mat-slide-toggle [formControl]="therapistOnlineStatus">
  <div id="therapistStatus" fxLayout="row">
    <div class="avatar">
      <mat-icon>account_box</mat-icon>
      <div class="online-status" [class.online]="therapistOnlineStatus.value"></div>
    </div>

    <div class="labels" fxLayout="column">
      <p>Your Status</p>
      <p class="status" [class.online]="therapistOnlineStatus.value">
        {{ therapistOnlineStatus.value ? 'Online' : 'Offline' }}
      </p>
    </div>
  </div>
</mat-slide-toggle>
