<app-main-toolbar></app-main-toolbar>

<div id="server-status">
  {{ serverStatusMessage }}
</div>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer
               class="sidenav animated-sidenav"
               fixedInViewport="false"
               autoFocus="false"
               [attr.role]="mediaObserver.isActive('lt-md') ? 'dialog' : 'navigation'"
               [mode]="mediaObserver.isActive('lt-md') ? 'over' : 'side'"
               (click)="mediaObserver.isActive('lt-md') && drawer.toggle()"
               [opened]="mediaObserver.isActive('lt-md') ? 'false' : 'true'"
               [class.open]="mainNavService.mainNavOpen$ | async"
               [@onMainNavChange]="mediaObserver.isActive('gt-xs') ? ((mainNavService.mainNavState$ | async) ? 'open' : 'close') : null">
    <app-main-sidenav></app-main-sidenav>
  </mat-sidenav>

  <mat-sidenav-content
    [@onMainContentChange]="(mainNavService.mainNavState$ | async) ? 'open' : 'close'"
    [ngClass]="{
      open: mediaObserver.isActive('gt-xs') ? (mainNavService.mainNavState$ | async) : null,
      closed: mediaObserver.isActive('gt-xs') ? !(mainNavService.mainNavState$ | async) : null,
    }">
    <app-main-tabs></app-main-tabs>
  </mat-sidenav-content>
</mat-sidenav-container>
