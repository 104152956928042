<nav mat-tab-nav-bar mat-stretch-tabs="false" mat-align-tabs="start" [tabPanel]="tabPanel">
  @for (tab of tabs | async; track tab; let index = $index) {
    <a mat-tab-link [routerLink]="tab.link" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive" [routerLinkActiveOptions]="{exact: true}">
      <div fxLayout="row" fxLayoutGap="24px">
        <span>{{ tab.displayName }}</span>
        <mat-icon *ngIf="(tabs | async).length > 1" inline (click)="$event.stopPropagation(); $event.preventDefault(); mainTabsService.closeTab(index);">
          cancel
        </mat-icon>
      </div>
    </a>
  }
</nav>
<mat-tab-nav-panel #tabPanel>
  <mat-progress-bar *ngIf="routerResolveStatus.IsLoading" mode="indeterminate" class="warn"></mat-progress-bar>

  <ng-container *ngIf="!routerResolveStatus.IsLoading">
    <router-outlet></router-outlet>
  </ng-container>
</mat-tab-nav-panel>
