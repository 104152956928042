import { DashboardSoftDeletedTubMoment } from '@backend-client/models/dashboard-soft-deleted-tub-moment';
import { Moment, AppointmentMomentLinkConnectionType, AppointmentMomentPosition, EditedMomentData } from '../moment';
import { MomentType } from '../moment-type';

export class GoogleDriveFileMoment extends Moment {
  readonly typeName = MomentType.GoogleFile;
  public googleDriveFileId: string;
  public googleDriveFolderId: string;
  public fileName: string;
  public contentType: string;
  public description: string;
  public edited: EditedMomentData;
  public appointmentRef: string = undefined;
  public appointmentMomentPosition: AppointmentMomentPosition = null;

  public get displayAppointmentConnectors(): AppointmentMomentLinkConnectionType {
    if (this.appointmentRef) {
      if (this.appointmentMomentPosition === AppointmentMomentPosition.start) {
        return AppointmentMomentLinkConnectionType.StartLink;
      }

      if (this.appointmentMomentPosition === AppointmentMomentPosition.last) {
        return AppointmentMomentLinkConnectionType.EndLink;
      }

      return AppointmentMomentLinkConnectionType.MiddleLink;
    }
  }

  constructor(options: {
    id: string,
    timestamp: Date,
    createdByUserId: number,
    author: string,
    googleDriveFileId: string,
    googleDriveFolderId: string,
    fileName: string,
    contentType: string,
    description: string,
    deleted?: DashboardSoftDeletedTubMoment,
    edited?: EditedMomentData,
    appointmentRef?: string,
    appointmentMomentPosition?: AppointmentMomentPosition,
  }) {
    super(options.id, options.timestamp, options.author, options.deleted);
    this.googleDriveFileId = options.googleDriveFileId;
    this.googleDriveFolderId = options.googleDriveFolderId;
    this.fileName = options.fileName;
    this.contentType = options.contentType;
    this.description = options?.description;
    this.edited = options.edited;
    this.appointmentRef = options.appointmentRef;
    this.appointmentMomentPosition = options.appointmentMomentPosition;
  }
}
