import { Component, Input } from '@angular/core';
import { MomentLinkConnectionType } from '../moment/moment';

@Component({
    selector: 'app-moment-connection-link',
    templateUrl: './moment-connection-link.component.html',
    styleUrls: ['./moment-connection-link.component.scss'],
    standalone: false
})
export class MomentConnectionLinkComponent {

  MomentLinkConnectionType = MomentLinkConnectionType;

  @Input() linkConnectionType: MomentLinkConnectionType;
  @Input() isInLinkGroup: boolean;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() colorHex = '#e59fb9';

}
