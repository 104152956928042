import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { SimpleNavLink } from '../main-nav/nav-link/nav-link';
import { Router } from '@angular/router';
import { allLinks, homeLink, paramLinks } from '../main-nav/router-links';


@Injectable({
  providedIn: 'root'
})
export class MainTabsService {
  public activeTabs$ = new BehaviorSubject<SimpleNavLink[]>([]);
  private readonly allUrls = allLinks;

  constructor(
    private router: Router
  ) {
  }

  public updateTabName(name: string): void {
    const url = window.location.pathname;
    const link = this.activeTabs$.value.filter(group => group.link === url)[0];

    if (link && link.displayName.indexOf('-') === -1) {
      link.displayName = link.displayName + ' - ' + name;
    }
  }

  public async addTab(url: string): Promise<void> {
    const home = url === '/' || url === '/home';

    if (home) {
      this.addTabIfNotInList(homeLink);
      return;
    }

    // ignore if callback
    if (url.includes('/callback')) {
      return;
    }

    const hasAcessToLink = this.allUrls.filter(group => group.link === url)[0];

    if (hasAcessToLink) {
      this.addTabIfNotInList(hasAcessToLink);
      return;
    } else {
      this.findPartialUrlMatch(url);
    }
  }

  public closeTab(index: number): void {
    const activeLinkIndex = this.activeTabs$.value.indexOf(this.activeTabs$.value.find(tab => tab.link === this.router.url));
    const remove = this.activeTabs$.value[index];
    this.activeTabs$.next(this.activeTabs$.value.filter(tab => tab !== remove));
    // Take next tab and navigate to it
    if (activeLinkIndex === index) {
      const previous = this.activeTabs$.value[index - 1];
      const next = this.activeTabs$.value[index + 1];

      if (previous) {
        this.router.navigate([previous.link]);
      } else if (next) {
        this.router.navigate([next.link]);
      } else {
        // Fallback to home page
        this.router.navigate([homeLink.link]);
      }
    }
  }

  public closeRecentTab(): void {
    this.closeTab(this.activeTabs$.value.length - 1);
  }

  private addTabIfNotInList(newTab: SimpleNavLink, path?: string): void {
    const newLink = {
      displayName: newTab.displayName,
      link: path ?? newTab.link
    }
    const exists = this.activeTabs$.value.some(tab => tab.link === newLink.link);

    if (!exists) {
      this.activeTabs$.value.push(newLink);
    }
  }

  private findPartialUrlMatch(url: string, originalUrl?: string, loop?: boolean): void {
    const lastDash = url.lastIndexOf('/');
    const slicedUrl = url.slice(0,lastDash);
    const endUrlParam = url.slice(lastDash, url.length);

    const includesLink = paramLinks.filter(group => group.link.includes(endUrlParam))[0];

    if (includesLink) {
      const path = originalUrl ?? url;
      this.addTabIfNotInList(includesLink, path);
      return;
    }

    // Match from bottom links first, these contains params
    const includesSlicedLink = paramLinks.filter(group => group.link.includes(slicedUrl + '/'))[0];

    if (includesSlicedLink) {
      const path = originalUrl ?? url;
      this.addTabIfNotInList(includesSlicedLink, path);
      return;
    } else if (!loop) {
      this.findPartialUrlMatch(slicedUrl, url, true);
    }
  }
}
