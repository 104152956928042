import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';

import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { MessageService } from '@app/modules/shared/services/message.service';
import { MomentType } from '../moment-type';
import { takeUntil } from 'rxjs';
import { OnDestroyObservable } from '@app/classes/on-destroy-observable';
import { TimelineService } from '../../timeline.service';
import { Moment } from '../moment';
import { StringEntryControlValidator } from '@shared/utils/string-entry-control-validator';

@Component({
    selector: 'app-soft-delete-moment-dialog',
    templateUrl: './soft-delete-moment-dialog.component.html',
    styleUrls: ['./soft-delete-moment-dialog.component.scss'],
    standalone: false
})
export class SoftDeleteMomentDialogComponent extends OnDestroyObservable implements OnInit {

  @ViewChild('dialog') dialog: DialogComponent;

  public momentType = MomentType;
  public deletionFormGroup = this.formBuilder.group({
    softDeleteReason: new FormControl('', [Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]),
    dataContainsAnotherUsersInfo: new FormControl(null, [Validators.required]),
    requestHardDelete: new FormControl(null),
  });
  public title = 'Are you sure that you want to delete this moment?';
  public infoText = `The following moment will be soft deleted, all data will remain visible in the patient's timeline.`;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      patientId: string,
      id: string,
      moment: Moment,
      timelineService: TimelineService
    },
    private dialogRef: MatDialogRef<SoftDeleteMomentDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.deletionFormGroup.get('dataContainsAnotherUsersInfo').valueChanges
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(value => {
        if (value) {
          this.deletionFormGroup.get('requestHardDelete').addValidators(Validators.required);
          this.deletionFormGroup.get('requestHardDelete').updateValueAndValidity();
        } else {
          this.deletionFormGroup.get('requestHardDelete').clearValidators();
          this.deletionFormGroup.get('requestHardDelete').updateValueAndValidity();
        }
      });
  }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

  public async deleteButtonClicked(): Promise<void> {
    await this.dialog.showAsyncProgressForFunction(async () => {
      try {
        if (this.deletionFormGroup.get('requestHardDelete').value) {
          //  Emails Andres & Adam in live to request moment to be deleted
          await this.data.timelineService.requestHardDeleteOfPatientMoment(this.data.patientId, this.data.id, this.deletionFormGroup.get('softDeleteReason').value);
        }
        await this.data.timelineService.softDeleteTherapistCreatedMoment(this.data.patientId, this.data.id, this.deletionFormGroup.get('softDeleteReason').value);
      } catch (err) {
        console.error(err);
        this.messageService.showMessage(`Error: Moment could not be soft deleted. Please refresh the page and try again.`);
      }
      this.dialogRef.close();
    });
  }

}
