import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

import { AuthenticationContainerHeader } from './models/authentication-container-header';
import { EnvironmentService } from '@shared/services/environment.service';
import { GoCoreService } from '@app/modules/shared/services/gocore/gocore.service';

@Component({
    selector: 'app-authentication-container',
    templateUrl: './authentication-container.component.html',
    styleUrls: ['./authentication-container.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AuthenticationContainerComponent implements OnInit {

  // 400ms coincides with "slide" animation duration of stepper
  private readonly ANIMATION_DURATION = 400;

  public goCoreWebVersion: string;

  @ViewChild('headingContainer', { static: true }) headingContainer;

  public currentHeader: AuthenticationContainerHeader = { header: 'Unknown', subHeader: 'Unknown' };
  public spinDirectionForward: boolean = null;
  public isLoading = false;
  public appVersion = this.environmentService.getVersion();
  public dashboardName = this.environmentService.getEnvironment().dashboardTitle ?? 'Thrive Dashboard';

  constructor(
    private environmentService: EnvironmentService,
    private gocoreService: GoCoreService
    ) { }

    ngOnInit() {
      this.goCoreWebVersion = this.gocoreService.getGoCoreWebVersion();
    }

  /**
   * Update the header text
   * @param header The new header text
   * @param delay Optional parameter that delays the changing of the header text.
   *              This is useful to allow the text change to coincide with the completion of the step animation.
   */
  public updateHeader(header: AuthenticationContainerHeader, delay?: number): void {
    this.headingContainer.nativeElement.style.opacity = 0;
    setTimeout(() => {
      this.currentHeader = header;
      this.headingContainer.nativeElement.style.opacity = 1;
    }, delay);
  }

  /**
   * On step change, animate the logo based on direction of step, and call function to update the header text
   * @param event Event data pertaining to step change
   * @param newHeader Header text of the new step
   */
  public stepChange(event: StepperSelectionEvent, newHeader: AuthenticationContainerHeader) {
    this.spinDirectionForward = event.selectedIndex > event.previouslySelectedIndex;
    this.updateHeader(newHeader, this.ANIMATION_DURATION);
    // Clear CSS bound bool, so when it is changed again, it triggers animation
    setTimeout(() => this.spinDirectionForward = null, this.ANIMATION_DURATION);
  }

}
