<app-moment icon="playlist_add_check" [title]="momentData.titleText" colour="#E3B23C" [momentData]="momentData">
  <div fxFlex fxLayout="column" fxLayoutGap="12px">
    <div *ngIf="momentData.deleted" class="deleted">
      <div fxLayout="column" fxLayouGap="6px">
        <h4>Deleted</h4>
        <p>{{ momentData.deleted.ts | date: 'fullDate' }}, {{ momentData.deleted.ts | date: 'shortTime' }} by {{ momentData.deleted.author }}</p>
        <p>Reason: {{ momentData.deleted.reason }}</p>
      </div>
    </div>

    <div *ngIf="momentData.edited" class="edited-info">
      <h4>Edited</h4>
      <p>{{ momentData.edited.ts | date: 'fullDate' }}, {{ momentData.edited.ts | date: 'shortTime' }} by {{ momentData.edited.author }}</p>
      <p>Reason: {{ momentData.edited.reason }}</p>
    </div>

    <ng-container *ngIf="momentData.GADQuestions.length > 0">
      <h1>GAD - {{ momentData.GADSeverity }} ({{ momentData.GADScore }})</h1>
      <p>{{ riskAssessmentIntroText }}</p>
      <ul>
        <li *ngFor="let question of momentData.GADQuestions; trackByProperty: 'momentDataGAD'">
          {{ question.question }} - <i>{{ question.answer }}</i>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="momentData.PHQQuestions.length > 0">
      <h1>PHQ - {{ momentData.PHQSeverity }} ({{ momentData.PHQScore }})</h1>
      <p>{{ riskAssessmentIntroText }}</p>
      <ul>
        <li *ngFor="let question of momentData.PHQQuestions; trackByProperty: 'momentDataPHQ'">
          {{ question.question }} - <i>{{ question.answer }}</i>
        </li>
      </ul>
    </ng-container>

    <div *ngIf="!momentData.deleted && momentData.authorTherapist && (featureFlags$ | async)?.isAppointmentMomentsEnabled"
         fxLayout="row" fxLayoutAlign="center">
      <button *ngIf="momentData.appointmentRef" mat-button (click)="momentService.editAppointmentMoment(momentData)">Edit</button>
      <button mat-button class="warn" (click)="momentService.softDeleteButtonClicked(momentData)">Delete</button>
    </div>
  </div>
</app-moment>
