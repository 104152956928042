<app-moment svgIcon="google-drive"
            [title]="momentData.fileName"
            [colour]="momentData.contentType === momentType.Call ? '#46cbf3' : '#fdb47f'"
            [momentData]="momentData">
  <div fxFlex fxLayout="column" fxLayoutGap="12px">
    <div *ngIf="momentData.deleted" class="deleted">
      <div fxLayout="column" fxLayouGap="6px">
        <h4>Deleted</h4>
        <p>{{ momentData.deleted.ts | date: 'fullDate' }}, {{ momentData.deleted.ts | date: 'shortTime' }} by {{ momentData.deleted.author }}</p>
        <p>Reason: {{ momentData.deleted.reason }}</p>
      </div>
    </div>

    <div *ngIf="momentData.edited" class="edited-info">
      <h4>Edited</h4>
      <p>{{ momentData.edited.ts | date: 'fullDate' }}, {{ momentData.edited.ts | date: 'shortTime' }} by {{ momentData.edited.author }}</p>
      <p>Reason: {{ momentData.edited.reason }}</p>
    </div>

    <div *ngIf="momentData?.description">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="6px">
        <div fxFlex class="sub-heading">Description</div>

        <div *ngIf="(featureFlags$ | async)?.isGoogleDriveEnabled">
          <button mat-icon-button aria-label="Edit description" matTooltip="Edit description" (click)="momentService.addEditDescriptionForGoogleFileMoment(momentData, momentData?.description)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="Fetch description history" matTooltip="Fetch description history" (click)="momentService.fetchAuditHistoryForGoogleFileMoment(momentData)">
            <mat-icon>history</mat-icon>
          </button>
        </div>
      </div>
      <div>
        {{ momentData.description }}
      </div>
    </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="center" fxLayoutGap="6px">
      <ng-container *ngIf="(featureFlags$ | async)?.isGoogleDriveEnabled">
        <button *ngIf="!momentData?.description" mat-button [disabled]="momentData.deleted" (click)="momentService.addEditDescriptionForGoogleFileMoment(momentData)">
          Add Description
        </button>
        <button mat-button (click)="momentService.previewGoogleDriveMoment(momentData)">Preview</button>
        <button *ngIf="(showDownloadButton | async)" mat-button (click)="momentService.downloadGoogleDriveMoment(momentData)">
          Download
        </button>
      </ng-container>

      <ng-container *ngIf="(featureFlags$ | async)?.isAppointmentMomentsEnabled && !momentData.deleted">
        <button *ngIf="momentData.appointmentRef" mat-button (click)="momentService.editAppointmentMoment(momentData)">
          Edit
        </button>
        <button mat-button class="warn" (click)="momentService.softDeleteButtonClicked(momentData)">
          Delete
        </button>
      </ng-container>
    </div>
  </div>
</app-moment>
