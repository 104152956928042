import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class StringEntryControlValidator {
  static REGEX_NO_CONSECUTIVE_WHITESPACES_ALLOWED = /^((?!\s{2,}).)*$/;

  public static isInvalidStringEntryValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.REGEX_NO_CONSECUTIVE_WHITESPACES_ALLOWED.test(control.value)) {
        return {
          'multipleWhitespacesError': { value: 'Value contains multiple whitespaces' }
        };
      }

      if (control.value.startsWith(' ')) {
        return {
          'trimErrorStart': { value: 'Value has leading whitespace' }
        };
      }

      if (control.value.endsWith(' ')) {
        return {
          'trimErrorEnd': { value: 'Value has trailing whitespace' }
        };
      }

      if (control.value.length < 3) {
        return {
          'lengthError': { value: 'Value must contain at least 3 characters' }
        };
      }

      return null;
    };
  }
}
