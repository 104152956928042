// go-chat-duplication
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';

import { GoActiveChatSessionsService } from '@app/modules/therapist/go-chat/go-active-chat-sessions.service';
import { GoClientChatSession } from '../go-message-notification-button/go-client-chat-session-model';
import { GoCoreChatService } from '@app/modules/shared/services/gocore/chat/gocore-chat.service';
import { GoAudioService, GoNotificationStateService } from '@app/modules/shared/services/go-notifications';
import { ChatChannelModel } from '@app/modules/shared/services/gocore/model';
import { GoNotificationState } from '@app/modules/shared/models/go-notification-state.model';

@Injectable({
  providedIn: 'root',
})
export class GoMessageNotificationButtonService {
  public chatSessions$: BehaviorSubject<GoClientChatSession[]> = new BehaviorSubject<GoClientChatSession[]>([]);
  // Because 0 is a valid value, initialise as -1. This allows the components to determine whether the unread messages have been fetched
  // or not.
  public totalChatNotifications$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  public totalPatientsWithUnreadMessages$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private activeChatSession$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public set activeChatSessionId(id: string) {
    this.activeChatSession$.next(id);
  }

  constructor(
    private activeChatSessionsService: GoActiveChatSessionsService,
    private audioService: GoAudioService,
    private notificationStateService: GoNotificationStateService,
    private goCoreChatService: GoCoreChatService,
  ) {
    this.goCoreChatService.activeChannels$.subscribe(async (activeChannels: ChatChannelModel[]) => {
      this.updateTotalMessageCount(activeChannels);
      const activeChatSessions: GoClientChatSession[] =
        (await this.activeChatSessionsService.getActiveChatSessions()) as GoClientChatSession[];
      if (activeChatSessions?.length > 0) {
        activeChatSessions.forEach(chatSession => {
          const sessionNotifications = activeChannels.filter(n => n.name.includes(chatSession.id))[0];

          if (sessionNotifications) {
            if (sessionNotifications.messageCount != null) {
              chatSession.unreadMessageCount = sessionNotifications.messageCount;
            }
          }
        });

        this.chatSessions$.next(activeChatSessions);

        if (localStorage.getItem('soundNotificationsEnabled') !== 'false') {
          this.notifyUserUnreadMessages(activeChannels);
        }
      }
    });
  }

  /**
   * Updates the total message count by summing all unread counts from active chat sessions
   */
  private updateTotalMessageCount(chatSessions: ChatChannelModel[]): void {
    const newMessageCount: number[] = chatSessions.map(chatSession => chatSession.messageCount || 0);
    // If the newMessageCount array is empty, set totalMessageCount to 0, otherwise sum all the values to get the total unread message count
      const totalUnreadMessageCount = !newMessageCount.length ? 0 : newMessageCount.reduce((a, b) => a + b);
      this.totalChatNotifications$.next(totalUnreadMessageCount);
      const patientsWithUnreadMessages = newMessageCount.filter(c => c > 0).length;
      this.totalPatientsWithUnreadMessages$.next(patientsWithUnreadMessages);
  }

  private notifyUserUnreadMessages(notifications: ChatChannelModel[]): void {
    this.notificationStateService.notifiedUserMessages
      .pipe(take(1), distinctUntilChanged())
      .subscribe(alreadyNotified => {
        let messageArray = this.unreadMessages(notifications, alreadyNotified);
        const unreadMessages = messageArray.filter(chats => chats.unread > 0).length > 0;
        const activeChatSession = this.activeChatSession$.value;

        // On load notify the user once of outstanding notifications
        if (alreadyNotified.length === 0 && unreadMessages) {
          this.audioService.playAudioMessage();
          messageArray = this.notificationStateService.setAllUserNotified(messageArray);
        } else {
          const newMessages = messageArray.filter(message => (message.unread > 0 && message.userNotified === false && !alreadyNotified.find(n => n.id === message.id)?.userNotified));
          const filteredNewMessages = newMessages.filter(message => (message.id === activeChatSession && !document.hasFocus()) || message.id !== activeChatSession);

          for (const message of filteredNewMessages) {
              this.audioService.playAudioMessage();
              messageArray = this.notificationStateService.setUserNotified(message.id, messageArray);
          }
        }

        this.notificationStateService.notifiedUserMessages.next(messageArray);
      });
  }

  private unreadMessages(
    notifications: ChatChannelModel[],
    alreadyNotified: GoNotificationState[],
  ): Array<GoNotificationState> {
    const addToNotified: Array<GoNotificationState> = [];

    if (notifications !== null) {
      notifications.forEach(notification => {
        const unread = {
          id: notification.name,
          unread: notification.messageCount,
          userNotified: alreadyNotified.find(c => c.id === notification.name)?.userNotified || false,
        } as GoNotificationState;

        addToNotified.push(unread);
      });
    }

    return addToNotified;
  }
}
