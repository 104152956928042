<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
  <div id="title-container" fxLayout="row" fxLayoutGap="20">
    <img *ngIf="mediaObserver.isActive('xs') || !(mainNavService.mainNavOpen$ | async)" fxShow id="thrive-logo-icon" src="assets/images/thrive-icon-logo-small.webp" width="43" height="43" class="only-on-light" alt="Thrive Mental Wellbeing" />
    <img *ngIf="mediaObserver.isActive('xs') || !(mainNavService.mainNavOpen$ | async)" fxShow id="thrive-logo-icon" src="assets/images/thrive-icon-logo-small-white.webp" width="43" height="43" class="only-on-dark" alt="Thrive Mental Wellbeing" />
    <img *ngIf="mediaObserver.isActive('gt-sm') && (mainNavService.mainNavOpen$ | async) || mediaObserver.isActive('sm') && (mainNavService.mainNavOpen$ | async)" fxShow id="thrive-logo-full" class="only-on-light" src="assets/images/thrive-mental-wellbeing-logo-small.webp" width="116" height="43" alt="Thrive Mental Wellbeing" />
    <img *ngIf="mediaObserver.isActive('gt-sm') && (mainNavService.mainNavOpen$ | async) || mediaObserver.isActive('sm') && (mainNavService.mainNavOpen$ | async)" fxShow id="thrive-logo-full" class="only-on-dark" src="assets/images/thrive-mental-wellbeing-logo-small-white.webp" width="116" height="43" alt="Thrive Mental Wellbeing" />

    <mat-divider [vertical]="true" class="notification-divider"></mat-divider>

    <div id="region-flag" fxLayout="row" fxLayoutGap="10">
      <img width="86" height="43" src="assets/images/flags/{{ isAustralia ? 'au' : 'gb' }}.webp" alt="{{ isAustralia ? 'AUS' : 'UK' }}"/>

      <div class="app-name">
        <p>{{ title }}</p>
        <p class="region-name">{{ isAustralia ? 'Australia' : 'UK' }}</p>
      </div>
    </div>

    <button *ngIf="mediaObserver.isActive('lt-md')" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>

    <ng-container *ngIf="(featureFlagService.featureFlags$ | async)?.isThrive24Enabled">
      <mat-divider [vertical]="true" class="notification-divider"></mat-divider>

      <app-office-hours *appUserHasScope="'region-settings:view'"></app-office-hours>
    </ng-container>

    <ng-container *ngIf="(featureFlagService.featureFlags$ | async)?.isTherapistOnlineStatusEnabled">
      <mat-divider [vertical]="true" class="notification-divider"></mat-divider>

      <app-therapist-status *appUserHasScope="'chat:view-active-chats'"></app-therapist-status>
    </ng-container>
  </div>

  <div *appUserHasScope="'chat:view-active-chats'" fxLayout="row">
    <div class="version-text" fxFlexAlign="center">V1</div>
    <app-failed-messages-button></app-failed-messages-button>
    <app-message-notification-button></app-message-notification-button>
    <app-primary-notification-button></app-primary-notification-button>

    <mat-divider [vertical]="true" class="notification-divider"></mat-divider>

    <!-- go-chat-duplication start -->
    <div class="version-text" fxFlexAlign="center">V2</div>
    <app-go-message-notification-button></app-go-message-notification-button>
    <app-go-primary-notification-button></app-go-primary-notification-button>
    <!-- go-chat-duplication end -->
  </div>
</mat-toolbar>
