import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { FlexLayoutModule, MediaObserver } from '@ngbracket/ngx-layout';

import { OfficeHoursService } from '@shared/services/office-hours/office-hours.service';

@Component({
    selector: 'app-office-hours',
    templateUrl: './office-hours.component.html',
    styleUrls: ['./office-hours.component.scss'],
    standalone: true,
    imports: [CommonModule, FlexLayoutModule],
    providers: [OfficeHoursService]
})
export class OfficeHoursComponent implements OnInit {
  constructor(
    public mediaObserver: MediaObserver,
    public officeHoursService: OfficeHoursService,
  ) {}

  ngOnInit() {
    this.officeHoursService.initialiseSchedule();
  }
}
