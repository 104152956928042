import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { SetupPasswordComponent } from './authentication-container/steps/setup-password/setup-password.component';
import { LoginStepperComponent } from './login-stepper/login-stepper.component';
import { LoginEmailPasswordComponent } from './authentication-container/steps/login-email-password/login-email-password.component';
import { EnterAuthenticatorComponent } from './authentication-container/steps/enter-authenticator/enter-authenticator.component';
import { USBAuthenticatorComponent } from './authentication-container/steps/usbauthenticator/usbauthenticator.component';
import { HasGotAuthenticatorComponent } from './authentication-container/steps/has-got-authenticator/has-got-authenticator.component';
import { InstallAuthenticatorComponent } from './authentication-container/steps/install-authenticator/install-authenticator.component';
import { QrCodeComponent } from './authentication-container/steps/qr-code/qr-code.component';
import { SuccessComponent } from './authentication-container/steps/success/success.component';
import { ResetPasswordComponent } from './authentication-container/steps/reset-password/reset-password.component';
import { QRCodeComponent } from 'angularx-qrcode';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginStepperModalComponent } from './login-stepper/login-stepper-modal.component';
import { FirebaseActionsStepperComponent } from './firebase-actions-stepper/firebase-actions-stepper.component';
import { FirebaseResetPasswordComponent } from './authentication-container/steps/firebase-reset-password/firebase-reset-password.component';
import { FirebaseRecoverEmailComponent } from './authentication-container/steps/firebase-recover-email/firebase-recover-email.component';
import { FirebaseVerifyEmailComponent } from './authentication-container/steps/firebase-verify-email/firebase-verify-email.component';
import { AuthenticationContainerComponent } from './authentication-container/authentication-container.component';
import { LogOutComponent } from './log-out/log-out.component';
import { AddAuthenticatorPromptComponent } from './authentication-container/steps/add-authenticator-prompt/add-authenticator-prompt.component';
import { TotpStepperModalComponent } from './totp-stepper/totp-stepper-modal.component';

@NgModule({
  declarations: [
    LoginStepperComponent,
    EnterAuthenticatorComponent,
    HasGotAuthenticatorComponent,
    InstallAuthenticatorComponent,
    LoginEmailPasswordComponent,
    QrCodeComponent,
    ResetPasswordComponent,
    SetupPasswordComponent,
    SuccessComponent,
    USBAuthenticatorComponent,
    LoginStepperModalComponent,
    FirebaseActionsStepperComponent,
    FirebaseResetPasswordComponent,
    FirebaseRecoverEmailComponent,
    FirebaseVerifyEmailComponent,
    AuthenticationContainerComponent,
    AddAuthenticatorPromptComponent,
    TotpStepperModalComponent,
    LogOutComponent
  ],
  exports: [
    LoginStepperComponent,
    TotpStepperModalComponent,
  ],
  imports: [
    AuthenticationRoutingModule,
    CommonModule,
    QRCodeComponent,
    SharedModule
  ]
})
export class AuthenticationModule {}
