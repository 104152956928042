<app-moment icon="speaker_notes{{ momentData.chatType === ChatType.EndChat ?  '_off' : '' }}"
            title="{{ momentData.chatType === ChatType.EndChat ? 'Chat Ended' : 'Chat Started' }}"
            colour="#e59fb9"
            [momentData]="momentData">
  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="6px" *ngIf="momentData.chatType === ChatType.StartChat  && isActiveChat">
    <!-- go-chat-duplication start -->
    <button *ngIf="momentData.pubNub else defaultChatButton" mat-flat-button (click)="momentService.goToActiveGoChatClicked(momentData.chatSessionId)">
      Go to Chat V2
    </button>
    <!-- go-chat-duplication end -->

    <ng-template #defaultChatButton>
      <button mat-flat-button (click)="momentService.goToActiveChatClicked(momentData.chatSessionId)">
        Go to Chat
      </button>
    </ng-template>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" *ngIf="momentData.chatType === ChatType.EndChat">
    <button mat-flat-button (click)="momentService.viewChatLogClicked(momentData)">View Chat Log</button>
  </div>
</app-moment>
