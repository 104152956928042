import { Component } from '@angular/core';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { CgiScoreMoment } from './cgi-score-moment';
import { MomentService } from '../moment.service';

@Component({
    selector: 'app-cgi-score-moment',
    templateUrl: './cgi-score-moment.component.html',
    styleUrls: ['./cgi-score-moment.component.scss'],
    standalone: false
})
export class CgiScoreMomentComponent {

  private patientId: string;
  public momentData: CgiScoreMoment;
  public featureFlags$ = this.featureFlagService.featureFlags$;

  constructor(
    public momentService: MomentService,
    private featureFlagService: FeatureFlagService
  ) {}
}
