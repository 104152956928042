import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-authentication-modal',
    templateUrl: './login-stepper-modal.component.html',
    styleUrls: ['./login-stepper-modal.component.scss'],
    standalone: false
})
export class LoginStepperModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { user: any },
              private dialogRef: MatDialogRef<LoginStepperModalComponent>) { }

  public onSuccessfulLogin() {
    this.dialogRef.close();
  }

}
