<form [formGroup]="forgotPasswordFormGroup">
  <mat-form-field appearance="outline" class="full-width-input">
    <mat-label>Email</mat-label>
    <input #email type="email" matInput formControlName="forgotPasswordFormControl" [errorStateMatcher]="errorMatcher">
    <mat-error *ngIf="forgotPasswordFormGroup.controls['forgotPasswordFormControl'].hasError('email') &&
                !forgotPasswordFormGroup.controls['forgotPasswordFormControl'].hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="forgotPasswordFormGroup.controls['forgotPasswordFormControl'].hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <br>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="back()">
      Back
    </button>
    <button type="submit" mat-flat-button (click)="forgotPassword.emit(email.value)"
            [disabled]="!forgotPasswordFormGroup.controls['forgotPasswordFormControl'].valid">
      Email Reminder
    </button>
  </div>
</form>
