import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-install-authenticator',
    templateUrl: './install-authenticator.component.html',
    styleUrls: ['./install-authenticator.component.scss'],
    standalone: false
})
export class InstallAuthenticatorComponent implements OnInit {

  @Output() installedAuthenticator: EventEmitter<null> = new EventEmitter();

  public osType: string;
  public osTypeFormControl: UntypedFormControl;

  public authenticatorLinks = [
    {
      os: 'Android',
      link: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
    },
    {
      os: 'Apple',
      link: 'https://itunes.apple.com/gb/app/google-authenticator/id388497605'
    }
  ];

  ngOnInit() {
    this.osTypeFormControl = new UntypedFormControl('', [ Validators.required ]);
  }

  public openAuthenticatorLink(): void {
    const storeLink = this.authenticatorLinks.find(obj => obj.os === this.osType).link;
    if (storeLink) {
      window.open(storeLink, '_blank');
      this.installedAuthenticator.emit();
    } else {
      console.error(`Unknown store link: ${this.osType}`);
    }
  }

  public backButtonClicked(): void {
    this.osTypeFormControl.setErrors(null);
  }

}
