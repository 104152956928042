import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog,
    public overlay: Overlay
  ) { }

  public openDialog(
    component,
    data: any = {},
    settings: {
      disableClose?: boolean,
      panelClass?: string,
      hasBackdrop?: boolean,
    } = {}
  ): MatDialogRef<any> {
    return this.dialog.open(component, {
       panelClass: settings?.panelClass || '',
       autoFocus: true,
       disableClose: settings.disableClose !== undefined ? settings.disableClose : true,
       hasBackdrop: settings.hasBackdrop !== undefined ? settings.hasBackdrop : true,
       width: 'auto',
       minWidth: '200px',
       maxWidth: '90vw',
       height: 'auto',
       minHeight: '100px',
       maxHeight: '94vh',
       data,
       scrollStrategy: this.overlay.scrollStrategies.noop(),
     });
  }

  public closeAllDialogs(): void {
    this.dialog.closeAll();
  }
}
