import { Component } from '@angular/core';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { RiskAssessmentEventMoment, RiskAssessmentIntroText } from './risk-assessment-event-moment';
import { MomentService } from '../moment.service';

@Component({
    selector: 'app-risk-assessment-event-moment',
    templateUrl: './risk-assessment-event-moment.component.html',
    styleUrls: ['./risk-assessment-event-moment.component.scss'],
    standalone: false
})
export class RiskAssessmentEventMomentComponent {

  public riskAssessmentIntroText = RiskAssessmentIntroText;
  public momentData: RiskAssessmentEventMoment;
  public featureFlags$ = this.featureFlagService.featureFlags$;

  constructor(
    public momentService: MomentService,
    private featureFlagService: FeatureFlagService
  ) {
  }
}
