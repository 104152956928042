import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumToArray',
    standalone: false
})
export class EnumToArrayPipe implements PipeTransform {

  transform(data: object): string[] {
    return Object.values(data);
  }
}
