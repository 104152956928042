<div *ngIf="loading" class="loading-app-container">
  <div class="loading-app">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<router-outlet></router-outlet>
