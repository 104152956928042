import { Component } from '@angular/core';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { FileMoment } from './file-moment';
import { MomentService } from '../moment.service';

@Component({
    selector: 'app-file-moment',
    templateUrl: './file-moment.component.html',
    styleUrls: ['./file-moment.component.scss'],
    standalone: false
})
export class FileMomentComponent {

  public momentData: FileMoment;
  public featureFlags$ = this.featureFlagService.featureFlags$;

  constructor(
    public momentService: MomentService,
    private featureFlagService: FeatureFlagService,
  ) { }
}
