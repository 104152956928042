import { Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs';

import { SwaggerSecurityScopes } from '@backend-client/swagger-security-scopes';
import { AuthenticationService } from './authentication.service';

@Pipe({
    name: 'userHasScope',
    standalone: false
})
export class UserHasScopePipe implements PipeTransform {

  constructor(private authenticationService: AuthenticationService) {}

  transform(scope: SwaggerSecurityScopes): Observable<boolean> {
    return this.authenticationService.isScopePresent$(scope);
  }
}
