<a
  [routerLink]="notification.link"
  mat-menu-item
  fxLayout="column"
  fxLayoutAlign="center"
  (click)="notificationClicked()"
  [ngClass]="{ 'read-notification': notification.readAtTime !== null }">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-icon inline>{{ notification.icon }}</mat-icon>
      <div>{{ notification.title }}</div>
    </div>
    <div id="notification-message">
      {{ notification.message }}
    </div>
  </div>
  <div id="timestamp" fxFlexAlign="end">
    {{ notification.timestamp.toDate().toLocaleDateString('en-GB') }}
    {{ notification.timestamp.toDate() | convertDateToTime: true }}
  </div>
</a>
