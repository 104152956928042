import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertUnixTimeToLocaleString',
    standalone: false
})
export class ConvertUnixTimeToLocaleStringPipe implements PipeTransform {

  transform(value: number, showDateOnly?: boolean): string {
    if (showDateOnly) {
      return new Date(value).toLocaleDateString( 'en-GB' );
    } else {
      return new Date(value).toLocaleString();
    }
  }

}
