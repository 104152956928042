<app-moment icon="event_available" title="Appointment" colour="#3cc4e3" [momentData]="momentData">
  <div *ngIf="momentData.deleted" class="deleted">
    <div fxLayout="column" fxLayouGap="6px">
      <h4>Deleted</h4>
      <p>{{ momentData.deleted.ts | date: 'fullDate' }}, {{ momentData.deleted.ts | date: 'shortTime' }} by {{ momentData.deleted.author }}</p>
      <p>Reason: {{ momentData.deleted.reason }}</p>
    </div>
  </div>

  <div *ngIf="momentData.edited" class="edited-info">
    <h4>Edited</h4>
    <p>{{ momentData.edited.ts | date: 'fullDate' }}, {{ momentData.edited.ts | date: 'shortTime' }} by {{ momentData.edited.author }}</p>
    <p>Reason: {{ momentData.edited.reason }}</p>
  </div>

  <div>Date: {{ momentData.appointmentDate | date: 'fullDate' }}</div>
  <div>Time: {{ momentData.appointmentTime | date: 'shortTime' }}</div>
  <p>Number of Sessions: {{ momentData.therapySessionNumber }}</p>
  <p>Outcome: {{ momentData.appointmentOutcome }} <span *ngIf="momentData.appointmentOutcomeOther"> - {{ momentData.appointmentOutcomeOther }}</span> </p>
  <p>How difficult patient is finding daily activites: {{ momentData.howPatientIsFunctioning }}</p>

  <div *ngIf="(featureFlags$ | async)?.isAppointmentMomentsEnabled && !momentData.deleted" fxLayout="row" fxLayoutAlign="center">
    <button mat-button (click)="momentService.editAppointmentMoment(momentData)">
      Edit
    </button>
    <button mat-button class="warn" (click)="momentService.softDeleteButtonClicked(momentData)">Delete</button>
  </div>
</app-moment>
