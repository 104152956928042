import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DialogComponent } from '@shared/components/dialog/dialog.component';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: [ './cancel-dialog.component.scss' ],
  standalone: false
})
export class CancelDialogComponent {
  @ViewChild('dialog') dialog: DialogComponent;

  constructor(private readonly dialogRef: MatDialogRef<CancelDialogComponent>) { }

  public returnClicked(): void {
    this.dialogRef.close({ routeAway: true });
  }

  public cancelClicked(): void {
    this.dialogRef.close();
  }
}
