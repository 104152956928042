import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

import { BehaviorSubject } from 'rxjs';

import { HelperService } from '@shared/services/helper.service';
import { MessageService } from '@shared/services/message.service';
import { LoginStep } from './login-step.enum';
import { LoginStepperService } from './login-stepper.service';
import { AuthenticationService } from '../authentication.service';
import { ApiHttpErrorResponse } from '@backend-client/api-http-error-response';
import { MfaMethod } from '../mfa-method.enum';
import { MfaOption } from '@backend-client/models/mfa-option';
import { FirebaseAuthErrors } from './firebase-auth-errors';

@Component({
    selector: 'app-login-stepper',
    templateUrl: './login-stepper.component.html',
    styleUrls: ['./login-stepper.component.scss'],
    standalone: false
})
export class LoginStepperComponent implements OnInit {

  @Input() isCalledFromDialog?: boolean;
  @Input() loggedInEmail?: string;
  @Input() startStep?: LoginStep;
  @Output() complete = new EventEmitter<void>();

  @ViewChild('prompt', { static: true }) prompt;
  @ViewChild('stepper', { static: true }) stepper;
  @ViewChild('qrCode') qrCode;
  @ViewChild('loginForm') loginForm;
  @ViewChild('enterAuthenticationCodeComponent') enterAuthenticationCodeComponent;

  public error: string;
  public email: string;
  public page = LoginStep;
  public continueUrl: string;
  public cachedIdToken: string = null;
  public mfaMethods: BehaviorSubject<MfaOption[]> = new BehaviorSubject<MfaOption[]>([]);

  private promptHeaders: { header: string, subHeader: string }[];

  constructor(private messageService: MessageService,
              private router: Router,
              private helperService: HelperService,
              private loginStepperService: LoginStepperService,
              private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.promptHeaders = this.loginStepperService.getPromptHeaders();

    // If called from dialog, then the user was trying to access a specific page, so return the full url
    // Otherwise, the user logged in from the log in page, so return the base url
    this.continueUrl = this.isCalledFromDialog ? window.location.href : window.location.origin;

    this.initialiseStepper();
  }

  /**
   * Sets the initial step of the stepper on initialisation based on:
   * 1. Whether an email has been provided
   * 2. Whether a "start step" has been provided
   * Otherwise, initialise the stepper at the first step - Sign In
   */
  private initialiseStepper(): void {

    this.email = this.helperService.getEmailFromQueryString();

    if (this.email) {
      this.setStep(LoginStep.choosePassword);
    } else if (this.startStep) {
      this.setStep(this.startStep);
    } else {
      this.setStep(LoginStep.signIn);
    }
  }

  /**
   * Go to particular login step
   * @param step The step to change to
   */
  private setStep(step: LoginStep): void {
    this.stepper.selectedIndex = step;
    this.prompt.updateHeader(this.promptHeaders[step]);
  }

  /**
   * Attempt to login, then either redirect to relevant page or close the login dialog
   * @param credentials The email and password used to attempt to login
   * @param mfaChallengeResponseToken Token provided from MFA challenge
   */
  public async login(credentials?: { email: string; password: string; }, mfaChallengeResponseToken?: string): Promise<void> {
    this.prompt.isLoading = true;
    this.error = null;
    let idToken: string = null;
    try {
      if (credentials != null) {
        idToken = await this.authenticationService.attemptLoginWithEmailAndPassword(credentials.email, credentials.password);
      } else if (this.cachedIdToken != null) {
        idToken = this.cachedIdToken;
      } else {
        throw new Error('Credentials not supplied to login and no idToken is available to use.');
      }
      await this.authenticationService.attemptLogin(idToken, mfaChallengeResponseToken);

      if (this.isCalledFromDialog) {
        // Emit will cause the dialog to close
        this.complete.emit();
      } else {
        // Logged in from login page, so route to root of app where the router will handle the routing
        this.router.navigate([ '/' ]);
      }
    } catch (err) {
      if (err instanceof ApiHttpErrorResponse && err.tubErrorToken === 'MfaRequiredError') {
        this.cachedIdToken = idToken;
        const currentMfaMethods: MfaOption[] = await this.authenticationService.getMfaOptions(idToken);
        this.mfaMethods.next(currentMfaMethods);

        // If there is only the email method, then email the otp
        if (currentMfaMethods.length === 1 && currentMfaMethods[0].type === 'email') {
          const configurationId = currentMfaMethods.find(mfaOption => mfaOption.type === 'email').id;
          this.enterAuthenticationCodeComponent.emailSendStatus = await this.authenticationService.emailTotp(idToken, configurationId);
        }

        this.stepper.selectedIndex = this.page.oneTimePassword;
      } else {
        switch (err.code) {
          case FirebaseAuthErrors.InvalidEmail:
            this.error = 'Invalid email address';
            break;
          case FirebaseAuthErrors.UserNotFound:
            this.error = 'Incorrect email address';
            break;
          case FirebaseAuthErrors.WrongPassword:
            this.error = 'Incorrect password';
            break;
          case FirebaseAuthErrors.UserDisabled:
            this.error = 'User account disabled. Please contact Thrive if you believe you should have access.';
            break;
          case FirebaseAuthErrors.TooManyRequests:
            this.error = 'Account temporarily disabled. Please reset your password or try again later.';
            break;
          case FirebaseAuthErrors.CustomTokenMismatch || FirebaseAuthErrors.InvalidCustomToken:
            this.error = 'Invalid token';
            break;

          default:
            this.messageService.showMessage(err.message);
            break;
        }
      }
    }
    this.prompt.isLoading = false;
  }

  /**
   * Determines the new text of the header and invokes the step animation using the new data
   * @param stepData Information pertaining to the current step of the login stepper
   */
  public stepChange(stepData: StepperSelectionEvent): void {
    const newHeader = this.promptHeaders[stepData.selectedIndex];
    this.prompt.stepChange(stepData, newHeader);
  }

  /**
   * Adds an mfa method to the current user's account
   * @param data Contains the token and secret needed to add the authenticator
   */
  public async addMfa(data: { token: string, secret: string }): Promise<void> {
    this.prompt.isLoading = true;
    try {
      await this.authenticationService.addMfa(data);
      this.prompt.isLoading = false;
      this.complete.emit();
    } catch (err) {
      this.prompt.isLoading = false;

      if (err.status === 400) {
        this.messageService.showMessage('Error: Invalid QR code, please try again');
      } else if (err.status === 429) {
        this.messageService.showMessage('Error: Maxiumum number of MFA methods has been reached');
      } else {
        this.messageService.showMessage('Error: Authentication could not be added, please try again');
      }
      console.error(err);
    }
  }

  /**
   * If an account that has been flagged as needing MFA does not have an associated MFA method, move to MFA step so it can be added
   * @param shouldAddAuthenticator Dictates whether authenticator should to be added
   */
  public mfaCheck(shouldAddAuthenticator: boolean): void {
    this.stepper.selectedIndex = shouldAddAuthenticator ? LoginStep.twoFactorAuthentication : LoginStep.success;
  }

  /**
   * Attempts to create a new user using the current email address
   * @param password Password of the account to be created
   */
  public async createUser(password: string): Promise<void> {
    this.prompt.isLoading = true;
    try {
      await this.authenticationService.createUser(this.email, password);
      this.stepper.selectedIndex = LoginStep.addAuthenticationPrompt;
    } catch (err) {
      this.messageService.showMessage(err);
    }
    this.prompt.isLoading = false;
  }

  /**
   * Attempts to authenticate an authentication code and displays a custom error if it fails
   * @param config Contains the 6 digit authentication code and the MFA method in which to validate it
   */
  public async authenticateMfa(config: { authCode: string, mfaMethod: MfaMethod }): Promise<void> {
    this.prompt.isLoading = true;

    if (this.cachedIdToken == null) {
      throw new Error('Assert: Cached IdToken must not be null when authenticating totp mfa.');
    }

    try {
      const challengeResponseToken
        = await this.authenticationService.authenticateMfa(this.cachedIdToken, config.authCode, config.mfaMethod);
      await this.login(null, challengeResponseToken);
    } catch (err) {
      // If the user is emailed a password, but then tries to enter a password from the authenticator, then it will fail.
      // This custom error should help nudge the user to use the correct method.
      if (err.status === 400) {

        let mfaMethod: string;

        switch (config.mfaMethod) {
          case MfaMethod.email: {
            mfaMethod = 'emailed';
            break;
          }
          case MfaMethod.totp: {
            mfaMethod = 'authenticator';
            break;
          }
          default: { mfaMethod = 'one time'; }
        }

        this.messageService.showMessage(`Incorrect ${mfaMethod} password, please try again`);
      } else {
        this.messageService.showMessage(err.message);
      }
    }
    this.prompt.isLoading = false;
  }

  /**
   * If there is an authenticator on the account, skip step prompting to add authenticator
   * @param hasGotAuthenticator Whether the user has an authenticator on their account
   */
  public checkForAuthenticator(hasGotAuthenticator: boolean): void {
    this.stepper.selectedIndex = hasGotAuthenticator ? LoginStep.scanQrCode : LoginStep.installAuthenticator;
  }

  /**
   * Sends a "reset password" email to the user and then resets the stepper
   * @param email The email address of the account for the password to be reset
   */
  public async resetPassword(email): Promise<void> {
    this.prompt.isLoading = true;
    try {
      await this.authenticationService.resetPassword(email, this.continueUrl);
    } catch (err) {
      // TODO: This error should not be sent back, as an attacker could determine whether an email
      //  address is valid or not, depending on whether the error is returned.
    }
    this.messageService.showMessage(`Password reminder has been sent to ${email} if the user exists`);
    this.loginForm.resetForm();
    this.resetStepper();
    this.prompt.isLoading = false;
  }

  /**
   * Resets stepper to the initial step of the sign in process
   */
  public resetStepper(): void {
    this.stepper.selectedIndex = LoginStep.signIn;
  }
}
