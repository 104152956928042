<form [formGroup]="passwordFormGroup">
  <mat-form-field appearance="outline" class="full-width-input">
    <mat-label>Password</mat-label>
    <input #password matInput [type]="hide() ? 'password' : 'text'" placeholder="Password" formControlName="passwordFormControl" autofocus>
    <button mat-icon-button matSuffix type="button" (click)="viewInput($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide()">
      <mat-icon>{{ hide() ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-hint align="end" aria-live="polite">{{ password.value.length }} / 12</mat-hint>
    <mat-error *ngIf="passwordFormGroup.controls['passwordFormControl'].hasError('minlength')">
      Password is only {{ password.value.length }} / 12 characters long
    </mat-error>
    <mat-error *ngIf="passwordFormGroup.controls['passwordFormControl'].hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <app-password-strength [password]="password.value" min="12"></app-password-strength>
  <br />
  <mat-form-field appearance="outline" class="full-width-input">
    <mat-label>Confirm Password</mat-label>
    <input matInput type="password" placeholder="Confirm Password" formControlName="passwordConfirmFormControl" [type]="hideConfirm() ? 'password' : 'text'" >
    <button mat-icon-button matSuffix type="button" (click)="viewConfirmInput($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirm()">
      <mat-icon>{{ hideConfirm() ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="passwordFormGroup.controls['passwordConfirmFormControl'].hasError('notEquivalent')">
      Passwords do not match
    </mat-error>
    <mat-error *ngIf="passwordFormGroup.controls['passwordConfirmFormControl'].hasError('minlength')">
      Your password should be at least 12 characters long
    </mat-error>
  </mat-form-field>
  <br />
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div></div>
    <button type="submit" mat-flat-button [disabled]="!passwordFormGroup.valid" (click)="submitPassword()">
      Next
    </button>
  </div>
</form>
