import { DashboardSoftDeletedTubMoment } from '@backend-client/models/dashboard-soft-deleted-tub-moment';
import { MomentType } from './moment-type';
import { NoteMoment } from './note-moment/note-moment';
import { CgiScoreMoment } from './cgi-score/cgi-score-moment';
import { RiskAssessmentEventMoment } from './risk-assessment-event-moment/risk-assessment-event-moment';
import { FileMoment } from './file-moment/file-moment';
import { CallMoment } from './call-moment/call-moment';
import { GoogleDriveFileMoment } from './google-drive-file-moment/google-drive-file-moment';
import { AppointmentMoment } from './appointment-moment/appointment-moment';

export enum AppointmentMomentLinkConnectionType {
  None,
  MiddleLink,
  StartLink,
  EndLink
}

export enum AppointmentMomentPosition {
  start,
  middle,
  last
}

export enum MomentLinkConnectionType {
  None,
  StartLink,
  EndLink
}

export interface EditedMomentData {
  author: string;
  ts: number;
  reason: string;
}

export declare type PatientMoments = NoteMoment | CgiScoreMoment | RiskAssessmentEventMoment | FileMoment | CallMoment | GoogleDriveFileMoment | AppointmentMoment;

export abstract class Moment {
  public abstract readonly typeName: MomentType;

  public get displayLinkConnection(): MomentLinkConnectionType { return MomentLinkConnectionType.None; }

  public get displayAppointmentConnectors(): AppointmentMomentLinkConnectionType { return AppointmentMomentLinkConnectionType.None; }

  protected constructor(public id: string, public timestamp: Date, public author: string, public deleted?: DashboardSoftDeletedTubMoment) { }
}
