<button mat-icon-button [matMenuTriggerFor]="primaryNotificationMenu">
  <mat-icon
    [matBadgeHidden]="unreadNotificationsCount === 0"
    [matBadge]="unreadNotificationsCount.toString()">
    notifications
  </mat-icon>
</button>
<mat-menu #primaryNotificationMenu="matMenu">
  <div id="menu-container">
    <a *ngIf="notifications.length === 0" disabled mat-menu-item>
      You have no notifications
    </a>
    <ng-container *ngFor="let notification of notifications; trackByProperty: 'primaryNotifications'">
      <ng-container [ngSwitch]="notification.data.type">
        <app-primary-notification appChatRequestNotification [notificationData]="notification" *ngSwitchCase="'chatRequest'"></app-primary-notification>
        <app-primary-notification appLeadStatusChangeNotification [notificationData]="notification" *ngSwitchCase="'leadStatus'"></app-primary-notification>
        <app-primary-notification appAssignedPatientNotification [notificationData]="notification" *ngSwitchCase="'assignedPatient'"></app-primary-notification>
      </ng-container>
      <mat-divider></mat-divider>
    </ng-container>
    <div *ngIf="notifications.length > 0"
      [disabled]="unreadNotificationsCount === 0"
      fxLayout="row"
      fxLayoutAlign="center"
      mat-menu-item
      class="mark-all-read"
      (click)="markAllChatRequestNotificationsAsRead()">
      Mark All As Read
    </div>
  </div>

</mat-menu>
