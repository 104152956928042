import { Directive, Host, Input, OnInit } from '@angular/core';

import { DatabaseNotificationType } from '@shared/services/notifications';
import { PrimaryNotificationComponent } from './primary-notification/primary-notification.component';
import { DashboardNotification } from './dashboard-notification';

@Directive({
    selector: '[appLeadStatusChangeNotification]',
    standalone: true
})
export class LeadStatusChangeNotificationDirective implements OnInit {

  @Input() notificationData: DatabaseNotificationType;

  constructor(@Host() private primaryNotificationComponent: PrimaryNotificationComponent) { }

  ngOnInit() {
    const { patientId, isLead } = this.notificationData.data;
    const timestamp = this.notificationData.ts;

    const icon = 'next_week';
    const link = `/therapist/assigned-patients/patient-profile/${patientId}`;
    const title = 'Lead Therapist Change';
    const message = `You ${isLead === 'true' ? 'have been made' : 'are no longer'} lead therapist for a patient.`;
    const readAtTime = this.notificationData.read;
    const id = this.notificationData.id;

    this.primaryNotificationComponent.notification = new DashboardNotification(icon, link, title, message, timestamp, readAtTime, id);
  }
}
