import { Component } from '@angular/core';
import { MainTabsService   } from './main-tabs-service';
import { RouterResolveStatusService } from '@app/modules/shared/services/router-resolve-status.service';

@Component({
    selector: 'app-main-tabs',
    templateUrl: './main-tabs.component.html',
    styleUrls: ['./main-tabs.component.scss'],
    standalone: false,
})
export class MainTabsComponent {
  public tabs = this.mainTabsService.activeTabs$;
  public currentUrl = window.location.pathname;

  constructor(
    public mainTabsService: MainTabsService,
    public routerResolveStatus: RouterResolveStatusService,
  ) { }

 }
