import { ChangeDetectorRef, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule, ViewportScroller } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';

import { InjectableEnvironmentService } from '@shared/services/injectable-environment-service';
import { LegalDocsState } from './legal-docs-state';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-legal-docs',
    templateUrl: './legal-docs.component.html',
    styleUrls: ['./legal-docs.component.scss'],
    imports: [CommonModule],
    standalone: true
})
export class LegalDocsComponent implements OnInit {

  private lang: string;
  private doc: string;

  public legalHtml: SafeHtml;
  public errorMessage: string;

  // Valid legal documents
  private validLegalDocs = {
    eula: 'html',
    'privacy-policy': 'html',
    'collection-statement': 'html',
  };

  // State management
  private stateInternal: LegalDocsState;

  get state(): LegalDocsState {
    return this.stateInternal;
  }

  set state(value: LegalDocsState) {
    if (this.stateInternal !== value) {
      this.stateInternal = value;
    }
  }

  public get legalDocsState(): typeof LegalDocsState {
    return LegalDocsState;
  }

  constructor(private route: ActivatedRoute,
              private http: HttpClient,
              private environment: InjectableEnvironmentService,
              private translate: TranslateService,
              private domSanitizer: DomSanitizer,
              private viewportScroller: ViewportScroller,
              private changeDetectorRef: ChangeDetectorRef,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document
  ) {
    this.route.params.subscribe(params => {
      if (this.validLegalDocs[params.doc]) {
        this.doc = params.doc;
      } else {
        this.state = LegalDocsState.Error;
        this.errorMessage = 'Invalid parameter';
      }
    });
  }

  ngOnInit(): void {
    this.state = LegalDocsState.Loading;

    this.route.queryParams.subscribe(async params => {
      const inputLanguage: string = (params.lang ?? 'en').toLowerCase();
      const pageAnchor: string = params.goto ?? '';

      // Sanitise input
      this.lang = inputLanguage.replace(/[^a-z_]/gi, '');

      this.translate.use(this.lang).subscribe(() => {
        console.log(`Successfully set '${this.lang}' language.'`);
      }, () => {
        this.state = LegalDocsState.Error;
        this.errorMessage = `Problem with '${this.lang}' language initialization`;
        console.error(`Problem with '${this.lang}' language initialization.'`);
      }, async () => {
        await this.loadDocument();
        this.changeDetectorRef.detectChanges();
        this.viewportScroller.scrollToAnchor(pageAnchor);
      });
    });

    this.configureDarkModeDetection();
  }

  private async loadDocument(): Promise<void> {
    // First try and load document for the current language
    try {
      await this.getLegalDoc(this.lang, this.doc);
      this.state = LegalDocsState.Loaded;
      return;
    } catch (e) {
      if (e.status !== 404) {
        this.handleError(e);
      }
    }

    // Fallback to English
    try {
      await this.getLegalDoc('en', this.doc);
      this.state = LegalDocsState.Loaded;
    } catch (e) {
      this.handleError(e);
    }
  }

  private async getLegalDoc(lang: string, doc: string): Promise<void> {
    const legalDocText = await this.getDocumentFromCloudStorage(lang, doc);
    this.legalHtml = this.domSanitizer.bypassSecurityTrustHtml(legalDocText);
  }

  private async getDocumentFromCloudStorage(lang: string, doc: string): Promise<string> {
    return await this.http.get(`${this.environment.getEnvironment().staticStorageBaseUrl}/legal/${lang}/${doc}.${this.validLegalDocs[doc]}`,
      { responseType: 'text' })
      .toPromise();
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      this.errorMessage = 'Failed to retrieve page. Please try again later.';
    }
    this.state = LegalDocsState.Error;
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  /**
   * Injects a meta tag to prompt the browser to try and style the page in accordance with the user's browser preferences.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/color-scheme
   */
  private configureDarkModeDetection(): void {
    // Create the meta element <meta name="color-scheme" content="light dark">
    const metaTag = this.renderer.createElement('meta');
    this.renderer.setAttribute(metaTag, 'name', 'color-scheme');
    this.renderer.setAttribute(metaTag, 'content', 'light dark');

    // Append the meta tag to the head
    this.renderer.appendChild(this.document.head, metaTag);
  }
}
