import { Moment } from '../moment';
import { MomentType } from '../moment-type';

export class DischargeMoment extends Moment {
  public therapistId: string;
  public patientId: string;
  public therapistName: string;
  public createdByUserId: number;
  readonly typeName = MomentType.Discharge;

  constructor(options: { createdByUserId: number, author: string, id: string, timestamp: Date, therapistName: string }) {
    super(options.id, options.timestamp, options.author);
    this.therapistName = options.therapistName;
    this.createdByUserId = options.createdByUserId;
    this.therapistName = options.therapistName;
  }
}
