import { Moment, AppointmentMomentLinkConnectionType, AppointmentMomentPosition, EditedMomentData } from '@shared/components/patient-timeline/moment/moment';
import { DashboardSoftDeletedTubMoment } from '@backend-client/models/dashboard-soft-deleted-tub-moment';
import { MomentType } from '@shared/components/patient-timeline/moment/moment-type';

export const RiskAssessmentQuestions = {
  GAD7_1: 'Feeling nervous, anxious or on edge',
  GAD7_2: 'Not being able to stop or control worrying',
  GAD7_3: 'Worrying too much about different things',
  GAD7_4: 'Trouble relaxing',
  GAD7_5: 'Being so restless that it is hard to sit still',
  GAD7_6: 'Becoming easily annoyed or irritable',
  GAD7_7: 'Feeling afraid as if something awful might happen',
  PHQ9_1: 'Little interest or pleasure in doing things',
  PHQ9_2: 'Feeling down, depressed or hopeless',
  PHQ9_3: 'Trouble falling or staying asleep, or sleeping too much',
  PHQ9_4: 'Feeling tired or having little energy',
  PHQ9_5: 'Poor appetite or overeating',
  PHQ9_6: 'Feeling bad about yourself - or that you are a failure or have let yourself or your family down',
  PHQ9_7: 'Trouble concentrating on things, such as reading the newspaper or watching television',
  PHQ9_8:
    'Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual',
  PHQ9_9: 'Thoughts that you would be better off dead or of hurting yourself in some way',
};

export const RiskAssessmentIntroText = 'In the last two weeks, how often have you experienced...';

export const RiskAssessmentYesNoQuestions = {
  GAD7_Panic: 'Over the last two weeks have you had an anxiety attack (suddenly feeling fear or panic)?',
};

export const RiskAssessmentAnswers = {
  0: 'Not at all',
  1: 'Several days',
  2: 'More than half the days',
  3: 'Nearly every day',
};

export interface RiskAssessmentData {
  Results: {
    /**
     * Array of question IDs.
     */
    QuestionsAsked: string[];
    /**
     * Array of question answers which map exactly to {@link QuestionsAsked}.
     */
    QuestionResults: number[];

    /**
     * Normalised anxiety score. See {@link Scores} for raw value. Optional because it is only provided by the old Unity client.
     */
    Anxiety?: number;
    /**
     * Normalised depression score. See {@link Scores} for raw value. Optional because it is only provided by the old Unity client.
     */
    Depression?: number;

    /**
     * Key-value pairs of scores names  raw integer scores.
     */
    Scores: {
      depression?: number;
      anxiety?: number;
      studySev?: number;
      panic?: number;
      rs_stress?: number;
      rs_resilience?: number;
    };
  };

  authorTherapist?: boolean;
}

export class RiskAssessmentEventMoment extends Moment {
  readonly typeName = MomentType.Event;
  private data: RiskAssessmentData;

  public GADQuestions: {question: string; answer: string; questionIndex: string; answerIndex: number;}[] = [];
  public PHQQuestions: {question: string; answer: string; questionIndex: string; answerIndex: number;}[] = [];

  public GADScore: number;
  public PHQScore: number;
  public GADSeverity: string;
  public PHQSeverity: string;

  public titleText: string;
  public authorTherapist?: boolean;
  public edited: EditedMomentData;
  public appointmentRef: string;
  public appointmentMomentPosition: AppointmentMomentPosition = null;

  public get displayAppointmentConnectors(): AppointmentMomentLinkConnectionType {
    if (this.appointmentRef) {
      if (this.appointmentMomentPosition === AppointmentMomentPosition.start) {
        return AppointmentMomentLinkConnectionType.StartLink;
      }

      if (this.appointmentMomentPosition === AppointmentMomentPosition.last) {
        return AppointmentMomentLinkConnectionType.EndLink;
      }

      return AppointmentMomentLinkConnectionType.MiddleLink;
    }
  }

  constructor(options: {
    id: string,
    timestamp: Date,
    author: string,
    data: RiskAssessmentData,
    deleted?: DashboardSoftDeletedTubMoment,
    edited?: EditedMomentData,
    appointmentRef?: string,
    appointmentMomentPosition?: AppointmentMomentPosition,
  }) {
    super(options.id, options.timestamp, options.author, options.deleted);
    this.data = options.data;
    this.authorTherapist = options.data.authorTherapist;
    this.edited = options.edited;
    this.appointmentRef = options.appointmentRef;
    this.appointmentMomentPosition = options.appointmentMomentPosition;
    this.fixQuestionOrdering();

    // precompute the questions for GAD and PHQ and store the resulting array of strings to display
    this.GADQuestions = this.getSection('GAD7_');
    this.PHQQuestions = this.getSection('PHQ9_');
    this.titleText = this.generateTitleText();

    // pull out the GAD and PHQ scores, and calculate their severity strings
    this.GADScore = this.data?.Results?.Scores?.anxiety;
    this.PHQScore = this.data?.Results?.Scores?.depression;

    if(this.PHQScore <= 4) { this.PHQSeverity = 'None'; }
    else if(this.PHQScore <= 9) { this.PHQSeverity = 'Mild'; }
    else if(this.PHQScore <= 14) { this.PHQSeverity = 'Moderate'; }
    else if(this.PHQScore <= 19) { this.PHQSeverity = 'Moderately Severe'; }
    else if(this.PHQScore == null) { this.PHQSeverity = null; }
    else { this.PHQSeverity = 'Severe'; }

    if(this.GADScore <= 4) { this.GADSeverity = 'None'; }
    else if(this.GADScore <= 9) { this.GADSeverity = 'Mild'; }
    else if(this.GADScore <= 14) { this.GADSeverity = 'Moderate'; }
    else if(this.GADScore == null) { this.GADSeverity = null; }
    else { this.GADSeverity = 'Severe'; }
  }

  /**
   * Parses the risk assessment data and returns an array of questions and answers for the given section.
   * @param section The section to parse. Either `GAD7_` or `PHQ9_`.
   * @returns An array of objects with `question` and `answer` strings to be displayed directly to the user
   */
  public getSection(section: `GAD7_` | `PHQ9_`): {question: string; answer: string; questionIndex: string; answerIndex: number;}[] {
    const output: {question: string; answer: string; questionIndex: string; answerIndex: number;}[] = [];
    try {
      const questionsAsked = this.data.Results.QuestionsAsked;
      const questionResults = this.data.Results.QuestionResults;
      for (let i = 0; i < questionsAsked.length; i++) {
        const question = questionsAsked[i];
        const answer = questionResults[i];
        if (question.startsWith(section)) {
          if(RiskAssessmentYesNoQuestions[question]) {
            output.push({
              question: RiskAssessmentYesNoQuestions[question],
              answer: answer === 0 ? 'No' : 'Yes',
              questionIndex: question,
              answerIndex: answer
            });
          } else {
            output.push({
              question: RiskAssessmentQuestions[question],
              answer: `${RiskAssessmentAnswers[answer]} (${answer})`,
              questionIndex: question,
              answerIndex: answer
            });
          }
        }
      }
    } catch (err) {
      console.warn('Error parsing risk assessment data.', this.data, err);
      return [];
    }
    return output;
  }

  /**
   * Generates a title for the risk assessment moment based on the scores present in the data
   * under the Results.Scores object.
   */
  public generateTitleText(): string {
    const scoreTexts = [];
    try {
      // include each score in the title, only if they are present
      const scores = this.data.Results.Scores;

      if (typeof scores.depression === 'number') {
        scoreTexts.push(`PHQ: ${scores.depression}`);
      }
      if (typeof scores.anxiety === 'number') {
        scoreTexts.push(`GAD: ${scores.anxiety}`);
      }
      if (typeof scores.rs_stress === 'number') {
        scoreTexts.push(`Stress: ${scores.rs_stress}`);
      }
      if (typeof scores.rs_resilience === 'number') {
        scoreTexts.push(`Resilience: ${scores.rs_resilience}`);
      }
    } catch (err) {
      console.warn('Error generating risk assessment title text.', this.data, err);
    }
    return scoreTexts.join(' • ') || `Risk Assessment (Unknown)`;
  }

  /**
   * The answers and questions arrays are stored in opposite orders on TUB, so one of the arrays must be reversed to enable a 1:1
   * mapping between them.
   */
  private fixQuestionOrdering(): void {
    // Null check required! Some old broken unity data does not include QuestionsAsked
    this.data.Results?.QuestionsAsked?.reverse();
  }
}
