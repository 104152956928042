import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getDayOfWeek',
    standalone: false
})
export class GetDayOfWeekPipe implements PipeTransform {

  private days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  transform(date: Date): any {
    return this.days[date.getDay()];
  }

}
