import { Component, Input } from '@angular/core';

import { AppointmentMomentLinkConnectionType } from '../moment/moment';
import { MomentType } from '../moment/moment-type';
import { AppointmentRelatedMoment } from '../moment/appointment-related-moment';
import { AppointmentMoment } from '../moment/appointment-moment/appointment-moment';

@Component({
    selector: 'app-appointment-moment-connector',
    templateUrl: './appointment-moment-connector.component.html',
    styleUrls: ['./appointment-moment-connector.component.scss'],
    standalone: false
})
export class AppointmentMomentConnectorComponent {

  AppointmentMomentLinkConnectionType = AppointmentMomentLinkConnectionType;
  public momentsMissingAboveIndicator = false;
  public momentsMissingBelowIndicator = false;
  public isLastVisibleInGroup = false;

  private currentAppointmentRef: string;

  @Input() linkConnectionType: AppointmentMomentLinkConnectionType;
  @Input() displayedMoments: AppointmentRelatedMoment[];
  @Input() set currentMoment(moment: AppointmentRelatedMoment) {
    this.currentAppointmentRef = moment?.typeName === MomentType.Appointment ? moment.id : moment?.appointmentRef;
    const appointmentMoment = this.displayedMoments.find(m => m.id === this.currentAppointmentRef) as AppointmentMoment;

    if (appointmentMoment) {
      if (moment?.typeName === MomentType.Appointment) {
        this.appointmentShowMissingMomentIndicator(appointmentMoment);
      }

      if (moment?.typeName === MomentType.CgiScore) {
        this.cgiScoreShowMissingMomentIndicator(appointmentMoment);
      }

      if (moment?.typeName === MomentType.Event) {
        this.riskAssessmentShowMissingMomentIndicator(appointmentMoment);
      }

      if (moment?.typeName === MomentType.Note) {
        this.noteShowMissingMomentIndicator(appointmentMoment);
      }
    }
  };
  @Input() isInLinkGroup: boolean;
  // Dynamic colour for lines and dots - default set to blue
  @Input() borderColorHexVal = '#3cc4e3';

  private isVisible(ref: string): boolean {
    return !!this.displayedMoments.find(m => m.id === ref);
  }

  private appointmentShowMissingMomentIndicator(appointmentMoment: AppointmentMoment): void {
    // If no moments visible after appointment show missing indicator
    if (
      appointmentMoment?.noteRef && !this.isVisible(appointmentMoment?.noteRef) &&
      appointmentMoment?.cgiRef && !this.isVisible(appointmentMoment?.cgiRef)
    ) {
      if (appointmentMoment?.riskAssessmentRef && !this.isVisible(appointmentMoment?.riskAssessmentRef) || !appointmentMoment?.riskAssessmentRef) {
        this.momentsMissingBelowIndicator = true;
      }

      if (appointmentMoment?.riskAssessmentRef && !this.isVisible(appointmentMoment?.riskAssessmentRef) && appointmentMoment?.attachmentsRef?.length > 0 && !this.isVisible(appointmentMoment?.attachmentsRef[0]))  {
        this.momentsMissingBelowIndicator = true;
      }

      if (
        appointmentMoment?.cgiRef && !this.isVisible(appointmentMoment?.cgiRef) && appointmentMoment?.riskAssessmentRef && !this.isVisible(appointmentMoment?.riskAssessmentRef) && appointmentMoment?.noteRef && !this.isVisible(appointmentMoment?.noteRef) ||
        appointmentMoment?.cgiRef && !this.isVisible(appointmentMoment?.cgiRef) && !appointmentMoment?.riskAssessmentRef && appointmentMoment?.noteRef && !this.isVisible(appointmentMoment?.noteRef)
      ) {
        // Are attachments visible? if not end visual indicator - show end of group missing moments
        if (appointmentMoment?.attachmentsRef?.length > 0 && !this.isVisible(appointmentMoment?.attachmentsRef[0]) || appointmentMoment?.attachmentsRef?.length === 0) {
          this.isLastVisibleInGroup = true;
        }
      }
    } else {
      this.momentsMissingBelowIndicator = false;
    }
  }

  private cgiScoreShowMissingMomentIndicator(appointmentMoment: AppointmentMoment): void {
    // Is there a risk assessment and the note is not visible? Show moment missing
    // Is there a risk assessment, but it is not visible and no attachments? Show moment missing
    if (
      appointmentMoment?.riskAssessmentRef && !this.isVisible(appointmentMoment?.riskAssessmentRef) ||
      appointmentMoment?.noteRef && !this.isVisible(appointmentMoment?.noteRef) && !appointmentMoment?.riskAssessmentRef
    ) {
      this.momentsMissingBelowIndicator = true;
    }

    if (
      appointmentMoment?.riskAssessmentRef && !this.isVisible(appointmentMoment?.riskAssessmentRef) && appointmentMoment?.noteRef && !this.isVisible(appointmentMoment?.noteRef) ||
      !appointmentMoment?.riskAssessmentRef && appointmentMoment?.noteRef && !this.isVisible(appointmentMoment?.noteRef)
    ) {
      // Are attachments NOT visible? - show end of group missing moments
      if (
        appointmentMoment?.attachmentsRef?.length > 0 && !this.isVisible(appointmentMoment?.attachmentsRef[0])
        || appointmentMoment?.attachmentsRef?.length === 0

      ) {
        this.isLastVisibleInGroup = true;
      }
    }
  }

  private riskAssessmentShowMissingMomentIndicator(appointmentMoment: AppointmentMoment): void {
    // If no CGI is visible show moment missing above
    if (appointmentMoment?.cgiRef && !this.isVisible(appointmentMoment?.cgiRef)) {
      this.momentsMissingAboveIndicator = true;
    }

    // If no note is visible show moment missing below
    if (appointmentMoment?.noteRef && !this.isVisible(appointmentMoment?.noteRef)) {
      this.momentsMissingBelowIndicator = true;

      // Are attachments NOT visible? - show end of group missing moments
      if (appointmentMoment?.attachmentsRef?.length > 0 && !this.isVisible(appointmentMoment?.attachmentsRef[0]) || appointmentMoment?.attachmentsRef?.length === 0) {
        this.isLastVisibleInGroup = true;
      }
    }
  }

  private noteShowMissingMomentIndicator(appointmentMoment: AppointmentMoment): void {
    // If there is no risk assessment and CGI is not visible? Show moment missing above
    if (!appointmentMoment?.riskAssessmentRef && appointmentMoment?.cgiRef && !this.isVisible(appointmentMoment?.cgiRef)) {
      this.momentsMissingAboveIndicator = true;
    }

    // Does risk assessment exist & visible? if not visible show moment missing above
    if (appointmentMoment?.cgiRef && !this.isVisible(appointmentMoment?.cgiRef) && appointmentMoment?.riskAssessmentRef && !this.isVisible(appointmentMoment?.riskAssessmentRef)) {
      this.momentsMissingAboveIndicator = true;
    }

    // Are attachments visible? if not end visual indicator, but show moment missing below
    if (appointmentMoment?.attachmentsRef?.length > 0 && !this.isVisible(appointmentMoment?.attachmentsRef[0])) {
      this.momentsMissingBelowIndicator = true;
      // Are attachments NOT visible? - show end of group missing moments
      this.isLastVisibleInGroup = true;
    }
  }
}
