// go-chat-duplication
import { Directive, Host, Input, OnInit } from '@angular/core';

import { GoPrimaryNotificationComponent } from './go-primary-notification/go-primary-notification.component';
import { GoDatabaseNotificationType } from '@shared/services/go-notifications';
import { GoDashboardNotification } from './go-dashboard-notification';

@Directive({
    selector: '[appGoChatRequestNotification]',
    standalone: true
})
export class GoChatRequestNotificationDirective implements OnInit {

  @Input() notificationData: GoDatabaseNotificationType;

  constructor(@Host() private primaryNotificationComponent: GoPrimaryNotificationComponent) { }

  ngOnInit() {
    const { fromTherapistFirstName, fromTherapistLastName } = this.notificationData.data;
    const therapistName = `${fromTherapistFirstName} ${fromTherapistLastName}`;

    let icon: string;
    const link = '/therapist/go-chat-requests';
    let title: string;
    let message: string;
    const timestamp = this.notificationData.ts;
    const readAtTime = this.notificationData.read;
    const id = this.notificationData.id;

    switch (this.notificationData.data.requestType) {
      case 'chat-patient-request': {
        icon = 'chat';
        title = 'New Chat Request';
        message = `You have received a new chat request from a patient.`;
        break;
      }
      case 'chat-therapist-handover': {
        icon = 'next_week';
        title = 'Patient Handover';
        message = `${therapistName} has requested to hand over a patient to you.`;
        break;
      }
      case 'chat-therapist-invite': {
        icon = 'person_add';
        title = 'New Chat Invite';
        message = `${therapistName} has invited you into a chat.`;
      }
    }

    this.primaryNotificationComponent.notification = new GoDashboardNotification(icon, link, title, message, timestamp, readAtTime, id);
  }
}
