import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable, Renderer2, signal, ViewChild } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private cssElement: HTMLLinkElement;
  private cssFile: string;
  private themeCSSID: string = 'themeCSS';

  private colorSchemePrefix = 'prefers-color-scheme-';
  public colourPreferenceSignal = signal<string>('light');
  private renderer: Renderer2;

  @ViewChild('themeCSS') themeElement: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  public setTheme(theme: string, renderer: Renderer2) {
    this.cssFile = `${theme}.css`;
    this.renderer = renderer;

    // If theme CSS file already in DOM, attempt to remove it
    this.removeExistingThemeStyle(renderer);

    // Create a link element via Angular's renderer to avoid SSR troubles
    this.cssElement = renderer.createElement('link') as HTMLLinkElement;

    // Set type of the link item and path to the css file
    renderer.setProperty(this.cssElement, 'rel', 'stylesheet');
    renderer.setProperty(this.cssElement, 'href', this.cssFile);
    renderer.setProperty(this.cssElement, 'id', this.themeCSSID);

    // Add the style to the head section
    renderer.appendChild(this.document.head, this.cssElement);
    // Set colour preference
    this.loadPreference(renderer);
  }

  public removeExistingThemeStyle(renderer: Renderer2) {
    const themeElement = this.themeElement?.nativeElement as HTMLElement;
    if (themeElement) {
      renderer.removeChild(this.document.head, themeElement);
    }
  }

  public loadPreference(renderer: Renderer2): void {
    this.getColorPreference();
    renderer.addClass(document.body, this.colorSchemePrefix + this.colourPreferenceSignal());
  }

  public setPreference(preference: string, skipStoringPreference = false): void {
    this.colourPreferenceSignal.set(preference);

    if (!skipStoringPreference) {
      // Save prefers-color-scheme to localStorage
      localStorage.setItem('prefers-color-scheme', preference);
    }
  }

  public updatePreference(): void {
    this.colourPreferenceSignal.update(value => value === 'light' ? 'dark' : 'light');
    // Save prefers-color-scheme to localStorage
    localStorage.setItem('prefers-color-scheme', this.colourPreferenceSignal());
    // Update DOM
    const removeClass = this.colourPreferenceSignal() !== 'light' ? 'light' : 'dark';
    this.renderer.removeClass(document.body, this.colorSchemePrefix + removeClass);
    this.renderer.addClass(document.body, this.colorSchemePrefix + this.colourPreferenceSignal());
  }

  public getColorPreference() {
    const localStorageColorScheme = localStorage.getItem('prefers-color-scheme');
    if (localStorageColorScheme) {
      // Get from localStorage
      this.setPreference(localStorageColorScheme, true);
    } else {
      // Detect prefers-color-scheme
      this.detectColorPreference();
    }
  }

  private detectColorPreference() {
    // Detect if prefers-color-scheme is supported
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      this.setPreference(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    } else {
      // Fallback to light
      this.setPreference('light');
    }
  }
}
