import { Component } from '@angular/core';

import { DischargeMoment } from './discharge-moment';

@Component({
    selector: 'app-discharge-moment',
    templateUrl: './discharge-moment.component.html',
    styleUrls: ['./discharge-moment.component.scss'],
    standalone: false
})
export class DischargeMomentComponent {

  momentData: DischargeMoment;
}
