<div fxLayout="column" fxLayoutAlign="center" class="container">
  <mat-card>
    <mat-card-content fxFlex>
      <mat-progress-bar id="loading" *ngIf="isLoading" mode="buffer"></mat-progress-bar>
      <img id="thrive-logo" src="/assets/images/thrive-icon-logo.webp" width="70" height="70"
           [ngClass]="spinDirectionForward ? 'spin' : spinDirectionForward !== null ? 'spin-back' : ''">
      <div #headingContainer id="heading-container">
        <h2 class="header">{{currentHeader.header}}</h2>
        <p class="sub-header">{{currentHeader.subHeader}}</p>
      </div>
      <ng-content></ng-content>
    </mat-card-content>

    <mat-card-footer>
      <div fxLayout="column" fxLayoutAlign="center">
        <span>{{dashboardName}} Version - {{ (appVersion | async)?.version }}</span>
        <span>Core Version -  {{ goCoreWebVersion }}</span>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
