import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TherapistStatusService {

  constructor() {}

  public geTherapistOnlineStatus() {
    // TODO: Send get value from TUB.

  }

  public markTherapistAsOnline(isOnline: boolean) {
    // TODO: Send this value to TUB.

  }
}
