import { Component, OnInit } from '@angular/core';

import { MediaObserver } from '@ngbracket/ngx-layout';

import { EnvironmentService } from '@shared/services/environment.service';
import { MainNavService } from '../main-nav/main-nav.service';
import { FeatureFlagService } from '@app/modules/shared/services/feature-flag.service';

@Component({
    selector: 'app-main-toolbar',
    templateUrl: './main-toolbar.component.html',
    styleUrls: ['./main-toolbar.component.scss'],
    standalone: false
})
export class MainToolbarComponent implements OnInit {
  public serverStatusMessage: string;
  public title: string;
  public isAustralia = false;

  public hoveringOverNav = false;

  constructor(
    private environmentService: EnvironmentService,
    public mediaObserver: MediaObserver,
    public featureFlagService: FeatureFlagService,
    public mainNavService: MainNavService,
  ) {}

  ngOnInit(): void {
    this.title = this.environmentService.getEnvironment().dashboardTitle ?? 'Thrive Dashboard';
    this.isAustralia = this.environmentService.isAustralia();
  }
}
