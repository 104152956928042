import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MessageService } from '@shared/services/message.service';
import { TimelineService } from '../timeline.service';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { MomentType } from '../moment/moment-type';

@Component({
    selector: 'app-add-file-moment',
    templateUrl: './add-file-moment.component.html',
    styleUrls: ['./add-file-moment.component.scss'],
    standalone: false
})
export class AddFileMomentComponent {

  @ViewChild('dialog') dialog: DialogComponent;

  public selectedFile: File;

  constructor(private dialogRef: MatDialogRef<AddFileMomentComponent>,
              private messageService: MessageService,
              @Inject(MAT_DIALOG_DATA) private data: { timelineService: TimelineService, patientId: string }) { }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

  public async addMomentButtonClicked(): Promise<void> {
    try {
      await this.dialog.showAsyncProgressForFunction(async () => {
        await this.data.timelineService.addFileMoment(this.data.patientId, this.selectedFile, MomentType.File);
        this.data.timelineService.ensureTypeSelectedOnFilterOptions(MomentType.File);
        this.data.timelineService.updateTimelineWithFilterOptions();
      });
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.messageService.showMessage('Error: ' + err.toString());
    }
  }

  public onFileInput(event): void {
    this.selectedFile = event.target.files[0];
  }
}
