import { Component, ElementRef, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MediaObserver } from '@ngbracket/ngx-layout';

import { take, takeUntil } from 'rxjs/operators';

import { RouterResolveStatusService } from '@shared/services/router-resolve-status.service';
import { MainNavService } from './main-nav.service';
import { OnDestroyObservable } from '@classes/on-destroy-observable';
import { AuthenticationService } from '@authentication/authentication.service';
import { MainNavLinkCollection } from './main-nav-links';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { OfficeHoursService } from '@shared/services/office-hours/office-hours.service';
import { animateText, onMainContentChange, onMainNavChange } from './main-nav-animations';
import { linkCollections } from './router-links';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: [ './main-nav.component.scss' ],
  animations: [onMainNavChange, onMainContentChange, animateText],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class MainNavComponent extends OnDestroyObservable implements OnInit {

  @ViewChildren('adminLink') adminLinks: QueryList<ElementRef>;
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;
  public serverStatusMessage: string;
  public linkCollections: MainNavLinkCollection[] = [];
  public allLinkCollections = linkCollections;
  public title: string;
  public isAustralia = false;

  constructor(private authenticationService: AuthenticationService,
              public mediaObserver: MediaObserver,
              public routerResolveStatus: RouterResolveStatusService,
              public mainNavService: MainNavService,
              public featureFlagService: FeatureFlagService,
              public officeHoursService: OfficeHoursService
  ) {
    super();
  }

  ngOnInit(): void {
    this.mainNavService.mainNavState$.pipe(take(3)).subscribe(open => {
      if (!open) {
        for (const accordion of this.expansionPanels) {
          accordion.close();
        }
      }
    });

    this.authenticationService.getUserScopes$().pipe(takeUntil(this.ngOnDestroy$)).subscribe(async () => {
      this.linkCollections = await this.mainNavService.getMainNavLinks();
    });
  }

  public openMenu(): void {
    if (!this.mainNavService.mainNavFixedOpen$.value && !this.mainNavService.mainNavState$.value) {
      const mainNavState = !this.mainNavService.mainNavState$.value;
      this.mainNavService.mainNavState$.next(mainNavState);
    }
  }

  public closeMenu(): void {
    if (!this.mainNavService.mainNavFixedOpen$.value && this.mainNavService.mainNavState$.value) {
      const mainNavState = !this.mainNavService.mainNavState$.value;
      this.mainNavService.mainNavState$.next(mainNavState);

      for (const accordion of this.expansionPanels) {
        accordion.close();
      }
    }
  }
}
