import { Component, OnInit } from '@angular/core';

import { MediaObserver } from '@ngbracket/ngx-layout';

import { ServerStatusService } from '@shared/services/server-status.service';
import { MainNavService } from '../components/main-nav/main-nav.service';
import { onMainContentChange, onMainNavChange } from '../components/main-nav/main-nav-animations';
import { NavigationEnd, Router } from '@angular/router';
import { MainTabsService } from '../components/main-tabs/main-tabs-service';

@Component({
    selector: 'app-default-template',
    templateUrl: './default-template.component.html',
    styleUrls: [ './default-template.component.scss' ],
    animations: [onMainContentChange, onMainNavChange],
    standalone: false
})
export class DefaultTemplateComponent implements OnInit {

  public hoveringOverNav = false;
  public serverStatusMessage: string;

  constructor(
    private serverStatus: ServerStatusService,
    private router: Router,
    private mainTabsService: MainTabsService,
    public mediaObserver: MediaObserver,
    public mainNavService: MainNavService,
  ) {
    this.router.events.subscribe(event => {
       if (event instanceof NavigationEnd) {
         this.mainTabsService.addTab(event.url);
       }
     });
  }

  ngOnInit(): void {
    this.serverStatus.isServerOnline.subscribe(status => {
      if (this.serverStatus.isGoingOffline) {
        // create a new Date object, using the offline time
        const offlineTime = new Date(status.offlineTime);
        this.serverStatusMessage = 'The Thrive server will go offline at ' + offlineTime;
      } else if (!this.serverStatus.isOnline) {
        this.serverStatusMessage = 'The Thrive server is currently offline for maintenance';
      } else {
        this.serverStatusMessage = '';
      }
    });
  }
}
