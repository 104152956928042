import { Directive, HostListener } from '@angular/core';
import { TimelineService } from '../components/patient-timeline/timeline.service';

@Directive({ selector: '[appClosePatientChatPanel]', standalone: false })
export class AppClosePatientChatPanelDirective {
    @HostListener('click', ['$event']) onClick(){
      if (this.timelineService.patientTimelinePanelOpen$.value) {
        this.timelineService.patientTimelinePanelOpen$.next(false);
      }
    }

    constructor(private timelineService: TimelineService) {}
}
