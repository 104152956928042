import { Component } from '@angular/core';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { NoteMoment } from './note-moment';
import { MomentService } from '../moment.service';

@Component({
    selector: 'app-note-moment',
    templateUrl: './note-moment.component.html',
    styleUrls: ['./note-moment.component.scss'],
    standalone: false
})
export class NoteMomentComponent {
  public momentData: NoteMoment;
  public featureFlags$ = this.featureFlagService.featureFlags$;

  constructor(
    public momentService: MomentService,
    private featureFlagService: FeatureFlagService
  ) { }
}
