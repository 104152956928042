<mat-nav-list>
  <!-- Home link -->
  <app-nav-link [linkData]="mainNavService.homeLink"></app-nav-link>

  <ng-container *ngFor="let linkCollection of linkCollections; trackByProperty: 'navLinkCollections'">
    <ng-template #title>
      <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="6px" class="menu-divider">
        <mat-divider fxFlex="1 1 6px"></mat-divider>
        <div class="divider-text">{{ linkCollection.title }}</div>
        <mat-divider fxFlex="1 0"></mat-divider>
      </div>
    </ng-template>

    <!-- LINKS -->
    <div *ngIf="linkCollection.links?.length > 0" fxLayout="column" fxLayoutAlign="space-evenly">
      <ng-template [ngTemplateOutlet]="title"></ng-template>
      @for (navLink of linkCollection.links; track navLink) {
        <app-nav-link [linkData]="navLink"></app-nav-link>
      }
    </div>

    <!-- EXPANDABLE SECTIONS -->
    <div *ngIf="linkCollection.sections?.length > 0" fxLayout="column" fxLayoutAlign="space-evenly" fxLayoutGap="10px">
      <ng-template [ngTemplateOutlet]="title"></ng-template>
      @for (section of linkCollection.sections; track section) {
        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="section.links | containsActiveLink" (opened)="openMenu()" (closed)="closeMenu()">
            <mat-expansion-panel-header [expandedHeight]="'48px'">
              <mat-panel-title [ngClass]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'menu-open' : 'menu-closed'">
                <div fxLayout="row" class="link-text">
                  <mat-icon *ngIf="section.matIcon && !section.svgIcon" class="icon">{{ section.matIcon }}</mat-icon>
                  <mat-icon *ngIf="section.svgIcon" [svgIcon]="section.svgIcon" class="icon white"></mat-icon>
                  <span [@animateText]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'show' : 'hide'">
                    {{ section.title }}
                  </span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            @for (sectionLink of section.links; track sectionLink) {
              <app-nav-link [linkData]="sectionLink" [sectionLink]="true"></app-nav-link>
            }
          </mat-expansion-panel>
        </mat-accordion>
      }
    </div>
  </ng-container>
</mat-nav-list>
