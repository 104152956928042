/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AccessCodesService } from './services/access-codes.service';
import { AccessCodeManagementService } from './services/access-code-management.service';
import { AppEngineLifecycleService } from './services/app-engine-lifecycle.service';
import { BETAUNSTABLEService } from './services/betaunstable.service';
import { ClientConfigService } from './services/client-config.service';
import { CloudDocumentsService } from './services/cloud-documents.service';
import { ContactUsService } from './services/contact-us.service';
import { EmailControllerService } from './services/email-controller.service';
import { ErrorReportingService } from './services/error-reporting.service';
import { FirebaseConfigService } from './services/firebase-config.service';
import { IAMSAccountDetailsService } from './services/iamsaccount-details.service';
import { IAMSAuthenticationService } from './services/iamsauthentication.service';
import { IAMSOpenIDService } from './services/iamsopen-id.service';
import { IAMSScopesInfoService } from './services/iamsscopes-info.service';
import { IAMSUserManagementService } from './services/iamsuser-management.service';
import { QueryStringTokenService } from './services/query-string-token.service';
import { MessageAlertsService } from './services/message-alerts.service';
import { PatientTimelineService } from './services/patient-timeline.service';
import { OnboardingSurveyService } from './services/onboarding-survey.service';
import { OrganisationsService } from './services/organisations.service';
import { PatientMetadataService } from './services/patient-metadata.service';
import { RewardsService } from './services/rewards.service';
import { PushNotificationsService } from './services/push-notifications.service';
import { RegionInteroperabilityService } from './services/region-interoperability.service';
import { RemoteConfigService } from './services/remote-config.service';
import { SocialService } from './services/social.service';
import { ServerStatusService } from './services/server-status.service';
import { ChatToolsService } from './services/chat-tools.service';
import { ChatsV2Service } from './services/chats-v2.service';
import { PatientChatService } from './services/patient-chat.service';
import { PatientService } from './services/patient.service';
import { PatientsService } from './services/patients.service';
import { TherapistAssignedPatientsService } from './services/therapist-assigned-patients.service';
import { TherapistChatService } from './services/therapist-chat.service';
import { TherapistService } from './services/therapist.service';
import { TherapistsService } from './services/therapists.service';
import { TimeService } from './services/time.service';
import { UsersDataService } from './services/users-data.service';
import { SurveyService } from './services/survey.service';
import { SurveysService } from './services/surveys.service';
import { ChatsService } from './services/chats.service';
import { ReportingToolService } from './services/reporting-tool.service';
import { MultiFactorAuthenticationService } from './services/multi-factor-authentication.service';
import { TimeBasedOneTimePasswordsMultiFactorAuthenticationService } from './services/time-based-one-time-passwords-multi-factor-authentication.service';
import { EmailCodesMultiFactorAuthenticationService } from './services/email-codes-multi-factor-authentication.service';
import { DomoDashboardViewsService } from './services/domo-dashboard-views.service';
import { GoogleDriveAuthenticationService } from './services/google-drive-authentication.service';
import { AnalystsService } from './services/analysts.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({ exports: [],
    declarations: [], imports: [], providers: [
        ApiConfiguration,
        AccessCodesService,
        AccessCodeManagementService,
        AppEngineLifecycleService,
        BETAUNSTABLEService,
        ClientConfigService,
        CloudDocumentsService,
        ContactUsService,
        EmailControllerService,
        ErrorReportingService,
        FirebaseConfigService,
        IAMSAccountDetailsService,
        IAMSAuthenticationService,
        IAMSOpenIDService,
        IAMSScopesInfoService,
        IAMSUserManagementService,
        QueryStringTokenService,
        MessageAlertsService,
        PatientTimelineService,
        OnboardingSurveyService,
        OrganisationsService,
        PatientMetadataService,
        RewardsService,
        PushNotificationsService,
        RegionInteroperabilityService,
        RemoteConfigService,
        SocialService,
        ServerStatusService,
        ChatToolsService,
        ChatsV2Service,
        PatientChatService,
        PatientService,
        PatientsService,
        TherapistAssignedPatientsService,
        TherapistChatService,
        TherapistService,
        TherapistsService,
        TimeService,
        UsersDataService,
        SurveyService,
        SurveysService,
        ChatsService,
        ReportingToolService,
        MultiFactorAuthenticationService,
        TimeBasedOneTimePasswordsMultiFactorAuthenticationService,
        EmailCodesMultiFactorAuthenticationService,
        DomoDashboardViewsService,
        GoogleDriveAuthenticationService,
        AnalystsService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
