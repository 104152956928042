import { Component } from '@angular/core';

import { RouterResolveStatusService } from '@shared/services/router-resolve-status.service';

@Component({
    selector: 'app-external-template',
    templateUrl: './external-template.component.html',
    styleUrls: [ './external-template.component.scss' ],
    standalone: false
})
export class ExternalTemplateComponent {
  constructor(public routerResolveStatus: RouterResolveStatusService) {}
}
