import { Component } from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';

import { DialogService } from '@shared/components/dialog/dialog.service';
import { LogoutConfirmationDialogComponent } from '../logout-confirmation-dialog/logout-confirmation-dialog.component';
import { MainNavService } from '../main-nav/main-nav.service';
import { animateText } from '../main-nav/main-nav-animations';
import { MainNavLinkCollection } from '../main-nav/main-nav-links';
import { OnDestroyObservable } from '@app/classes/on-destroy-observable';

@Component({
    selector: 'app-main-sidenav',
    templateUrl: './main-sidenav.component.html',
    styleUrls: ['./main-sidenav.component.scss'],
    animations: [animateText],
    standalone: false
})
export class MainSideNavComponent extends OnDestroyObservable {

  public linkCollections: MainNavLinkCollection[] = [];

  constructor(
    private dialogService: DialogService,
    public mediaObserver: MediaObserver,
    public mainNavService: MainNavService,
  ) {
    super();
  }

  public fixSideNavOpen(): void {
    this.mainNavService.mainNavFixedOpen$.next(!this.mainNavService.mainNavFixedOpen$.value);
    this.mainNavService.mainNavState$.next(!this.mainNavService.mainNavState$.value);
    localStorage.setItem('menu_open', `${!this.mainNavService.mainNavOpen$.value}`);
    this.mainNavService.mainNavOpen$.next(!this.mainNavService.mainNavOpen$.value);
  }

  public signOutButtonClicked(): void {
    this.dialogService.openDialog(LogoutConfirmationDialogComponent);
  }
}
