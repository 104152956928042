import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSize',
    standalone: false
})
export class FileSizePipe implements PipeTransform {
  transform(value: number, unit: 'kb' | 'mb', roundToWhole = true): string {
    let output = value / (unit === 'kb' ? 1024 : 1024 * 1024);
    if (roundToWhole) {
      output = Math.round(output);
    }
    return output + unit;
  }
}
