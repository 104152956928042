import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../services/helper.service';

@Pipe({
    name: 'convertTimeToString',
    standalone: false
})
export class ConvertTimeToStringPipe implements PipeTransform {

  constructor(private helperService: HelperService) {}

  transform(time: number): string {
    return this.helperService.convertMillisecondsToString(time);
  }

}
