/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubRequestTubChatRequest } from '../models/tub-request-tub-chat-request';
import { TubTherapistRequestHandlerResponse } from '../models/tub-therapist-request-handler-response';
import { TubChatSession } from '../models/tub-chat-session';
import { PubNubToken } from '../models/pub-nub-token';
import { TubPubNubLastReadChatsResponse } from '../models/tub-pub-nub-last-read-chats-response';
import { TubChatRequestMessage } from '../models/tub-chat-request-message';
import { TubChatSessionV2 } from '../models/tub-chat-session-v2';
import { TubChatSessionV2Summary } from '../models/tub-chat-session-v2summary';
import { TubChatUserPresenceInfo } from '../models/tub-chat-user-presence-info';
@Injectable({
  providedIn: 'root',
})
class TherapistChatService extends __BaseService {
  static readonly TherapistChatRequestsListRequestsPath = '/therapist/chat-requests';
  static readonly TherapistChatRequestsAcceptPath = '/therapist/chat-requests/{requestId}/accept';
  static readonly TherapistChatsListChatsPath = '/therapist/chats';
  static readonly TherapistChatsTokenPath = '/therapist/chats/token';
  static readonly TherapistChatsTokenDeletePath = '/therapist/chats/token';
  static readonly TherapistChatsPubNubUnreadMessagesPath = '/therapist/chats/unread-messages';
  static readonly TherapistChatsInvitePath = '/therapist/chats/{patientId}/invite/{therapistId}';
  static readonly TherapistChatsHandoverPath = '/therapist/chats/{patientId}/handover/{therapistId}';
  static readonly TherapistChatsEndChatPath = '/therapist/chats/{patientId}/end-chat';
  static readonly TherapistChatsLeaveChatPath = '/therapist/chats/{patientId}/leave-chat';
  static readonly TherapistChatsClaimLeadPath = '/therapist/chats/{patientIdOrEmail}/claim-lead';
  static readonly TherapistChatsListPinnedChatsPath = '/therapist/chats/pinned';
  static readonly TherapistChatsPinChatSessionPath = '/therapist/chats/{patientId}/action/pin-chat';
  static readonly TherapistChatsUnpinChatSessionPath = '/therapist/chats/{patientId}/action/unpin-chat';
  static readonly TherapistChatsControllerV2ListChatsPath = '/therapist/v2/chats';
  static readonly TherapistChatsControllerV2TidyTherapistNotificationCachePath = '/therapist/v2/chats/action/tidy-therapist-notification-cache';
  static readonly TherapistChatsControllerV2GetChatPath = '/therapist/v2/chats/{chatSessionId}';
  static readonly TherapistChatsControllerV2GetChatSummaryPath = '/therapist/v2/chats/{chatSessionId}/summary';
  static readonly TherapistChatsControllerV2GetChatParticipantsPath = '/therapist/v2/chats/{chatSessionId}/participants';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lists the top 20 patient chat requests for an authenticated therapist.
   * @param pubNub Flag to determine type of the chat.
   * @return Ok
   */
  TherapistChatRequestsListRequestsResponse(pubNub?: boolean): __Observable<__StrictHttpResponse<Array<TubRequestTubChatRequest>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pubNub != null) __params = __params.set('pubNub', pubNub.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/chat-requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubRequestTubChatRequest>>;
      })
    );
  }
  /**
   * Lists the top 20 patient chat requests for an authenticated therapist.
   * @param pubNub Flag to determine type of the chat.
   * @return Ok
   */
  TherapistChatRequestsListRequests(pubNub?: boolean): __Observable<Array<TubRequestTubChatRequest>> {
    return this.TherapistChatRequestsListRequestsResponse(pubNub).pipe(
      __map(_r => _r.body as Array<TubRequestTubChatRequest>)
    );
  }

  /**
   * Accept a generic chat request. Behaviour is dependant on the type of request.
   * @param requestId The firestore ID of the request to accept.
   * @return Ok
   */
  TherapistChatRequestsAcceptResponse(requestId: string): __Observable<__StrictHttpResponse<TubTherapistRequestHandlerResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/chat-requests/${encodeURIComponent(String(requestId))}/accept`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubTherapistRequestHandlerResponse>;
      })
    );
  }
  /**
   * Accept a generic chat request. Behaviour is dependant on the type of request.
   * @param requestId The firestore ID of the request to accept.
   * @return Ok
   */
  TherapistChatRequestsAccept(requestId: string): __Observable<TubTherapistRequestHandlerResponse> {
    return this.TherapistChatRequestsAcceptResponse(requestId).pipe(
      __map(_r => _r.body as TubTherapistRequestHandlerResponse)
    );
  }

  /**
   * List all active chats for the authenticated therapist.
   *
   * List all active chats for the authenticated therapist.  Supports optional paging.
   * Please note that if paging is used, there may be null entries in the array - this is to support paging even when filtering out chats
   * that have become inaccessible due to the organisation scope of the therapist being changed away from those patients.  To page results,
   * keep retrieving pages until the length of the array is less than the specified limit.  The nulls ensure that this can be relied upon,
   * even when filtering patients.
   * @param params The `TherapistChatService.TherapistChatsListChatsParams` containing the following parameters:
   *
   * - `skip`: how far to page into the results.
   *
   * - `pubNub`: Flag to determine type of the chat.
   *
   * - `limit`: the amount of chats to return in a single request
   *
   * @return Ok
   */
  TherapistChatsListChatsResponse(params: TherapistChatService.TherapistChatsListChatsParams): __Observable<__StrictHttpResponse<Array<TubChatSession>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.pubNub != null) __params = __params.set('pubNub', params.pubNub.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/chats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubChatSession>>;
      })
    );
  }
  /**
   * List all active chats for the authenticated therapist.
   *
   * List all active chats for the authenticated therapist.  Supports optional paging.
   * Please note that if paging is used, there may be null entries in the array - this is to support paging even when filtering out chats
   * that have become inaccessible due to the organisation scope of the therapist being changed away from those patients.  To page results,
   * keep retrieving pages until the length of the array is less than the specified limit.  The nulls ensure that this can be relied upon,
   * even when filtering patients.
   * @param params The `TherapistChatService.TherapistChatsListChatsParams` containing the following parameters:
   *
   * - `skip`: how far to page into the results.
   *
   * - `pubNub`: Flag to determine type of the chat.
   *
   * - `limit`: the amount of chats to return in a single request
   *
   * @return Ok
   */
  TherapistChatsListChats(params: TherapistChatService.TherapistChatsListChatsParams): __Observable<Array<TubChatSession>> {
    return this.TherapistChatsListChatsResponse(params).pipe(
      __map(_r => _r.body as Array<TubChatSession>)
    );
  }

  /**
   * Requesting new token to access therapist's chat
   * @param chatRoomId
   * @return Ok
   */
  TherapistChatsTokenResponse(chatRoomId?: string): __Observable<__StrictHttpResponse<PubNubToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (chatRoomId != null) __params = __params.set('chatRoomId', chatRoomId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/chats/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PubNubToken>;
      })
    );
  }
  /**
   * Requesting new token to access therapist's chat
   * @param chatRoomId
   * @return Ok
   */
  TherapistChatsToken(chatRoomId?: string): __Observable<PubNubToken> {
    return this.TherapistChatsTokenResponse(chatRoomId).pipe(
      __map(_r => _r.body as PubNubToken)
    );
  }

  /**
   * Destroys token issued for the therapist and prevents them to access chat channels
   * @param token token we want to remove
   * @return Ok
   */
  TherapistChatsTokenDeleteResponse(token: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/therapist/chats/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Destroys token issued for the therapist and prevents them to access chat channels
   * @param token token we want to remove
   * @return Ok
   */
  TherapistChatsTokenDelete(token: string): __Observable<boolean> {
    return this.TherapistChatsTokenDeleteResponse(token).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * gets PubNub unread message counters
   *
   * Returns PubNub's unread message counters for the given channel IDs for the authenticated user.  This includes a "last read time token"
   * for each channel.  Please note that channels which do not have a last read time token will not be included in the response. Similarly,
   * if PubNub does not provide an unread message count, they will also not be included in the response.
   * @param channelIds comma separated list of PubNub channel IDs to retrieve unread counters for
   * @return Ok
   */
  TherapistChatsPubNubUnreadMessagesResponse(channelIds?: string): __Observable<__StrictHttpResponse<TubPubNubLastReadChatsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (channelIds != null) __params = __params.set('channelIds', channelIds.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/chats/unread-messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubPubNubLastReadChatsResponse>;
      })
    );
  }
  /**
   * gets PubNub unread message counters
   *
   * Returns PubNub's unread message counters for the given channel IDs for the authenticated user.  This includes a "last read time token"
   * for each channel.  Please note that channels which do not have a last read time token will not be included in the response. Similarly,
   * if PubNub does not provide an unread message count, they will also not be included in the response.
   * @param channelIds comma separated list of PubNub channel IDs to retrieve unread counters for
   * @return Ok
   */
  TherapistChatsPubNubUnreadMessages(channelIds?: string): __Observable<TubPubNubLastReadChatsResponse> {
    return this.TherapistChatsPubNubUnreadMessagesResponse(channelIds).pipe(
      __map(_r => _r.body as TubPubNubLastReadChatsResponse)
    );
  }

  /**
   * Invite a therapist into a patient's chat.
   * @param params The `TherapistChatService.TherapistChatsInviteParams` containing the following parameters:
   *
   * - `therapistId`: Therapist firestore ID.
   *
   * - `patientId`: Patient firestore ID.
   *
   * - `message`: Message to be shown in the request to the therapist.
   */
  TherapistChatsInviteResponse(params: TherapistChatService.TherapistChatsInviteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.message;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/chats/${encodeURIComponent(String(params.patientId))}/invite/${encodeURIComponent(String(params.therapistId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Invite a therapist into a patient's chat.
   * @param params The `TherapistChatService.TherapistChatsInviteParams` containing the following parameters:
   *
   * - `therapistId`: Therapist firestore ID.
   *
   * - `patientId`: Patient firestore ID.
   *
   * - `message`: Message to be shown in the request to the therapist.
   */
  TherapistChatsInvite(params: TherapistChatService.TherapistChatsInviteParams): __Observable<null> {
    return this.TherapistChatsInviteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Invite a therapist into a patient's chat to take over as lead
   * @param params The `TherapistChatService.TherapistChatsHandoverParams` containing the following parameters:
   *
   * - `therapistId`: Therapist firestore ID.
   *
   * - `patientId`: Patient firestore ID.
   *
   * - `message`: Message to be shown in teh request to the therapist.
   */
  TherapistChatsHandoverResponse(params: TherapistChatService.TherapistChatsHandoverParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.message;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/chats/${encodeURIComponent(String(params.patientId))}/handover/${encodeURIComponent(String(params.therapistId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Invite a therapist into a patient's chat to take over as lead
   * @param params The `TherapistChatService.TherapistChatsHandoverParams` containing the following parameters:
   *
   * - `therapistId`: Therapist firestore ID.
   *
   * - `patientId`: Patient firestore ID.
   *
   * - `message`: Message to be shown in teh request to the therapist.
   */
  TherapistChatsHandover(params: TherapistChatService.TherapistChatsHandoverParams): __Observable<null> {
    return this.TherapistChatsHandoverResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * end a patients chat session.
   *
   * End a patients chat session if called by a lead therapist, otherwise an error is thrown
   * @param patientId Firestore ID of patient.
   */
  TherapistChatsEndChatResponse(patientId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/chats/${encodeURIComponent(String(patientId))}/end-chat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * end a patients chat session.
   *
   * End a patients chat session if called by a lead therapist, otherwise an error is thrown
   * @param patientId Firestore ID of patient.
   */
  TherapistChatsEndChat(patientId: string): __Observable<null> {
    return this.TherapistChatsEndChatResponse(patientId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * leave chat with patient.
   *
   * Used by therapists and nurses to leave the chat room.  They will be unable to rejoin unless invited.  Note that the
   * lead therapist on a patient is not able to leave the chat room, and must instead deliberately end (terminate) the
   * chat entirely, or hand it over to another therapist before leaving.
   * @param patientId Firestore ID of patient.
   */
  TherapistChatsLeaveChatResponse(patientId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/chats/${encodeURIComponent(String(patientId))}/leave-chat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * leave chat with patient.
   *
   * Used by therapists and nurses to leave the chat room.  They will be unable to rejoin unless invited.  Note that the
   * lead therapist on a patient is not able to leave the chat room, and must instead deliberately end (terminate) the
   * chat entirely, or hand it over to another therapist before leaving.
   * @param patientId Firestore ID of patient.
   */
  TherapistChatsLeaveChat(patientId: string): __Observable<null> {
    return this.TherapistChatsLeaveChatResponse(patientId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * claim lead status of given patient
   *
   * Given a patientId (or a patient's email), claim lead status of that patient for the authenticated user.  This will add the
   * authenticated user to the patient's chat room to ensure access to the realtime database channel, and will also remove the lead status
   * from the previous lead therapist. Note that the original therapist will not be removed from the chat, and will remain in the chat as a
   * non-lead therapist participant.
   * @param patientIdOrEmail the patient ID or the email address of the patient to claim the lead status for
   * @return Ok
   */
  TherapistChatsClaimLeadResponse(patientIdOrEmail: string): __Observable<__StrictHttpResponse<TubTherapistRequestHandlerResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/chats/${encodeURIComponent(String(patientIdOrEmail))}/claim-lead`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubTherapistRequestHandlerResponse>;
      })
    );
  }
  /**
   * claim lead status of given patient
   *
   * Given a patientId (or a patient's email), claim lead status of that patient for the authenticated user.  This will add the
   * authenticated user to the patient's chat room to ensure access to the realtime database channel, and will also remove the lead status
   * from the previous lead therapist. Note that the original therapist will not be removed from the chat, and will remain in the chat as a
   * non-lead therapist participant.
   * @param patientIdOrEmail the patient ID or the email address of the patient to claim the lead status for
   * @return Ok
   */
  TherapistChatsClaimLead(patientIdOrEmail: string): __Observable<TubTherapistRequestHandlerResponse> {
    return this.TherapistChatsClaimLeadResponse(patientIdOrEmail).pipe(
      __map(_r => _r.body as TubTherapistRequestHandlerResponse)
    );
  }

  /**
   * returns list of previously pinned chat sessions from therapist object.
   *
   * Returns list of chats marked for follow up. This may be used When a Therapist is working through
   * a backlog of patients and needs to follow up with user in future..
   * This allows them to return the list of previously pinned chat sessionss from therapist object.
   * @param params The `TherapistChatService.TherapistChatsListPinnedChatsParams` containing the following parameters:
   *
   * - `skip`:
   *
   * - `limit`:
   *
   * @return Ok
   */
  TherapistChatsListPinnedChatsResponse(params: TherapistChatService.TherapistChatsListPinnedChatsParams): __Observable<__StrictHttpResponse<Array<TubChatSessionV2>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/chats/pinned`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubChatSessionV2>>;
      })
    );
  }
  /**
   * returns list of previously pinned chat sessions from therapist object.
   *
   * Returns list of chats marked for follow up. This may be used When a Therapist is working through
   * a backlog of patients and needs to follow up with user in future..
   * This allows them to return the list of previously pinned chat sessionss from therapist object.
   * @param params The `TherapistChatService.TherapistChatsListPinnedChatsParams` containing the following parameters:
   *
   * - `skip`:
   *
   * - `limit`:
   *
   * @return Ok
   */
  TherapistChatsListPinnedChats(params: TherapistChatService.TherapistChatsListPinnedChatsParams): __Observable<Array<TubChatSessionV2>> {
    return this.TherapistChatsListPinnedChatsResponse(params).pipe(
      __map(_r => _r.body as Array<TubChatSessionV2>)
    );
  }

  /**
   * pin chat session by adding patients ref to pinned list on therapist object.
   *
   * When a Therapist is working through a backlog of patients, they may wish to mark a patients chat session to
   * return to in a few days time, for example if pending a response from the user or confirmation another Therapist
   * has taken the patient on board.
   * This allows them to ADD a chat session to their pinned list on their therapist object.
   * @param patientId the patient ID to add
   */
  TherapistChatsPinChatSessionResponse(patientId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/chats/${encodeURIComponent(String(patientId))}/action/pin-chat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * pin chat session by adding patients ref to pinned list on therapist object.
   *
   * When a Therapist is working through a backlog of patients, they may wish to mark a patients chat session to
   * return to in a few days time, for example if pending a response from the user or confirmation another Therapist
   * has taken the patient on board.
   * This allows them to ADD a chat session to their pinned list on their therapist object.
   * @param patientId the patient ID to add
   */
  TherapistChatsPinChatSession(patientId: string): __Observable<null> {
    return this.TherapistChatsPinChatSessionResponse(patientId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * unpin chat ession by removing patients ref from pinned list on therapist object.
   *
   * When a Therapist is working through a backlog of patients, they may wish to remove a pinned patients chat session from their list.
   * This allows them to REMOVE a previously pinned chat session from the list on their therapist object.
   * @param patientId the patient ID to remove
   */
  TherapistChatsUnpinChatSessionResponse(patientId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/chats/${encodeURIComponent(String(patientId))}/action/unpin-chat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * unpin chat ession by removing patients ref from pinned list on therapist object.
   *
   * When a Therapist is working through a backlog of patients, they may wish to remove a pinned patients chat session from their list.
   * This allows them to REMOVE a previously pinned chat session from the list on their therapist object.
   * @param patientId the patient ID to remove
   */
  TherapistChatsUnpinChatSession(patientId: string): __Observable<null> {
    return this.TherapistChatsUnpinChatSessionResponse(patientId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List all active chats for the authenticated therapist.
   *
   * List all active chats for the authenticated therapist.  Supports optional paging.
   * Please note that if paging is used, there may be null entries in the array - this is to support paging even when filtering out chats
   * that have become inaccessible due to the organisation scope of the therapist being changed away from those patients.  To page results,
   * keep retrieving pages until the length of the array is less than the specified limit.  The nulls ensure that this can be relied upon,
   * even when filtering patients.
   * @param params The `TherapistChatService.TherapistChatsControllerV2ListChatsParams` containing the following parameters:
   *
   * - `skip`: how far to page into the results.
   *
   * - `pubNub`: Flag to determine type of the chat.
   *
   * - `limit`: the amount of chats to return in a single request
   *
   * @return Ok
   */
  TherapistChatsControllerV2ListChatsResponse(params: TherapistChatService.TherapistChatsControllerV2ListChatsParams): __Observable<__StrictHttpResponse<Array<TubChatSessionV2>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.pubNub != null) __params = __params.set('pubNub', params.pubNub.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/v2/chats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubChatSessionV2>>;
      })
    );
  }
  /**
   * List all active chats for the authenticated therapist.
   *
   * List all active chats for the authenticated therapist.  Supports optional paging.
   * Please note that if paging is used, there may be null entries in the array - this is to support paging even when filtering out chats
   * that have become inaccessible due to the organisation scope of the therapist being changed away from those patients.  To page results,
   * keep retrieving pages until the length of the array is less than the specified limit.  The nulls ensure that this can be relied upon,
   * even when filtering patients.
   * @param params The `TherapistChatService.TherapistChatsControllerV2ListChatsParams` containing the following parameters:
   *
   * - `skip`: how far to page into the results.
   *
   * - `pubNub`: Flag to determine type of the chat.
   *
   * - `limit`: the amount of chats to return in a single request
   *
   * @return Ok
   */
  TherapistChatsControllerV2ListChats(params: TherapistChatService.TherapistChatsControllerV2ListChatsParams): __Observable<Array<TubChatSessionV2>> {
    return this.TherapistChatsControllerV2ListChatsResponse(params).pipe(
      __map(_r => _r.body as Array<TubChatSessionV2>)
    );
  }

  /**
   * Removes stale cache entries for therapist
   *
   * Loops through the notification cache and removes any entries where the therapist no longer has access, there is no
   * patient in the chat, or the chat is not a Firebase chat session.
   */
  TherapistChatsControllerV2TidyTherapistNotificationCacheResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/v2/chats/action/tidy-therapist-notification-cache`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Removes stale cache entries for therapist
   *
   * Loops through the notification cache and removes any entries where the therapist no longer has access, there is no
   * patient in the chat, or the chat is not a Firebase chat session.
   */
  TherapistChatsControllerV2TidyTherapistNotificationCache(): __Observable<null> {
    return this.TherapistChatsControllerV2TidyTherapistNotificationCacheResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List all active chat information for specific chat session.
   * @param chatSessionId
   * @return Ok
   */
  TherapistChatsControllerV2GetChatResponse(chatSessionId: string): __Observable<__StrictHttpResponse<TubChatSessionV2>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/v2/chats/${encodeURIComponent(String(chatSessionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubChatSessionV2>;
      })
    );
  }
  /**
   * List all active chat information for specific chat session.
   * @param chatSessionId
   * @return Ok
   */
  TherapistChatsControllerV2GetChat(chatSessionId: string): __Observable<TubChatSessionV2> {
    return this.TherapistChatsControllerV2GetChatResponse(chatSessionId).pipe(
      __map(_r => _r.body as TubChatSessionV2)
    );
  }

  /**
   * List a summary of active chat information for specific chat session.
   * @param chatSessionId The chat session id to get the summary for.
   * @return Ok
   */
  TherapistChatsControllerV2GetChatSummaryResponse(chatSessionId: string): __Observable<__StrictHttpResponse<TubChatSessionV2Summary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/v2/chats/${encodeURIComponent(String(chatSessionId))}/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubChatSessionV2Summary>;
      })
    );
  }
  /**
   * List a summary of active chat information for specific chat session.
   * @param chatSessionId The chat session id to get the summary for.
   * @return Ok
   */
  TherapistChatsControllerV2GetChatSummary(chatSessionId: string): __Observable<TubChatSessionV2Summary> {
    return this.TherapistChatsControllerV2GetChatSummaryResponse(chatSessionId).pipe(
      __map(_r => _r.body as TubChatSessionV2Summary)
    );
  }

  /**
   * gets details about users within chat session
   *
   * Retrieve a representation of the users presence for each user in the given chat session,
   * added to retrieve list of participants in PubNub V2 chats, however it will work for V1 chats too.
   * @param chatSessionId id of chat session for which to extract user information for
   * @return Ok
   */
  TherapistChatsControllerV2GetChatParticipantsResponse(chatSessionId: string): __Observable<__StrictHttpResponse<Array<TubChatUserPresenceInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/v2/chats/${encodeURIComponent(String(chatSessionId))}/participants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubChatUserPresenceInfo>>;
      })
    );
  }
  /**
   * gets details about users within chat session
   *
   * Retrieve a representation of the users presence for each user in the given chat session,
   * added to retrieve list of participants in PubNub V2 chats, however it will work for V1 chats too.
   * @param chatSessionId id of chat session for which to extract user information for
   * @return Ok
   */
  TherapistChatsControllerV2GetChatParticipants(chatSessionId: string): __Observable<Array<TubChatUserPresenceInfo>> {
    return this.TherapistChatsControllerV2GetChatParticipantsResponse(chatSessionId).pipe(
      __map(_r => _r.body as Array<TubChatUserPresenceInfo>)
    );
  }
}

module TherapistChatService {

  /**
   * Parameters for TherapistChatsListChats
   */
  export interface TherapistChatsListChatsParams {

    /**
     * how far to page into the results.
     */
    skip?: number;

    /**
     * Flag to determine type of the chat.
     */
    pubNub?: boolean;

    /**
     * the amount of chats to return in a single request
     */
    limit?: number;
  }

  /**
   * Parameters for TherapistChatsInvite
   */
  export interface TherapistChatsInviteParams {

    /**
     * Therapist firestore ID.
     */
    therapistId: string;

    /**
     * Patient firestore ID.
     */
    patientId: string;

    /**
     * Message to be shown in the request to the therapist.
     */
    message: TubChatRequestMessage;
  }

  /**
   * Parameters for TherapistChatsHandover
   */
  export interface TherapistChatsHandoverParams {

    /**
     * Therapist firestore ID.
     */
    therapistId: string;

    /**
     * Patient firestore ID.
     */
    patientId: string;

    /**
     * Message to be shown in teh request to the therapist.
     */
    message: TubChatRequestMessage;
  }

  /**
   * Parameters for TherapistChatsListPinnedChats
   */
  export interface TherapistChatsListPinnedChatsParams {
    skip?: number;
    limit?: number;
  }

  /**
   * Parameters for TherapistChatsControllerV2ListChats
   */
  export interface TherapistChatsControllerV2ListChatsParams {

    /**
     * how far to page into the results.
     */
    skip?: number;

    /**
     * Flag to determine type of the chat.
     */
    pubNub?: boolean;

    /**
     * the amount of chats to return in a single request
     */
    limit?: number;
  }
}

export { TherapistChatService }
