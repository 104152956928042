import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';

import { TubPatient, TubHasActiveChat } from '@backend-client/models';
import { PatientsService } from '@backend-client/services/patients.service';

@Injectable({
  providedIn: 'root'
})
export class PatientProfileService {

  constructor(private patientsService: PatientsService) {}

  public async getPatientInformation(patientId: string): Promise<TubPatient> {
      return await firstValueFrom(this.patientsService.PatientsGetPatient(patientId));
  }

  public async getPatientChatInformation(patientId: string): Promise<TubHasActiveChat> {
      return await firstValueFrom(this.patientsService.PatientsGetPatientChatData(patientId));
  }

  public async sharePatientRecord(patientId: string, therapistId: string): Promise<void> {
      await firstValueFrom(this.patientsService.PatientsSharePatientDataWithTherapist({patientId, therapistId}));
  }
}
