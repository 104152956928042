// go-chat-duplication
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-go-connection-lost-to-chat-session-dialog',
    templateUrl: './go-connection-lost-to-chat-session-dialog.component.html',
    styleUrls: ['./go-connection-lost-to-chat-session-dialog.component.scss'],
    standalone: false
})
export class GoConnectionLostToChatSessionDialogComponent {

  @ViewChild('dialog') dialog;

  constructor(@Inject(MAT_DIALOG_DATA) public chatSessionId: string,
    private dialogRef: MatDialogRef<GoConnectionLostToChatSessionDialogComponent>) { }

  public async confirmButtonClicked(): Promise<void> {
    await this.dialog.showAsyncProgressForFunction(async () => {
    });
    this.dialogRef.close();
  }

}
