import { Component } from '@angular/core';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { AppointmentMoment } from './appointment-moment';
import { MomentService } from '../moment.service';

@Component({
    selector: 'app-appointment-moment',
    templateUrl: './appointment-moment.component.html',
    styleUrls: ['./appointment-moment.component.scss'],
    standalone: false
})
export class AppointmentMomentComponent {
  public momentData: AppointmentMoment;
  public featureFlags$ = this.featureFlagService.featureFlags$;

  constructor(
    public momentService: MomentService,
    private featureFlagService: FeatureFlagService
  ) {}
}
