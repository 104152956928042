import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';

import { passwordValidatorsAnimation } from './password-strength-animations';
import { Criteria } from './password-strength-enums';
import { PasswordStrengthValidator, RegExpValidator } from './password-strength-validators';

@Component({
    selector: 'app-password-strength',
    templateUrl: './password-strength.component.html',
    styleUrls: ['./password-strength.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false,
    animations: [passwordValidatorsAnimation]
})
export class PasswordStrengthComponent implements OnInit, OnChanges {
  @Input() password: string;
  @Input() externalError: boolean;

  @Input() enableLengthRule = true;
  @Input() enableLowerCaseLetterRule = true;
  @Input() enableUpperCaseLetterRule = true;
  @Input() enableDigitRule = true;
  @Input() enableSpecialCharRule = true;

  @Input() min = 12;
  @Input() max = 30;
  @Input() customValidator: RegExp;
  @Input() displayPasswordStrength = false;
  @Input() displayPasswordStrengthPercentage = false;

  @Input() warnThreshold = 21;
  @Input() accentThreshold = 81;

  @Output() strengthChanged = new EventEmitter<number>();

  public containAtLeastMinChars: boolean;
  public containAtLeastOneLowerCaseLetter: boolean;
  public containAtLeastOneUpperCaseLetter: boolean;
  public containAtLeastOneDigit: boolean;
  public containAtLeastOneSpecialChar: boolean;
  public containAtCustomChars: boolean;

  public lowerCaseCriteriaMsg = 'Must contain one lowercase letter';
  public upperCaseCriteriaMsg = 'Must contain one uppercase letter';
  public digitsCriteriaMsg = 'Must contain one number';
  public specialCharsCriteriaMsg = 'Must contain one symbol';
  public customCharsCriteriaMsg = 'Must contain one custom character';
  public minCharsCriteriaMsg = `Must be at least ${this.min} characters long`;

  private criteriaMap = new Map<Criteria, RegExp>();
  private passwordStrengthValidator = new PasswordStrengthValidator();

  private passwordFormControl: UntypedFormControl = new UntypedFormControl();
  private passwordConfirmationFormControl: UntypedFormControl = new UntypedFormControl();

  private validatorsArray: ValidatorFn[] = [];
  private validators: ValidatorFn;

  private _strength = 0;
  get strength(): number {
    return this._strength ? this._strength : 0;
  }

  ngOnInit(): void {
    this.setRulesAndValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.externalError && changes.externalError.firstChange) || (changes.password && changes.password.firstChange)) {
      return;
    } else if (changes.externalError && changes.externalError.currentValue) {
      this.externalError = true;
      return;
    } else if (changes.password && changes.password.previousValue === changes.password.currentValue && !changes.password.firstChange) {
      this.calculatePasswordStrength();
    } else {
      if (this.password && this.password.length > 0) {
        this.calculatePasswordStrength();
      } else {
        this.reset();
      }
    }
  }

  private parseCustomValidatorsRegex(): RegExp {
    if (this.customValidator instanceof RegExp) {
      return this.customValidator;
    } else if (typeof this.customValidator === 'string') {
      return RegExp(this.customValidator);
    }
  }

  private setRulesAndValidators(): void {
    this.validatorsArray = [];
    this.criteriaMap = new Map<Criteria, RegExp>();
    this.passwordConfirmationFormControl
      .setValidators(Validators.compose([
        Validators.required, this.passwordStrengthValidator.confirm(this.password)
      ]));
    this.validatorsArray.push(Validators.required);
    if (this.enableLengthRule) {
      this.criteriaMap.set(Criteria.at_least_eight_chars, RegExp(`^.{${this.min},${this.max}}$`));
      this.validatorsArray.push(Validators.minLength(this.min));
      this.validatorsArray.push(Validators.maxLength(this.max));
    }
    if (this.enableLowerCaseLetterRule) {
      this.criteriaMap.set(Criteria.at_least_one_lower_case_char, RegExpValidator.lowerCase);
      this.validatorsArray.push(Validators.pattern(RegExpValidator.lowerCase));
    }
    if (this.enableUpperCaseLetterRule) {
      this.criteriaMap.set(Criteria.at_least_one_upper_case_char, RegExpValidator.upperCase);
      this.validatorsArray.push(Validators.pattern(RegExpValidator.upperCase));
    }
    if (this.enableDigitRule) {
      this.criteriaMap.set(Criteria.at_least_one_digit_char, RegExpValidator.digit);
      this.validatorsArray.push(Validators.pattern(RegExpValidator.digit));
    }
    if (this.enableSpecialCharRule) {
      this.criteriaMap.set(Criteria.at_least_one_special_char, RegExpValidator.specialChar);
      this.validatorsArray.push(Validators.pattern(RegExpValidator.specialChar));
    }
    if (this.customValidator) {
      this.criteriaMap.set(Criteria.at_custom_chars, this.parseCustomValidatorsRegex());
      this.validatorsArray.push(Validators.pattern(this.parseCustomValidatorsRegex()));
    }

    this.criteriaMap.forEach((value: any, key: string) => {
      this.validatorsArray.push(this.passwordStrengthValidator.validate(key, value));
    });

    this.passwordFormControl.setValidators(Validators.compose([...this.validatorsArray]));
    this.validators = Validators.compose([...this.validatorsArray]);
  }

  private calculatePasswordStrength(): void {
    const requirements: boolean[] = [];
    const unit = 100 / this.criteriaMap.size;

    requirements.push(
      this.enableLengthRule ? this._containAtLeastMinChars() : false,
      this.enableLowerCaseLetterRule ? this._containAtLeastOneLowerCaseLetter() : false,
      this.enableUpperCaseLetterRule ? this._containAtLeastOneUpperCaseLetter() : false,
      this.enableDigitRule ? this._containAtLeastOneDigit() : false,
      this.enableSpecialCharRule ? this._containAtLeastOneSpecialChar() : false,
      this.customValidator ? this._containCustomChars() : false
    );

    this._strength = requirements.filter(v => v).length * unit;
    this.strengthChanged.emit(this.strength);
    this.setRulesAndValidators();
  }

  private reset(): void {
    this._strength = 0;
    this.containAtLeastMinChars = false;
    this.containAtLeastOneLowerCaseLetter = false;
    this.containAtLeastOneUpperCaseLetter = false;
    this.containAtLeastOneDigit = false;
    this.containAtCustomChars = false;
    this.containAtLeastOneSpecialChar = false;
  }

  private _containAtLeastMinChars(): boolean {
    this.containAtLeastMinChars = this.password.length >= this.min;
    return this.containAtLeastMinChars;
  }

  private _containAtLeastOneLowerCaseLetter(): boolean {
    this.containAtLeastOneLowerCaseLetter = this.criteriaMap.get(Criteria.at_least_one_lower_case_char).test(this.password);
    return this.containAtLeastOneLowerCaseLetter;
  }

  private _containAtLeastOneUpperCaseLetter(): boolean {
    this.containAtLeastOneUpperCaseLetter = this.criteriaMap.get(Criteria.at_least_one_upper_case_char).test(this.password);
    return this.containAtLeastOneUpperCaseLetter;
  }

  private _containAtLeastOneDigit(): boolean {
    this.containAtLeastOneDigit = this.criteriaMap.get(Criteria.at_least_one_digit_char).test(this.password);
    return this.containAtLeastOneDigit;
  }

  private _containAtLeastOneSpecialChar(): boolean {
    this.containAtLeastOneSpecialChar = this.criteriaMap.get(Criteria.at_least_one_special_char).test(this.password);
    return this.containAtLeastOneSpecialChar;
  }

  private _containCustomChars(): boolean {
    this.containAtCustomChars = this.criteriaMap.get(Criteria.at_custom_chars).test(this.password);
    return this.containAtCustomChars;
  }
}
