import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { takeUntil } from 'rxjs/operators';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { OnDestroyObservable } from '@app/classes/on-destroy-observable';
import { TherapistStatusService } from '@shared/services/therapist-status/therapist-status.service';

@Component({
    selector: 'app-therapist-status',
    templateUrl: './therapist-status.component.html',
    styleUrls: ['./therapist-status.component.scss'],
    standalone: true,
    imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, MatSlideToggleModule, MatIconModule],
    providers: [TherapistStatusService]
})
export class TherapistStatusComponent extends OnDestroyObservable implements OnInit {

  public therapistOnlineStatus = new FormControl<boolean>(true);

  constructor(private therapistStatusService: TherapistStatusService) {
    super();
  }

  ngOnInit(): void {
    // TODO: get this value using Therapist Status Service to handle call to TUB

    this.therapistOnlineStatus.valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(value => {
      // TODO: send this off to the Therapist Status Service to handle call to TUB
      this.therapistStatusService.markTherapistAsOnline(value);
    });
  }

 }
