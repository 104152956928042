<app-main-nav></app-main-nav>

<div fxLayout="column" class="buttons-container">
  <div *ngIf="mediaObserver.isActive('gt-xs')" class="fix-menu-width">
    <button mat-icon-button [class.spin]="(mainNavService.mainNavOpen$ | async)" (click)="fixSideNavOpen(); $event.preventDefault();">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

  <button mat-flat-button (click)="signOutButtonClicked()">
    <mat-icon>logout</mat-icon>
    <span [@animateText]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'show' : 'hide'">
      Logout
    </span>
  </button>
</div>
