import { DashboardSoftDeletedTubMoment } from '@backend-client/models/dashboard-soft-deleted-tub-moment';
import { Moment, AppointmentMomentLinkConnectionType, AppointmentMomentPosition, EditedMomentData } from '../moment';
import { MomentType } from '../moment-type';

export class FileMoment extends Moment {

  public fileName: string;
  public createdByUserId: number;
  public edited: EditedMomentData;
  public appointmentRef: string = undefined;
  public appointmentMomentPosition: AppointmentMomentPosition = null;
  readonly typeName = MomentType.File;

  public get displayAppointmentConnectors(): AppointmentMomentLinkConnectionType {
    if (this.appointmentRef) {
      if (this.appointmentMomentPosition === AppointmentMomentPosition.start) {
        return AppointmentMomentLinkConnectionType.StartLink;
      }

      if (this.appointmentMomentPosition === AppointmentMomentPosition.last) {
        return AppointmentMomentLinkConnectionType.EndLink;
      }

      return AppointmentMomentLinkConnectionType.MiddleLink;
    }
  }

  constructor(options: {
    id: string,
    timestamp: Date,
    fileName: string,
    createdByUserId: number,
    author: string,
    deleted?: DashboardSoftDeletedTubMoment,
    edited?: EditedMomentData,
    appointmentRef?: string,
    appointmentMomentPosition?: AppointmentMomentPosition,
   }) {
    super(options.id, options.timestamp, options.author, options.deleted);
    this.fileName = options.fileName;
    this.createdByUserId = options.createdByUserId;
    this.edited = options.edited;
    this.appointmentRef = options.appointmentRef;
    this.appointmentMomentPosition = options.appointmentMomentPosition;
  }
}
