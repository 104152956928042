import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { TimelineService } from '../../../timeline.service';
import { HelperService } from '@app/modules/shared/services/helper.service';

@Component({
  selector: 'app-expand-note-moment-dialog',
  templateUrl: './expand-note-moment-dialog.component.html',
  styleUrls: ['./expand-note-moment-dialog.component.scss'],
  standalone: false
})

export class ExpandNoteMomentDialogComponent implements AfterViewInit {
  @ViewChild('dialog') dialog: DialogComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; subtitle: string; note: string; timelineService: TimelineService; },
    private dialogRef: MatDialogRef<ExpandNoteMomentDialogComponent>,
    private helperService: HelperService
  ) { }

  async ngAfterViewInit(): Promise<void> {
    await this.helperService.delay(400);
    this.toggleTimelinePanelAfterAddingNote(false);
  }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
    this.toggleTimelinePanelAfterAddingNote(true);
  }

  private toggleTimelinePanelAfterAddingNote(isOpen: boolean): void {
    if (isOpen) {
      this.data.timelineService.patientTimelinePanelOpen$.next(true);
      this.data.timelineService.patientTimelineVisible$.next(true);
    } else {
      this.data.timelineService.patientTimelinePanelOpen$.next(false);
    }
  }
}
