<div *ngIf="linkConnectionType === AppointmentMomentLinkConnectionType.EndLink || linkConnectionType === AppointmentMomentLinkConnectionType.MiddleLink" class="link end-link" [ngStyle]="{'border-color' : borderColorHexVal}">
  <ng-container *ngTemplateOutlet="linkBlipLast"></ng-container>
</div>
<div *ngIf="linkConnectionType === AppointmentMomentLinkConnectionType.StartLink || linkConnectionType === AppointmentMomentLinkConnectionType.MiddleLink" class="link start-link" [ngStyle]="{'border-color' : borderColorHexVal}">
  <ng-container *ngTemplateOutlet="linkBlipFirst"></ng-container>
</div>

<ng-template #linkBlipFirst>
  <mat-icon *ngIf="momentsMissingAboveIndicator" class="moments-missing-indicator above" [ngStyle]="{'color' : borderColorHexVal}">fiber_manual_record</mat-icon>
</ng-template>
<ng-template #linkBlipLast>
  <mat-icon *ngIf="momentsMissingBelowIndicator" class="moments-missing-indicator below" [class.last]="isLastVisibleInGroup" [ngStyle]="{'color' : borderColorHexVal}">fiber_manual_record</mat-icon>
</ng-template>
