<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
  <div id="title-container">
    <img src="assets/images/secondary-thrive-mental-wellbeing-logo.webp" width="245" height="50" class="only-on-light" alt="Thrive Mental Wellbeing" />
    <img src="assets/images/secondary-thrive-mental-wellbeing-logo-white.webp" width="245" height="50" class="only-on-dark" alt="Thrive Mental Wellbeing" />
  </div>
</mat-toolbar>

<mat-progress-bar *ngIf="routerResolveStatus.IsLoading" mode="indeterminate" class="warn"></mat-progress-bar>

<router-outlet></router-outlet>
