import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MessageService } from '@shared/services/message.service';
import { TimelineService } from '../timeline.service';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { DashboardTubMoment } from '@backend-client/models';
import { MomentType } from '../moment/moment-type';

@Component({
    selector: 'app-add-note-moment',
    templateUrl: './add-note-moment.component.html',
    styleUrls: ['./add-note-moment.component.scss'],
    standalone: false
})
export class AddNoteMomentComponent implements OnInit {

  @ViewChild('dialog') dialog: DialogComponent;
  public previousNote: DashboardTubMoment;

  constructor(private dialogRef: MatDialogRef<AddNoteMomentComponent>,
              private messageService: MessageService,
              @Inject(MAT_DIALOG_DATA) private data: { timelineService: TimelineService, patientId: string }) { }

  async ngOnInit(): Promise<void> {
    this.previousNote = (await this.data.timelineService.getLatestNoteMoment(this.data.patientId))[0];
    this.toggleTimelinePanelAfterAddingNote(false);
  }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
    this.toggleTimelinePanelAfterAddingNote(true);
  }

  public async addMomentButtonClicked(noteText: string): Promise<void> {
    try {
      await this.dialog.showAsyncProgressForFunction(async () => {
        await this.data.timelineService.addNoteMoment(this.data.patientId, noteText);
        this.data.timelineService.ensureTypeSelectedOnFilterOptions(MomentType.Note);
        this.data.timelineService.updateTimelineWithFilterOptions();
      });
      this.dialogRef.close(true);
      this.toggleTimelinePanelAfterAddingNote(true);
    } catch (err) {
      console.error(err);
      this.messageService.showMessage('Error: ' + err.toString());
    }
  }

  private toggleTimelinePanelAfterAddingNote(isOpen: boolean): void {
    if (isOpen) {
      this.data.timelineService.patientTimelinePanelOpen$.next(true);
      this.data.timelineService.patientTimelineVisible$.next(true);
    } else {
      this.data.timelineService.patientTimelinePanelOpen$.next(false);
    }
  }
}
