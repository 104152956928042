import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TubMomentDescriptionAuditData } from '@backend-client/models';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { TimelineService } from '../../../timeline.service';

@Component({
    selector: 'app-google-file-moment-description-history',
    templateUrl: './google-file-moment-description-history.component.html',
    styleUrls: ['./google-file-moment-description-history.component.scss'],
    standalone: false
})
export class GoogleFileMomentDescriptionHistoryComponent implements OnInit {
  @ViewChild('dialog') dialog: DialogComponent;
  public auditHistory: TubMomentDescriptionAuditData;
  public loading = true;

  constructor(
    private dialogRef: MatDialogRef<GoogleFileMomentDescriptionHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { timelineService: TimelineService; patientId: string; momentId: string },
  ) {}

  ngOnInit() {
    this.data.timelineService
      .getPatientMomentDescriptionAuditHistory(this.data.patientId, this.data.momentId)
      .then((response: TubMomentDescriptionAuditData) => {
        this.auditHistory = response;
        this.loading = false;
      });
  }

  public closeButtonClicked(): void {
    this.dialogRef.close();
  }
}
