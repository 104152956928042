import { Component } from '@angular/core';

@Component({
    selector: 'app-usbauthenticator',
    templateUrl: './usbauthenticator.component.html',
    styleUrls: ['./usbauthenticator.component.scss'],
    standalone: false
})
export class USBAuthenticatorComponent {

  constructor() { }

}
