import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

import { TubMomentDescriptionResponseDto } from '@backend-client/models';
import { ETagged } from '@classes/tagged-model';
import { MessageService } from '@shared/services/message.service';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { TimelineService } from '../../../timeline.service';
import { MomentType } from '../../moment-type';

@Component({
    selector: 'app-add-edit-google-file-moment-description',
    templateUrl: './add-edit-google-file-moment-description.component.html',
    styleUrls: ['./add-edit-google-file-moment-description.component.scss'],
    standalone: false
})
export class AddEditGoogleFileMomentDescriptionComponent implements OnInit {
  @ViewChild('dialog') dialog: DialogComponent;
  public descriptionFormControl = new FormControl('', [Validators.required]);
  public fetchingData = false;
  public placeholderText: string;

  private eTag: string;

  constructor(
    private dialogRef: MatDialogRef<AddEditGoogleFileMomentDescriptionComponent>,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA)
    public data: { timelineService: TimelineService; patientId: string; momentId: string; description?: string }
  ) {}

  ngOnInit() {
    if (this.data.description) {
      this.fetchingData = true;
      this.descriptionFormControl.disable();
      this.data.timelineService
        .getPatientMomentDescription(this.data.patientId, this.data.momentId)
        .then((response: ETagged<TubMomentDescriptionResponseDto>) => {
          this.eTag = response.ETag;
          this.descriptionFormControl.setValue(response.text);
          this.descriptionFormControl.enable();
          this.fetchingData = false;
        });
    } else {
      this.placeholderText = 'Enter your description here...';
    }
  }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

  public async addDescriptionButtonClicked(): Promise<void> {
    try {
      await this.dialog.showAsyncProgressForFunction(async () => {
        this.descriptionFormControl.disable();
        await this.data.timelineService.updatePatientMomentDescription(
          this.data.patientId,
          this.data.momentId,
          this.descriptionFormControl.value,
          this.eTag,
        );
        this.data.timelineService.ensureTypeSelectedOnFilterOptions(MomentType.GoogleFile);
        this.data.timelineService.updateTimelineWithFilterOptions();
      });
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      let message = '';
      switch(err.status) {
        case 400:
          message = 'Description was updated by another Therapist, please retry';
        break;
        case 403:
          message = `You no longer have access to this patient or moment, please reload your patient's list`;
        break;
        case 404:
          message = `Not found, please retry if you continue to see this error contact support`;
        break;
        default:
          message = err.message;
        break;
      }

      this.messageService.showMessage('Error: ' + message);
      this.descriptionFormControl.enable();
      this.data.timelineService.ensureTypeSelectedOnFilterOptions(MomentType.GoogleFile);
      this.data.timelineService.updateTimelineWithFilterOptions();
      this.dialogRef.close();
    }
  }
}
