import { DashboardSoftDeletedTubMoment } from '@backend-client/models/dashboard-soft-deleted-tub-moment';
import { Moment, AppointmentMomentLinkConnectionType, AppointmentMomentPosition, EditedMomentData } from '../moment';
import { MomentType } from '../moment-type';

export class CgiScoreMoment extends Moment {
  readonly typeName = MomentType.CgiScore;
  public cgiSeverity: number;
  public cgiImprovement: number;
  public edited: EditedMomentData;
  public appointmentRef: string = undefined;
  public appointmentMomentPosition: AppointmentMomentPosition = null;

  public get displayAppointmentConnectors(): AppointmentMomentLinkConnectionType {
    if (this.appointmentRef) {
      if (this.appointmentMomentPosition === AppointmentMomentPosition.start) {
        return AppointmentMomentLinkConnectionType.StartLink;
      }

      if (this.appointmentMomentPosition === AppointmentMomentPosition.last) {
        return AppointmentMomentLinkConnectionType.EndLink;
      }

      return AppointmentMomentLinkConnectionType.MiddleLink;
    }
  }

  constructor(options: {
    id: string,
    timestamp: Date,
    createdByUserId: number,
    author: string,
    cgiSeverity: number,
    cgiImprovement: number,
    deleted?: DashboardSoftDeletedTubMoment,
    edited?: EditedMomentData,
    appointmentRef?: string,
    appointmentMomentPosition?: AppointmentMomentPosition,
  }) {
    super(options.id, options.timestamp, options.author, options.deleted);
    this.cgiSeverity = options.cgiSeverity;
    this.cgiImprovement = options.cgiImprovement;
    this.edited = options.edited;
    this.appointmentRef = options.appointmentRef;
    this.appointmentMomentPosition = options.appointmentMomentPosition;
  }
}
