export const CGI_Severity: string = `This rating is based on observed and reported symptoms, behaviour, and function in the past seven days. Clearly, symptoms and behaviour can fluctuate over a week; the score should reflect the average severity level across the seven days. The CGI-Severity (CGI-S) asks the clinician one question:`;
export const CGI_Severity_Question: string = `"Considering your total clinical experience with this particular population, how mentally unwell is the client at this time?"`;
export const CGI_Severity_Hint: string = `which is rated on the following seven-point scale:`;

export const CGI_Severity_Answers = {
  1: 'Normal, not at all ill',
  2: 'Borderline unwell',
  3: 'Mildly unwell',
  4: 'Moderately unwell',
  5: 'Markedly unwell',
  6: 'Severely unwell',
  7: 'Among the most extremely unwell clients'
};

export const CGI_Improvement: string = `The CGI-Improvement (CGI-I) is similarly simple in its format. Each time the client is seen after therapy has been initiated, the clinician compares the client's overall clinical condition to the one-week period just prior to the start of therapy (the so-called baseline visit). The CGI-S score obtained at the baseline (initiation) visit serves as a good basis for making this assessment. Again, only the following one query is rated on a seven-point scale:`;
export const CGI_Improvement_Question: string = `“Compared to the client's condition at the start of therapy, this client's condition is”:`;

export const CGI_Improvement_Answers = {
  1: 'Very much improved since the initiation of therapy',
  2: 'Much improved',
  3: 'Minimally improved',
  4: 'No change from baseline (the initiation of therapy)',
  5: 'Minimally worse',
  6: 'Much worse',
  7: 'Very much worse since the initiation of therapy'
};
