import { Pipe, PipeTransform } from '@angular/core';
  /**
   * No longer extends ngx-pipes as it is no longer maintained
   */
@Pipe({
    name: 'timeAgoExtended',
    standalone: false
})
export class TimeAgoExtendedPipe implements PipeTransform {
  private YEAR_MS: number = 1000 * 60 * 60 * 24 * 7 * 4 * 12;
  private MAPPER = [
    { single: 'last year', many: 'years', div: 1 },
    { single: 'last month', many: 'months', div: 12 },
    { single: 'last week', many: 'weeks', div: 4 },
    { single: 'yesterday', many: 'days', div: 7 },
    { single: 'an hour ago', many: 'hours', div: 24 },
    { single: 'just now', many: 'minutes', div: 60 },
  ];

  public transform(date: number): string {
    const inputDate: Date = new Date(date);

    if (!inputDate || !inputDate.getTime) {
      return 'Invalid date';
    }

    const past = inputDate.getTime();
    const now = +new Date();

    if (past > now) {
      return 'in the future';
    }

    for (let i = 0, l = this.MAPPER.length, ms = now - past, div = this.YEAR_MS; i < l; ++i) {
      const elm = this.MAPPER[i];
      const unit = Math.floor(ms / (div /= elm.div));

      if (unit >= 1) {
        return unit === 1 ? elm.single : `${unit} ${elm.many} ago`;
      }
    }

    return 'just now';
  }
}
