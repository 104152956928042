import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EmailSendStatus } from './email-send-status.enum';
import { AuthenticationService } from '@authentication/authentication.service';
import { MfaMethod } from '@authentication/mfa-method.enum';
import { OnDestroyObservable } from '@classes/on-destroy-observable';
import { MfaOption } from '@backend-client/models/mfa-option';

@Component({
    selector: 'app-enter-authenticator',
    templateUrl: './enter-authenticator.component.html',
    styleUrls: ['./enter-authenticator.component.scss'],
    standalone: false
})
export class EnterAuthenticatorComponent extends OnDestroyObservable implements OnInit {

  // Necessary to permit access to EmailSendStatus from the HTML template
  public EmailSendStatus = EmailSendStatus;

  @Input() idToken: string;
  @Input() mfaMethods: BehaviorSubject<MfaOption[]>;

  @Output() authenticationCode = new EventEmitter<{ authCode: string, mfaMethod: MfaMethod }>();
  @Output() back = new EventEmitter<void>();

  @ViewChild('sendEmail', { read: ElementRef }) sendEmailButton: ElementRef<HTMLElement>;

  public oneTimePasswordFormGroup: UntypedFormGroup;
  public emailSendStatus: EmailSendStatus = EmailSendStatus.Unsent;
  public shouldDisplayEmail;

  constructor(private formBuilder: UntypedFormBuilder,
              private authenticationService: AuthenticationService) {super(); }

  ngOnInit() {
    // Set up form
    this.oneTimePasswordFormGroup = this.formBuilder.group({
      oneTimePasswordFormControl: [ '', [ Validators.required, Validators.minLength(6), Validators.maxLength(6) ] ]
    });

    // Subscribe to MFA methods to determine whether to display the "send email" UI element
    this.mfaMethods.pipe(takeUntil(this.ngOnDestroy$)).subscribe(mfaMethods => {
      this.shouldDisplayEmail = mfaMethods?.find(mfaOption => mfaOption.type === 'email');
    });
  }

  /**
   * Resets the state and form fields of the component before triggering the "step back" of the parent component via an event emitter
   */
  public backButtonClicked(): void {
    this.oneTimePasswordFormGroup.reset();
    this.oneTimePasswordFormGroup.controls.oneTimePasswordFormControl.setErrors(null);
    this.emailSendStatus = EmailSendStatus.Unsent;
    this.shouldDisplayEmail = null;
    this.back.emit();
  }

  /**
   * Emits the authentication code along with the method that it should be validated with
   * @param authCode The 6 digit authentication code
   */
  public submitAuthCode(authCode: string): void {
    this.authenticationCode.emit({
      authCode,
      mfaMethod: this.emailSendStatus === EmailSendStatus.Unsent ? MfaMethod.totp : MfaMethod.email
    });
  }

  /**
   * Attempt to send the email based totp and update the emailSendStatus accordingly
   */
  public async emailCode(): Promise<void> {
    this.emailSendStatus = EmailSendStatus.Sending;
    const currentMfaOptions = this.mfaMethods.getValue();
    const emailConfigurationId = currentMfaOptions.find(mfaOption => mfaOption.type === 'email').id;
    this.emailSendStatus = await this.authenticationService.emailTotp(this.idToken, emailConfigurationId);
  }

}
