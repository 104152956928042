import { MainNavLinkCollection } from './main-nav-links';
import { SimpleNavLink } from './nav-link/nav-link';

export const  homeLink: SimpleNavLink = { displayName: 'Home', link: '/home', svgIcon: 'home' };

export const linkCollections: MainNavLinkCollection[] = [
  {
    title: 'Therapist',
    sections: [
      {
        title: 'Chat',
        matIcon: 'question_answer',
        links: [
          {
            displayName: 'Chat V1',
            link: '/therapist/chat',
            scope: 'chat:view-active-chats'
          },
          {
            displayName: 'Chat Requests V1',
            link: '/therapist/chat-requests',
            scope: 'chat:view-requests'
          },
          {
            displayName: 'Chat V2',
            link: '/therapist/go-chat/all',
            scope: 'chat:view-active-chats'
          },
          {
            displayName: 'Chat Requests V2',
            link: '/therapist/go-chat-requests',
            scope: 'chat:view-requests'
          },
          {
            displayName: 'Pinned Chats V2',
            link: '/therapist/go-chat/pinned',
            scope: 'chat:view-active-chats'
          },
        ]
      },
      {
        title: 'Assigned Patients',
        svgIcon: 'inbox',
        links: [
          {
            displayName: 'General Risk',
            link: '/therapist/assigned-patients',
            scope: 'therapist:list-patients'
          },
          {
            displayName: 'High Risk',
            link: '/therapist/assigned-patients',
            scope: 'therapist:list-patients'
          },
        ]
      },
      {
        title: 'Patient Journey',
        svgIcon: 'inbox',
        links: [
          {
            displayName: 'Step 1 - Triage',
            link: '/therapist/assigned-patients/triage',
            scope: 'therapist:list-patients',
            isDisabled: true
          },
          {
            displayName: 'Step 2 - Assessment',
            link: '/therapist/assigned-patients/assessment',
            scope: 'therapist:list-patients',
            isDisabled: true
          },
          {
            displayName: 'Step 3 - Therapy',
            link: '/therapist/assigned-patients/therapy',
            scope: 'therapist:list-patients',
            isDisabled: true
          },
        ]
      },
    ]
  },
  {
    title: 'Admin',
    sections: [
      {
        title: 'Organisations',
        matIcon: 'source_environment',
        scope: 'organisations:edit',
        links: [
          {
            displayName: 'All Organisations',
            link: '/admin/organisations',
            scope: 'organisations:edit'
          },
          {
            displayName: 'Expiring Access Codes',
            link: '/admin/organisations',
            scope: 'access-code:view'
          },
          {
            displayName: 'Add New Organisation',
            link: '/admin/organisations',
            scope: 'organisations:create'
          },
        ]
      },
      {
        title: 'Message Alerts',
        matIcon: 'breaking_news',
        scope: 'message-alerts:get',
        links: [
          {
            displayName: 'All Message Alerts',
            link: '/admin/message-alerts',
            scope: 'message-alerts:get'
          },
          {
            displayName: 'Add New Message Alerts',
            link: '/admin/message-alerts',
            scope: 'message-alerts:get'
          },
        ]
      },
      {
        title: 'Surveys',
        matIcon: 'note_alt',
        links: [
          {
            displayName: 'Survey Configs',
            link: '/admin/tools/survey',
            scope: 'survey:get'
          }
        ]
      },
      {
        title: 'Promote User',
        matIcon: 'manage_accounts',
        links: [
          {
            displayName: 'Promote to Analyst',
            link: '/admin/promote-users-analyst',
            scope: 'iams:escalate-to-analyst'
          },
          {
            displayName: 'Downgrade Analyst',
            link: '/admin/downgrade-users-analyst',
            scope: 'iams:downgrade-from-analyst'
          }
        ]
      }
    ]
  },
  {
    title: 'Support',
    links: [
      {
        displayName: 'Promote to Analyst',
        matIcon: 'group_add',
        link: '/admin/promote-users-analyst',
        scope: 'iams:escalate-to-analyst'
      },
      {
        displayName: 'Downgrade Analyst',
        matIcon: 'group_remove',
        link: '/admin/downgrade-users-analyst',
        scope: 'iams:downgrade-from-analyst'
      }
    ]
  },
  {
    title: 'Mi Reporter',
    links: [
      {
        displayName: 'All Reports',
        matIcon: 'table_chart_view',
        link: '/admin/reporting/reports',
        scope: 'mi-report:basic'
      },
      {
        displayName: 'Report Schedule',
        matIcon: 'event_repeat',
        link: '/admin/reporting/report-schedule',
        scope: 'mi-report:basic'
      },
    ]
  },
  {
    title: 'DOMO Reporter',
    links: [
      {
        displayName: 'Dashboard',
        matIcon: 'monitoring',
        link: '/admin/reporting/dashboard',
        scope: 'domo-reports:view'
      },
    ]
  },
  {
    title: 'Super Admin',
    links: [
      {
        displayName: 'Edit Thrive',
        matIcon: 'edit_square',
        link: '/admin/organisations/thrive',
        scope: 'organisations:edit-thrive'
      },
    ]
  },
  {
    title: 'Therapist Manager',
    links: [
      {
        displayName: 'Region',
        matIcon: 'public',
        link: '/admin/region-settings',
        scope: 'region-settings:edit'
      },
    ]
  },
  {
    title: 'Super Therapist',
    links: [
      {
        displayName: 'View Chat Log',
        matIcon: 'inbox_text_person',
        link: '/admin/tools/chat-log',
        scope: 'chat:download-chat'
      },
      {
        displayName: 'Claim Chat Lead',
        matIcon: 'face_right',
        link: '/admin/claim-chat-lead',
        scope: 'chat:claim-lead'
      },
      {
        displayName: 'Promote Users',
        matIcon: 'group_add',
        link: '/admin/promote-users',
        scope: 'iams:escalate-to-therapist'
      },
    ]
  },
  {
    title: 'Account',
    links: [
      {
        displayName: 'Therapist Profile',
        matIcon: 'account_circle',
        link: '/therapist/profile',
        // TODO: This needs a "view therapist profile" specific scope to be implemented on TUB
        scope: 'therapist:list-patients'
      },
      {
        displayName: 'Settings',
        matIcon: 'settings',
        link: '/account/settings'
        // No scope; account links should scope agnostic
      } ]
  }

];

export const allLinks: SimpleNavLink[] = [
  {
    displayName: 'Home',
    link: '/home'
  },
  {
    displayName: 'Chat V1',
    link: '/therapist/chat'
  },
  {
    displayName: 'Chat Requests V1',
    link: '/therapist/chat-requests'
  },
  {
    displayName: 'Chat V2',
    link: '/therapist/go-chat/all'
  },
  {
    displayName: 'Pinned Chats V2',
    link: '/therapist/go-chat/pinned'
  },
  {
    displayName: 'Chat Requests V2',
    link: '/therapist/go-chat-requests'
  },
  {
    displayName: 'General Risk',
    link: '/therapist/assigned-patients'
  },
  {
    displayName: 'Patient Profile',
    link: '/therapist/assigned-patients/patient-profile'
  },
  {
    displayName: 'Therapist Profile',
    link: '/therapist/profile'
  },
  {
    displayName: 'All Organisations',
    link: '/admin/organisations'
  },
  {
    displayName: 'All Organisations',
    link: '/admin/organisations/search'
  },
  {
    displayName: 'Add New Organisation',
    link: '/admin/organisations/new/details?parentId=thrive'
  },
  {
    displayName: 'Edit Thrive Organisation',
    link: '/admin/organisations/thrive',
  },
  {
    displayName: 'Organisation',
    link: '/admin/organisations/{ID}'
  },
  {
    displayName: 'Organisation Details',
    link: '/admin/organisations/{ID}/details'
  },
  {
    displayName: 'Organisation Access Codes',
    link: '/admin/organisations/{ID}/access'
  },
  {
    displayName: 'Organisation GoThrive Client Links',
    link: '/admin/organisations/{ID}/gothrive-client-links'
  },
  {
    displayName: 'Organisation App Settings',
    link: '/admin/organisations/{ID}/app-settings'
  },
  {
    displayName: 'All Message Alerts',
    link: '/admin/message-alerts'
  },
  {
    displayName: 'Survey Configs',
    link: '/admin/tools/survey'
  },
  {
    displayName: 'Promote Users',
    link: '/admin/promote-users'
  },
  {
    displayName: 'Promote to Analyst',
    link: '/admin/promote-users-analyst'
  },
  {
    displayName: 'Downgrade Analyst',
    link: '/admin/downgrade-users-analyst'
  },
  {
    displayName: 'All Reports',
    link: '/admin/reporting/reports'
  },
  {
    displayName: 'Report Schedule',
    link: '/admin/reporting/report-schedule'
  },
  {
    displayName: 'Domo Dashboard',
    link: '/admin/reporting/dashboard'
  },
  {
    displayName: 'Region Settings',
    link: '/admin/region-settings'
  },
  {
    displayName: 'View Chat Log History',
    link: '/admin/tools/chat-log'
  },
  {
    displayName: 'Claim Chat Lead',
    link: '/admin/claim-chat-lead'
  },
  {
    displayName: 'Settings',
    link: '/account/settings'
  },
  {
    displayName: 'Users',
    link: '/admin/users'
  }
];

export const paramLinks: SimpleNavLink[] = [
  {
    displayName: 'Organisation',
    link: '/admin/organisations/{ID}'
  },
  {
    displayName: 'Organisation Details',
    link: '/admin/organisations/{ID}/details'
  },
  {
    displayName: 'Organisation Access Codes',
    link: '/admin/organisations/{ID}/access'
  },
  {
    displayName: 'Organisation GoThrive Client Links',
    link: '/admin/organisations/{ID}/gothrive-client-links'
  },
  {
    displayName: 'Organisation App Settings',
    link: '/admin/organisations/{ID}/app-settings'
  },
  {
    displayName: 'Patient Profile Timeline',
    link: '/therapist/assigned-patients/patient-profile/{ID}/timeline'
  },
  {
    displayName: 'Patient Profile Mood Meter',
    link: '/therapist/assigned-patients/patient-profile/{ID}/mood-meter'
  },
  {
    displayName: 'Patient Profile Record',
    link: '/therapist/assigned-patients/patient-profile/{ID}/record'
  },
  {
    displayName: 'Chat V2',
    link: '/therapist/go-chat/all/{ID}'
  },
  {
    displayName: 'Pinned Chat V2',
    link: '/therapist/go-chat/pinned/{ID}'
  },
  {
    displayName: 'Chat',
    link: '/therapist/chat/{ID}'
  },
];
