import { Injectable } from '@angular/core';

import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { SimpleNavLink } from './nav-link/nav-link';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { MainNavLinkCollection, MainNavLinkSections } from './main-nav-links';
import { linkCollections, homeLink } from './router-links';


@Injectable({
  providedIn: 'root'
})
export class MainNavService {
  public mainNavState$ = new BehaviorSubject(true);
  public mainNavOpen$ = new BehaviorSubject(true);
  public mainNavFixedOpen$ = new BehaviorSubject(true);

  public homeLink = homeLink;

  constructor(
    private authenticationService: AuthenticationService,
  ) {}

  public initialiseStoredNavState(): void {
    const stored = localStorage.getItem('menu_open');
    this.mainNavOpen$.next(stored === 'true');
    this.mainNavState$.next(stored === 'true');
    this.mainNavFixedOpen$.next(stored === 'true');
  }

  /**
   * Returns only the links that the user has permissions to access
   */
  public async getMainNavLinks(): Promise<MainNavLinkCollection[]> {

    // If there are no scopes, then try to fetch them.
    // setUserScopes() is called on login, however when running locally or on dev, it is possible to refresh the page without triggering a
    // log in request - this code triggers that request.
    const currentScopes = await firstValueFrom(this.authenticationService.getUserScopes$());
    // Checking to make sure we are not on the callback page - we don't want Auth box appearing
    if (currentScopes[0] === null && !window.location.pathname.includes('callback')) {
      await this.authenticationService.setUserScopes();
    }

    const filteredLinks: MainNavLinkCollection[] = [];

    for (const linkCollection of linkCollections) {
      // Create an array of promises to determine whether the user has permissions to view link
      let results: Promise<boolean>[] = [];

      if (linkCollection.links?.length > 0) {
        results.push(...linkCollection.links?.map(link => this.hasPermissionToViewLink(link)));
      } else if (linkCollection.sections?.length > 0) {
        for (const section of linkCollection.sections) {
          if (section.links?.length > 0) {
            results.push(...section.links?.map(link => this.hasPermissionToViewLink(link)));
          }
        }
      }

      // Resolve all the promises to create an array of boolean whose indexes correspond with each link in linkCollection.links
      const hasPermission: boolean[] = await Promise.all(results);

      // construct a new MainNavLinkCollection rather than modifying the existing one, as this would change the original config
      const filteredLinkCollection: MainNavLinkCollection = {
        title: linkCollection.title,
        links: linkCollection.links?.filter((_, index) => hasPermission[index]),
        sections: linkCollection.sections?.filter((_, index) => hasPermission[index])
      };

      filteredLinks.push(filteredLinkCollection);
    }

    return filteredLinks;
  }

  /**
   * Returns true if no scope has been set (can be assumed to be a "default" link, visible by all users).
   * Returns true if the link has a scope, and the user has been assigned that scope
   * Returns false if the links has a scope, but the user does not have that scope
   * @param link The link to check
   */
  private async hasPermissionToViewLink(link: SimpleNavLink | MainNavLinkSections): Promise<boolean> {
    return link.scope ? await this.authenticationService.isScopePresent(link.scope) : true;
  }
}
