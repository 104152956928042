<a class="link"
  [class.section-link]="sectionLink"
  [ngClass]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'menu-open' : 'menu-closed'"
  mat-list-item
  [routerLink]="linkData.isDisabled ? null : linkData.link"
  routerLinkActive="selected-route">
  <div fxLayout="row" class="link-text">
    <mat-icon *ngIf="linkData.matIcon && !linkData.svgIcon" class="icon">{{ linkData.matIcon }}</mat-icon>
    <mat-icon *ngIf="linkData.svgIcon" [svgIcon]="linkData.svgIcon" class="icon white"></mat-icon>
    <span [@animateText]="(mainNavService.mainNavOpen$ | async) || (mainNavService.mainNavState$ | async) ? 'show' : 'hide'">
      {{ linkData.displayName }}
    </span>
    <mat-icon *ngIf="!sectionLink" class="link-arrow">chevron_right</mat-icon>
  </div>
</a>
