import { Injectable } from '@angular/core';

import { BehaviorSubject, firstValueFrom, take } from 'rxjs';

import { TherapistAssignedPatientsService } from '@backend-client/services';
import { TubAssignedPatient } from '@backend-client/models/tub-assigned-patient';
import { ActiveChatSessionsService } from '../chat/active-chat-sessions.service';
import { GoActiveChatSessionsService } from '../go-chat/go-active-chat-sessions.service';
import { GoCoreChatService } from '@app/modules/shared/services/gocore/chat/gocore-chat.service';

@Injectable({
  providedIn: 'root'
})
export class AssignedPatientsService {

  private readonly PAGE_LIMIT = 20;
  private readonly assignedPatientsSubject$ = new BehaviorSubject<TubAssignedPatient[]>([]);
  public readonly assignedPatients$ = this.assignedPatientsSubject$.asObservable();
  public dataStateValid$ = new BehaviorSubject<boolean>(true);

  private skip = 0;
  public loadedAll = false;
  public fetchingResults = false;
  public loadFromCache = false;

  constructor(
    private readonly therapistAssignedPatientsService: TherapistAssignedPatientsService,
    private readonly activeChatSessionsService: ActiveChatSessionsService,
    private readonly goActiveChatSessionsService: GoActiveChatSessionsService,
    private readonly goCoreChatService: GoCoreChatService,
  ) {}

  public async loadCache(): Promise<void> {
    this.fetchingResults = true;
    const loadedPatients = this.assignedPatientsSubject$.getValue();

    if (loadedPatients.length === 0 || !this.dataStateValid$.getValue()) {
      this.loadFromCache = false;
      await this.reload();
      this.dataStateValid$.next(true);
    } else {
      this.loadFromCache = true;
      this.assignedPatientsSubject$.next(loadedPatients);
    }
  }

  public async reload(): Promise<void> {
    this.fetchingResults = true;
    this.loadedAll = false;
    this.skip = 0;
    const results: TubAssignedPatient[] = [];

    try {
      await this.loadAssignedPatients(results);
    } catch (err) {
      this.fetchingResults = false;
    }
  }

  public async load(loadAll: boolean = false): Promise<void> {
    this.fetchingResults = true;
    const results: TubAssignedPatient[] = this.assignedPatientsSubject$.value;

    if (this.loadedAll) {
      this.fetchingResults = false;
      return;
    }

    try {
      if (loadAll) {
        do {
          await this.loadAssignedPatients(results, loadAll);
        } while (!this.loadedAll);
      } else {
        await this.loadAssignedPatients(results);
      }
    } catch (err) {
      this.fetchingResults = false;
    }
  }

  public async setHighRiskStatus(patientId: string, highRisk: boolean): Promise<void> {
    try {
      this.modifyHighRiskThenEmit(patientId, highRisk);
      const updatedAssignedPatient = await this.sendHighRiskStatusRequest(patientId, highRisk);
      this.emitUpdatedAssignedPatient(updatedAssignedPatient);
    } catch (err) {
      // Revert the high risk.
      this.modifyHighRiskThenEmit(patientId, !highRisk);
      // Rethrow so the calling component can handle the exception as appropriate.
      throw err;
    }
  }

  private async loadAssignedPatients(results: TubAssignedPatient[], loadAll: boolean = false): Promise<void> {
    // Skip here to prevent duplicate requests sent before we update the skip value.
    this.skip += this.PAGE_LIMIT;

    await firstValueFrom(this.therapistAssignedPatientsService.TherapistAssignedPatientsGetAssignedPatients({
        skip: (this.skip - this.PAGE_LIMIT),
        limit: this.PAGE_LIMIT
      })).then(resultsPage => {
        if (resultsPage.length !== this.PAGE_LIMIT) {
          this.loadedAll = true;
          results.push(...resultsPage);
          this.assignedPatientsSubject$.next(results);
          return;
        }

        results.push(...resultsPage);

        if (!loadAll) {
          this.assignedPatientsSubject$.next(results);
        }
      });
  }

  private modifyHighRiskThenEmit(patientId: string, highRisk: boolean): void {
    const patientToUpdate = this.assignedPatientsSubject$.value.find(patient => patient.id === patientId);

    if (patientToUpdate != null) {
      patientToUpdate.record.highRisk = highRisk;
      this.assignedPatientsSubject$.next(this.assignedPatientsSubject$.value);
    }
  }

  private async sendHighRiskStatusRequest(patientId: string, highRisk: boolean): Promise<TubAssignedPatient> {
    return this.therapistAssignedPatientsService.TherapistAssignedPatientsSetAssignedPatientHighRisk({
      patientId,
      payload: {
        highRisk
      }
    }).toPromise();
  }

  private emitUpdatedAssignedPatient(updatedPatient: TubAssignedPatient): void {
    const index = this.assignedPatientsSubject$.value.findIndex(patient => patient.id === updatedPatient.id);

    if (index >= 0) {
      Object.assign(this.assignedPatientsSubject$.value[index], updatedPatient);
      this.assignedPatientsSubject$.next(this.assignedPatientsSubject$.value);
    }
  }

  public async dischargePatient(patient: TubAssignedPatient, mass: boolean = false): Promise<void> {
    if (patient?.record?.activeChat?.pubNub) {
      await new Promise<void>(async (resolve, reject) => {
        this.goCoreChatService.dischargeSuccessful$.pipe(take(1)).subscribe(() => resolve());
        this.goCoreChatService.dischargeFailed$.pipe(take(1)).subscribe((error) => { reject(error); });
        await this.goCoreChatService.chatTherapistDischarge(patient.record.activeChat.chatSessionId, patient.id);
      }).finally(async () => {
        if (!mass) {
          // Clearing the cache to remove the chat.
         this.goActiveChatSessionsService.clearCache();
        }
      });
    } else {
      await this.therapistAssignedPatientsService
        .TherapistAssignedPatientsDischargePatientFromCare(patient.id)
        .toPromise();
    }

    // NOTE: once pagination is implemented we will reload the current page. Until then, we'll opt for removing the patient
    // directly from the array, rather than requests all the therapists (potentially 100's) of assigned patients.
    const index = this.assignedPatientsSubject$.value.findIndex(p => p.id === patient.id);

    if (index >= 0) {
      this.assignedPatientsSubject$.value.splice(index, 1);
      this.assignedPatientsSubject$.next(this.assignedPatientsSubject$.value);
    }
  }
}
