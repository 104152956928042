import { Directive, Host, Input, OnInit } from '@angular/core';

import { DatabaseNotificationType } from '@shared/services/notifications';
import { PrimaryNotificationComponent } from './primary-notification/primary-notification.component';
import { DashboardNotification } from './dashboard-notification';

@Directive({
    selector: '[appAssignedPatientNotification]',
    standalone: true
})
export class AssignedPatientNotificationDirective implements OnInit {

  @Input() notificationData: DatabaseNotificationType;

  constructor(@Host() private primaryNotificationComponent: PrimaryNotificationComponent) { }

  ngOnInit() {
    const { patientId, fromTherapistFirstName, fromTherapistLastName } = this.notificationData.data;

    const therapistName = `${fromTherapistFirstName} ${fromTherapistLastName}`;
    const timestamp = this.notificationData.ts;
    const readAtTime = this.notificationData.read;
    const id = this.notificationData.id;

    const icon = 'folder_shared';
    const link = `/therapist/assigned-patients/patient-profile/${patientId}`;
    const title = 'Patient Record Shared';
    const message = `A patient record has been shared with you by ${therapistName}.`;

    this.primaryNotificationComponent.notification = new DashboardNotification(icon, link, title, message, timestamp, readAtTime, id);
  }

}
