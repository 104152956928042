import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a word e.g. 'googleFile' to 'Google File'
 */
@Pipe({
    name: 'convertCamelCaseToTitleCase',
    standalone: false
})
export class ConvertCamelCaseToTitleCasePipe implements PipeTransform {

  transform(values: string[]): string {
    if (!values || values === undefined) return '';

    const formattedValues = [];
    values.forEach(value => {
      formattedValues.push(this.captialise(value.replace(/([a-z])([A-Z])/g, '$1 $2')));
    });
    return formattedValues.join(', ');
  }

  private captialise(value: string): string {
    return value[0].toUpperCase() + value.slice(1);
  }
}
