<mat-form-field class="full-width-input">
  <mat-label>Phone Type</mat-label>
  <mat-select [(value)]="osType" [formControl]="osTypeFormControl" required>
    <mat-option *ngFor="let link of authenticatorLinks; trackByProperty: 'authenticators'" value="{{link.os}}">{{link.os}}</mat-option>
  </mat-select>
</mat-form-field>
<div class="text-block">
  <p>Clicking "Install" will open up a link to Google's official Authenticator that we will be using to add two
    factor
    authentication to your account.</p>
  Once installed:
  <ol>
    <li>Open the App</li>
    <li>Read through the tutorial</li>
    <li>Select "Scan a barcode"</li>
    <li>Return to this page to scan your barcode</li>
  </ol>
  <p>Click "Install" once you're ready to continue.</p>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center">
  <button type="button" mat-button matStepperPrevious (click)="backButtonClicked()">Back</button>
  <button type="submit" mat-flat-button
          [disabled]="osTypeFormControl.invalid || !osTypeFormControl.value"
          (click)="openAuthenticatorLink()">
    Install
  </button>
</div>
