<div id="thrive-24" fxLayout="row">
  <ng-container *ngIf="(officeHoursService.getOfficeHours$() | async)?.isOfficeHours; else thrive24">
    <img src="../../../../../assets/images/thrive-24-greyscale.webp" width="51" height="43" class="only-on-light" alt="thrive 24 coverage inactive" />
    <img src="../../../../../assets/images/thrive-24-greyscale-white.webp" width="51" height="43" class="only-on-dark" alt="thrive 24 coverage inactive" />
    <div class="feature-name inactive">
      <p>Thrive 24</p>
      <p class="status">In-Active</p>
    </div>
  </ng-container>
</div>

<ng-template #thrive24>
  <img src="../../../../../assets/images/thrive-24.webp" width="51" height="43" class="only-on-light" alt="thrive 24 coverage active" />
  <img src="../../../../../assets/images/thrive-24-white.webp" width="51" height="43" class="only-on-dark" alt="thrive 24 coverage active" />
  <div class="feature-name">
    <p>Thrive 24</p>
    <p class="status">Active</p>
  </div>
</ng-template>
