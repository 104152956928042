import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, UntypedFormArray, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { HttpErrorResponse } from '@angular/common/http';

import { filter, take, takeUntil } from 'rxjs';
import moment from 'moment-timezone';

import { DashboardTubMoment } from '@backend-client/models/dashboard-tub-moment';
import { TubAppointmentMoment, TubCgiCreationRequestBody, TubEditAppointmentMomentRequestBody, TubHasActiveChat, TubPatient, TubPatientRiskAssessment } from '@backend-client/models';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { OnDestroyObservable } from '@app/classes/on-destroy-observable';
import { PatientProfileService } from '@app/modules/therapist/assigned-patients/patient-profile/patient-profile.service';
import { TimelineService } from '../timeline.service';
import { AttendanceStates } from './attendance-states';
import { CGI_Improvement, CGI_Improvement_Answers, CGI_Improvement_Question, CGI_Severity, CGI_Severity_Answers, CGI_Severity_Hint, CGI_Severity_Question } from './cgi-questions';
import { PHQ_GAD_Initial_Question, GAD7, PHQ9, PHQ_GAD_Answers, Function_Question, Function_Question_Answers } from './phq-gad-questionnaire';
import { CheckableMoment } from './attachments.model';
import { AppointmentMoment } from '../moment/appointment-moment/appointment-moment';
import { NoteMoment } from '../moment/note-moment/note-moment';
import { RiskAssessmentEventMoment } from '../moment/risk-assessment-event-moment/risk-assessment-event-moment';
import { CgiScoreMoment } from '../moment/cgi-score/cgi-score-moment';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';
import { AppointmentMomentService } from './appointment-moment.service';
import { MomentService } from '../moment/moment.service';
import { MomentType } from '../moment/moment-type';
import { StringEntryControlValidator } from '@shared/utils/string-entry-control-validator';

@Component({
  selector: 'app-add-edit-appointment-moment',
  templateUrl: './add-edit-appointment-moment.component.html',
  styleUrls: [ './add-edit-appointment-moment.component.scss' ],
  providers: [ TimelineService, AppointmentMomentService, MomentService ],
  standalone: false
})
export class AddEditAppointmentMomentComponent extends OnDestroyObservable implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;

  private id: string;
  private patientId: string;
  private appointment: AppointmentMoment;
  private previousLocation: string;

  public patient: TubPatient;
  public patientChat: TubHasActiveChat;
  public today = new Date();
  public editMode = false;
  public sessionCancelled = false;
  public featureFlags$ = this.featureFlagService.featureFlags$;
  public editReasonFormGroup = this.formBuilder.group({
    reason: ['', [Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]],
  });
  public appointmentFormGroup = this.formBuilder.group({
    appointmentDate: [{ value: new Date(), disabled: true }, [Validators.required]],
    appointmentTime: [ new Date(), [Validators.required, this.isInvalidTimeValidator()]],
    appointmentAttendanceStatus: ['', Validators.required],
    appointmentAttendanceStatusOther: '',
    appointmentSessionNumber: ['', [Validators.required, Validators.min(0)]],
    functionResult: ['', Validators.required],
  });
  public noteFormGroup = this.formBuilder.group({
    note: ['', [Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]],
  });
  public phqFormGroup = this.formBuilder.group({
    PHQ9_1: ['', Validators.required],
    PHQ9_2: ['', Validators.required],
    PHQ9_3: ['', Validators.required],
    PHQ9_4: ['', Validators.required],
    PHQ9_5: ['', Validators.required],
    PHQ9_6: ['', Validators.required],
    PHQ9_7: ['', Validators.required],
    PHQ9_8: ['', Validators.required],
    PHQ9_9: ['', Validators.required],
  });
  public gadFormGroup = this.formBuilder.group({
    GAD7_1: ['', Validators.required],
    GAD7_2: ['', Validators.required],
    GAD7_3: ['', Validators.required],
    GAD7_4: ['', Validators.required],
    GAD7_5: ['', Validators.required],
    GAD7_6: ['', Validators.required],
    GAD7_7: ['', Validators.required],
  });
  public cgiFormGroup = this.formBuilder.group({
    cgiSeverityResult: ['', Validators.required],
    cgiImprovementResult: ['', Validators.required],
  });
  public attachmentsFormGroup = this.formBuilder.group({
    moments: this.formBuilder.array([]),
  });

  // Step 1
  public previousNote: DashboardTubMoment;
  public attendanceStates = Object.values(AttendanceStates);
  public functionQuestion = Function_Question;
  public functionAnswers = Object.values(Function_Question_Answers);

  // Step 2 & 3
  public phqGadInitialQuestion = PHQ_GAD_Initial_Question;
  public gadQuestions = Object.values(GAD7);
  public gadQuestionsKeys = Object.keys(GAD7);
  public phqQuestions = Object.values(PHQ9);
  public phqQuestionsKeys = Object.keys(PHQ9);
  public phqGadAnswers = Object.values(PHQ_GAD_Answers);
  public gadScore = 0;
  public phqScore = 0;

  // Step 4
  public previousCGI: DashboardTubMoment;
  public cgiSeverityExplanation = CGI_Severity;
  public cgiSeverityQuestion = CGI_Severity_Question;
  public cgiSeverityQuestionHint = CGI_Severity_Hint;
  public cgiSeverityKeys = Object.keys(CGI_Severity_Answers);
  public cgiSeverityAnswers = Object.values(CGI_Severity_Answers);
  public cgiImprovementExplanation = CGI_Improvement;
  public cgiImprovementQuestion = CGI_Improvement_Question;
  public cgiImprovementKeys = Object.keys(CGI_Improvement_Answers);
  public cgiImprovementAnswers = Object.values(CGI_Improvement_Answers);

  // Step 5
  // Values all stored under appointment moment service

  // Saving
  public savingData = false;
  public errorSaving = false;
  public savingCompleted = false;
  public errorInformation$ = this.appointmentMomentService.errorInformation$;
  public phqGadRequired = false;

  constructor(
    public appointmentMomentService: AppointmentMomentService,
    public momentService: MomentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
    private dialogService: DialogService,
    private timelineService: TimelineService,
    private patientProfileService: PatientProfileService,
  ) {
    super();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.ngOnDestroy$))
      .subscribe((event: NavigationEnd) => {
        this.previousLocation = event.url;
      });
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.patientId = params.patientId;
      this.id = params.id;

      if (this.id) {
        this.editMode = true;
      }
    });
    this.appointmentMomentService.patientId$.next(this.patientId);

    try {
      if (this.editMode) {
        this.appointment = await this.appointmentMomentService.getLatestMoment(this.id) as AppointmentMoment;
      } else {
        this.previousNote = (await this.timelineService.getMoments(this.patientId, [MomentType.Note]))[0];
        this.previousCGI = (await this.timelineService.getMoments(this.patientId, [MomentType.CgiScore]))[0];
      }
    } catch (e) {
      console.error(e);
    }

    await this.formInit();
    this.stepper.selectionChange.pipe(takeUntil(this.ngOnDestroy$)).subscribe(changeEvent => {
      if (changeEvent.selectedIndex === 4) {
        this.appointmentMomentService.fetchingAttachments$.next(true);
        this.appointmentMomentService.fetchAttachments();
      }
    });

    // Do this last as it is not needed till last step
    try {
      this.patient = await this.patientProfileService.getPatientInformation(this.patientId);
      this.patientChat = await this.patientProfileService.getPatientChatInformation(this.patientId);
    } catch (e) {
      console.error(e);
    }
  }

  public cancel(): void {
    const dialogRef = this.dialogService.openDialog(CancelDialogComponent);
    dialogRef.afterClosed().pipe(take(1)).subscribe(response => {
      if (response?.routeAway) {
        const returnUrl = this.previousLocation !== window.location.pathname ? this.previousLocation : `/therapist/assigned-patients/patient-profile/${this.patientId}`;
        this.router.navigate([ returnUrl ]);
      }
    });
  }

  public returnToTimeline(): void {
    this.router.navigate([ `/therapist/assigned-patients/patient-profile/${this.patientId}` ]);
  }

  public returnToChatSession(): void {
    const chatURL = this.patientChat.pubNub ? 'go-chat' : 'chat';
    this.router.navigate([ `/therapist/${chatURL}/${this.patientChat.chatRoomId}` ]);
  }

  public saveDisabled(): boolean {
    if (this.editMode && this.editReasonFormGroup.invalid) {
      return true;
    }

    if (this.appointmentMomentService.errorFetchingAttachments$.value) {
      return true;
    }

    if (this.sessionCancelled) {
      return !this.appointmentFormGroup.valid;
    }

    if (!this.sessionCancelled && this.appointmentFormGroup.valid && this.cgiFormGroup.valid) {
      if (this.gadFormGroup.pristine && this.phqFormGroup.pristine) {
        return false;
      }

      if (this.gadFormGroup.dirty && this.phqFormGroup.dirty) {
        return !this.gadFormGroup.valid || !this.phqFormGroup.valid;
      }
    }

    return true;
  }

  public async save(): Promise<void> {
    this.errorSaving = false;
    this.savingData = true;

    const note = { text: this.noteFormGroup.get('note').value !== '' ? this.noteFormGroup.get('note').value : undefined };
    const cgiScore = this.cgiFormGroup.valid && this.cgiFormGroup.touched ? this.getCGIMomentData() : undefined;
    const riskAssessment = this.phqFormGroup.valid && this.phqFormGroup.touched && this.gadFormGroup.valid && this.gadFormGroup.touched ? this.getRiskAssessmentMomentData() : undefined;
    const appointment = this.getAppointmentMomentData();

    appointment.noteRef = this.appointment?.noteRef;
    appointment.cgiRef = this.appointment?.cgiRef;
    appointment.riskAssessmentRef = this.appointment?.riskAssessmentRef;

    if (!this.id && !this.appointment) {
      await this.timelineService.createAppointmentMoment(this.patientId, {appointment, note, cgiScore, riskAssessment})
        .catch((error: HttpErrorResponse) => {
          console.error(error);
          this.errorSaving = true;
          switch(error.status) {
            case 404:
              this.appointmentMomentService.errorInformation$.next('Patient not found in database.');
              break;
            case 403:
              this.appointmentMomentService.errorInformation$.next('You do not have authorisation to access / update this patients record. This may be because you have discharged the patient recently.');
              break;
            case 400:
              this.appointmentMomentService.errorInformation$.next('Attachment belongs to another appointment, please update selection.');
              break;
            default:
              this.appointmentMomentService.errorInformation$.next('An error has occured on submitting the data, if you continue to encounter this error please contact support.');
              break;
          }
        })
        .finally(() => {
          if (!this.errorSaving) {
            this.savingCompleted = true;
          }
          this.savingData = false;
        });
    } else {
      const dataToSave: TubEditAppointmentMomentRequestBody = {
        id: this.id,
        reason: this.editReasonFormGroup.get('reason').value,
      };

      if (this.appointmentFormGroup.touched && this.appointmentFormGroup.dirty) {
        dataToSave.appointment = appointment;
      } else if (this.attachmentsFormGroup.touched && this.attachmentsFormGroup.dirty) {
        dataToSave.appointment = {
          attachmentsRef: this.attachmentsFormGroup.get('moments').value as string[]
        } as TubAppointmentMoment;
      }
      if (this.noteFormGroup.touched && this.noteFormGroup.dirty) {
        dataToSave.note = note;
      }
      if (this.cgiFormGroup.touched && this.cgiFormGroup.dirty) {
        dataToSave.cgiScore = cgiScore;
      }
      if (this.gadFormGroup.touched && this.gadFormGroup.dirty || this.phqFormGroup.touched && this.phqFormGroup.dirty) {
        dataToSave.riskAssessment = riskAssessment;
      }

      await this.timelineService.updateAppointmentMoment(this.patientId, dataToSave)
        .catch((error: HttpErrorResponse) => {
          console.error(error);
          this.errorSaving = true;
          switch(error.status) {
            case 404:
              this.appointmentMomentService.errorInformation$.next('Patient not found in database.');
              break;
            case 403:
              this.appointmentMomentService.errorInformation$.next('You do not have authorisation to access / update this patients record. This may be because you have discharged the patient recently.');
              break;
            case 400:
              this.appointmentMomentService.errorInformation$.next('Attachment belongs to another appointment, please update selection.');
              break;
            default:
              this.appointmentMomentService.errorInformation$.next('An error has occured on submitting the data, if you continue to encounter this error please contact support.');
              break;
          }
        })
        .finally(() => {
          if (!this.errorSaving) {
            this.savingCompleted = true;
          }
          this.savingData = false;
        });
    }
  }

  public markGadPhqAsNotRequired(): void {
    //  Don't emit event - as GAD relies on PHQ - forms mark each another as dirty when value changes
    this.phqFormGroup.reset({}, {emitEvent: false});
    this.phqFormGroup.setErrors(null);
    this.phqScore = 0;

    this.gadFormGroup.reset({}, {emitEvent: false});
    this.gadFormGroup.setErrors(null);
    this.gadScore = 0;
  }

  public checkedAttachment(attachment: CheckableMoment): void {
    const formArray = (this.attachmentsFormGroup.get('moments') as UntypedFormArray);
    const checked = this.appointmentMomentService.attachments.filter(a => a.id === attachment.id)[0];

    if (this.appointmentMomentService.attachmentsCheckedList$.value.length > 0 && this.appointmentMomentService.attachmentsCheckedList$.value.filter(a => a.id === attachment.id).length > 0) {
      this.appointmentMomentService.attachmentsCheckedList$.next(this.appointmentMomentService.attachmentsCheckedList$.value.filter(a => a.id !== attachment.id));

      checked.checked = false;
      const removeIndex = formArray.controls.findIndex(control => control.value === attachment.id);
      formArray.removeAt(removeIndex);
      this.attachmentsFormGroup.markAsTouched();
      this.attachmentsFormGroup.markAsDirty();
    } else {
      this.appointmentMomentService.attachmentsCheckedList$.value.push(attachment);
      checked.checked = true;
      formArray.push(this.formBuilder.control(attachment.id));
      this.attachmentsFormGroup.markAsTouched();
      this.attachmentsFormGroup.markAsDirty();
    }
  }

  private async formInit(): Promise<void> {
    if (this.editMode) {
      this.appointmentFormGroup.patchValue({
        appointmentDate: new Date(this.appointment.appointmentDate),
        appointmentTime: new Date(this.appointment.appointmentTime),
        appointmentAttendanceStatus: this.appointment.appointmentOutcome,
        appointmentAttendanceStatusOther: this.appointment?.appointmentOutcomeOther,
        appointmentSessionNumber: `${this.appointment.therapySessionNumber}`,
        functionResult: this.appointment.howPatientIsFunctioning
      });

      if (this.appointment.appointmentOutcome === AttendanceStates.therapistCancelled ||
        this.appointment.appointmentOutcome === AttendanceStates.patientCancelled ||
        this.appointment.appointmentOutcome === AttendanceStates.rescheduled
      ) {
        // Clear all validation as session never took place
        this.sessionCancelled = true;

        this.noteFormGroup.controls['note'].removeValidators([Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]);
        this.noteFormGroup.controls['note'].setErrors(null);
        this.noteFormGroup.controls['note'].updateValueAndValidity();
        this.appointmentFormGroup.controls['functionResult'].clearValidators();
        this.appointmentFormGroup.controls['functionResult'].setErrors(null);

        this.phqFormGroup.clearValidators();
        this.gadFormGroup.clearValidators();
        this.cgiFormGroup.clearValidators();
      }

      if (this.appointment?.noteRef) {
        const note = await this.appointmentMomentService.getLatestMoment(this.appointment.noteRef) as NoteMoment;
        this.noteFormGroup.controls['note'].patchValue(note.note);
      }

      if (this.appointment?.riskAssessmentRef) {
        this.phqGadRequired = true;
        const riskAssessment = await this.appointmentMomentService.getLatestMoment(this.appointment.riskAssessmentRef) as RiskAssessmentEventMoment;
        // PHQ
        for (const questionAnswer of riskAssessment.PHQQuestions) {
          this.phqFormGroup.get(questionAnswer.questionIndex).patchValue(questionAnswer.answerIndex);
        }
        this.phqScore = riskAssessment.PHQScore;
        this.phqFormGroup.updateValueAndValidity();
        this.phqFormGroup.markAsDirty();

        // GAD
        for (const questionAnswer of riskAssessment.GADQuestions) {
          this.gadFormGroup.get(questionAnswer.questionIndex).patchValue(questionAnswer.answerIndex);
        }
        this.gadScore = riskAssessment.GADScore;
        this.gadFormGroup.updateValueAndValidity();
        this.gadFormGroup.markAsDirty();
      }

      if (this.appointment?.cgiRef) {
        const cgiScore = await this.appointmentMomentService.getLatestMoment(this.appointment.cgiRef) as CgiScoreMoment;
        this.cgiFormGroup.patchValue({
          cgiImprovementResult: `${cgiScore.cgiImprovement}`,
          cgiSeverityResult: `${cgiScore.cgiSeverity}`
        });
      }

      if (this.appointment?.attachmentsRef && this.appointment?.attachmentsRef.length > 0) {
        const attachments = [];
        const formArray = this.attachmentsFormGroup.get('moments') as FormArray;

        for (const attachmentId of this.appointment.attachmentsRef) {
          const file = await this.timelineService.getMomentById(this.patientId, attachmentId);
          if (file) {
            (file as CheckableMoment).checked = true;
            file.id = attachmentId;
            attachments.push(file);
            formArray.push(this.formBuilder.control(attachmentId));
          }
        }

        this.appointmentMomentService.attachmentsCheckedList$.next(attachments);
      }

    } else {
      this.editReasonFormGroup.controls['reason'].clearValidators();
      this.editReasonFormGroup.controls['reason'].setErrors(null);

      this.appointmentFormGroup.get('appointmentTime').patchValue(new Date());
    }

    this.appointmentFormGroup.get('appointmentAttendanceStatus').valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(status => {
      if (status === AttendanceStates.therapistCancelled ||
          status === AttendanceStates.patientCancelled ||
          status === AttendanceStates.rescheduled
        ) {

        // Clear all validation as session never took place
        this.sessionCancelled = true;

        this.noteFormGroup.controls['note'].removeValidators([Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]);
        this.noteFormGroup.controls['note'].setErrors(null);
        this.noteFormGroup.controls['note'].updateValueAndValidity();
        this.appointmentFormGroup.controls['functionResult'].clearValidators();
        this.appointmentFormGroup.controls['functionResult'].setErrors(null);

        this.phqFormGroup.clearValidators();
        this.gadFormGroup.clearValidators();
        this.cgiFormGroup.clearValidators();
      }

      if (this.sessionCancelled && status !== AttendanceStates.therapistCancelled &&
          this.sessionCancelled && status !== AttendanceStates.patientCancelled &&
          this.sessionCancelled && status !== AttendanceStates.rescheduled
        ) {

          // Re-add validators
          this.sessionCancelled = false;

          this.noteFormGroup.get('note').addValidators([Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]);
          this.noteFormGroup.get('note').setErrors(Validators.required);
          this.noteFormGroup.get('note').updateValueAndValidity();
          this.appointmentFormGroup.get('functionResult').addValidators(Validators.required);
          this.appointmentFormGroup.get('functionResult').setErrors(Validators.required);
          this.appointmentFormGroup.get('functionResult').updateValueAndValidity();

          this.phqFormGroup.addValidators(Validators.required);
          this.phqFormGroup.setErrors(Validators.required);
          this.phqFormGroup.updateValueAndValidity();

          this.gadFormGroup.addValidators(Validators.required);
          this.gadFormGroup.setErrors(Validators.required);
          this.gadFormGroup.updateValueAndValidity();

          this.cgiFormGroup.addValidators(Validators.required);
          this.cgiFormGroup.setErrors(Validators.required);
          this.cgiFormGroup.updateValueAndValidity();
        }

      if (status === AttendanceStates.other) {
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').addValidators([Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]);
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').setErrors(Validators.required);
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').updateValueAndValidity();
      } else {
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').reset();
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').removeValidators([Validators.required, StringEntryControlValidator.isInvalidStringEntryValidator()]);
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').setErrors(null);
        this.appointmentFormGroup.get('appointmentAttendanceStatusOther').updateValueAndValidity();
      }
    });

    this.appointmentFormGroup.get('appointmentDate').valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(date => {
      const appointmentTimeControl = this.appointmentFormGroup?.get('appointmentTime');

      const now = new Date();
      now.setHours(0,0,0,0);
      const currentDate = new Date(date);

      if (currentDate.toDateString() === now.toDateString()) {
        const currentDateTime = currentDate;
        const currentTime = appointmentTimeControl.value.getTime();
        currentDateTime.setTime(currentTime);

        if (new Date() < currentDateTime) {
          appointmentTimeControl.setErrors({ invalidTimeFuture: true });
        }
      } else if (appointmentTimeControl.hasError('invalidTimeFuture')) {
        // clear errors if date has been changed to a previous date
        appointmentTimeControl.setErrors(null);
      }
    });

    this.phqFormGroup.valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(answers => {
      this.phqScore = this.appointmentMomentService.calcGadPhqScore(Object.values(answers));

      if (this.gadFormGroup.pristine) {
        this.gadFormGroup.markAsDirty();
      }

      if (!this.gadFormGroup.controls['GAD7_1'].hasValidator(Validators.required)) {
        const gadControls = this.gadFormGroup.controls;

        for (const control of Object.keys(gadControls)) {
          this.gadFormGroup.get(control).addValidators(Validators.required);
        }
      }
    });

    this.gadFormGroup.valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(answers => {
      this.gadScore = this.appointmentMomentService.calcGadPhqScore(Object.values(answers));

      if (this.phqFormGroup.pristine) {
        this.phqFormGroup.markAsDirty();
      }

      if (!this.phqFormGroup.controls['PHQ9_1'].hasValidator(Validators.required)) {
        const phqControls = this.phqFormGroup.controls;

        for (const control of Object.keys(phqControls)) {
          this.phqFormGroup.get(control).addValidators(Validators.required);
        }
      }
    });

    this.appointmentMomentService.fetchAttachments();
  }

  private getCGIMomentData(): TubCgiCreationRequestBody {
    return {
      CGIImprovement: Number(this.cgiFormGroup.get('cgiImprovementResult').value),
      CGISeverity: Number(this.cgiFormGroup.get('cgiSeverityResult').value)
    } as TubCgiCreationRequestBody;
  }

  private getRiskAssessmentMomentData(): TubPatientRiskAssessment {
    const gadResults = Object.values(this.gadFormGroup.value).map(v => Number(v));
    const phqResults = Object.values(this.phqFormGroup.value).map(v => Number(v));
    // Reverse results sent also reverse order. TUB handles things backwards to support unity
    return {
      QuestionsAsked: [...this.gadQuestionsKeys, ...this.phqQuestionsKeys] ,
      QuestionResults: [...phqResults.reverse(), ...gadResults.reverse()]
    } as TubPatientRiskAssessment;
  }

  private getAppointmentMomentData(): TubAppointmentMoment {
    const dateTime = this.appointmentFormGroup.get('appointmentDate').value;
    dateTime.setHours(
      parseInt(moment(this.appointmentFormGroup.get('appointmentTime').value,'hh:mm').format('hh')),
      parseInt(moment(this.appointmentFormGroup.get('appointmentTime').value,'hh:mm').format('mm'))
    );

    const appointmentData = {
      appointmentDateTime: Date.parse(dateTime.toString()),
      appointmentOutcome: this.appointmentFormGroup.get('appointmentAttendanceStatus').value,
      therapySessionNumber: Number(this.appointmentFormGroup.get('appointmentSessionNumber').value),
      howPatientIsFunctioning: this.appointmentFormGroup.get('functionResult').value,
      attachmentsRef: this.attachmentsFormGroup.get('moments').value as string[]
    } as TubAppointmentMoment;

    if (this.appointmentFormGroup.get('appointmentAttendanceStatusOther').value) {
      appointmentData.appointmentOutcomeOther = this.appointmentFormGroup.get('appointmentAttendanceStatusOther').value;
    }

    return appointmentData;
  }

  private isInvalidTimeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.appointmentFormGroup) {
        return null;
      }

      if (this.appointmentFormGroup?.get('appointmentDate')?.value?.getDate() === new Date().getDate()) {
        const currentValue = new Date(this.appointmentFormGroup?.get('appointmentDate')?.value);

        if (control.value) {
          const hh = new Date(control.value).getHours();
          const mm = new Date(control.value).getMinutes();

          currentValue.setHours(hh);
          currentValue.setMinutes(mm);

          // Make time flexible - give or take a minute
          const toleranceMillis = 60000;

            // number of milliseconds tolerance (i.e. 60000 == one minute)
            if (currentValue.getTime() > Date.now() + toleranceMillis) {
              return { invalidTimeFuture: true };
            }
        }
      }

      return null;
    };
  }
}
