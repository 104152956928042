<mat-progress-bar mode="determinate" [value]="strength"
                  [ngClass]="{ weak: (strength <= 35 || externalError), ok: strength <= 65 && strength > 35, strong: strength === 100 }">
</mat-progress-bar>

<div *ngIf="displayPasswordStrength || displayPasswordStrengthPercentage" class="current-strength">
  <div *ngIf="displayPasswordStrength">
    <small *ngIf="strength <= 35">weak</small>
    <small *ngIf="strength <= 65 && strength > 35">ok</small>
    <small *ngIf="strength <= 99 && strength > 65">good</small>
    <small *ngIf="strength === 100">strong</small>
  </div>
  <div *ngIf="displayPasswordStrengthPercentage">
    <small>{{ strength }} / 100</small>
  </div>
</div>

<div *ngIf="enableLowerCaseLetterRule" @items class="info-row">
  <div *ngIf="containAtLeastOneLowerCaseLetter; then done; else error"></div>
  <ng-template #done>
    <mat-icon @positiveState class="done">done</mat-icon>
  </ng-template>
  <ng-template #error>
    <mat-icon @negativeState class="warn">error</mat-icon>
  </ng-template>
  <span>{{ lowerCaseCriteriaMsg }}</span>
</div>

<div *ngIf="enableUpperCaseLetterRule" @items class="info-row">
  <div *ngIf="containAtLeastOneUpperCaseLetter; then done; else error"></div>
  <ng-template #done>
    <mat-icon @positiveState class="done">done</mat-icon>
  </ng-template>
  <ng-template #error>
    <mat-icon @negativeState class="warn">error</mat-icon>
  </ng-template>
  <span>{{ upperCaseCriteriaMsg }}</span>
</div>

<div *ngIf="enableDigitRule" @items class="info-row">
  <div *ngIf="containAtLeastOneDigit; then done; else error"></div>
  <ng-template #done>
    <mat-icon @positiveState class="done">done</mat-icon>
  </ng-template>
  <ng-template #error>
    <mat-icon @negativeState class="warn">error</mat-icon>
  </ng-template>
  <span>{{ digitsCriteriaMsg }}</span>
</div>

<div *ngIf="enableSpecialCharRule" @items class="info-row">
  <div *ngIf="containAtLeastOneSpecialChar; then done; else error"></div>
  <ng-template #done>
    <mat-icon @positiveState class="done">done</mat-icon>
  </ng-template>
  <ng-template #error>
    <mat-icon @negativeState class="warn">error</mat-icon>
  </ng-template>
  <span>{{ specialCharsCriteriaMsg }}</span>
</div>

<div *ngIf="enableLengthRule" @items class="info-row">
  <div *ngIf="containAtLeastMinChars; then done; else error"></div>
  <ng-template #done>
    <mat-icon @positiveState class="done">done</mat-icon>
  </ng-template>
  <ng-template #error>
    <mat-icon @negativeState class="warn">error</mat-icon>
  </ng-template>
  <span>{{ minCharsCriteriaMsg }}</span>
</div>

<div *ngIf="customValidator" @items class="info-row">
  <div *ngIf="containAtCustomChars; then done; else error"></div>
  <ng-template #done>
    <mat-icon @positiveState class="done">done</mat-icon>
  </ng-template>
  <ng-template #error>
    <mat-icon @negativeState class="warn">error</mat-icon>
  </ng-template>
  <span>{{ customCharsCriteriaMsg }}</span>
</div>
